import PortfolioCoinTransactionController from "./portfolio_coin_transactions_controller";
import { EventToast } from "../events";
import { convertPriceFrom } from '../util/convert';
import { getActiveCurrency, formatCurrency } from "../util/currency";

export default class extends PortfolioCoinTransactionController {
  static targets = PortfolioCoinTransactionController.targets;

  updateCoinIdValue(e) {
    const coin = e.currentTarget.dataset,
          coinSymbol = coin.coinSymbol,
          currencyCode = getActiveCurrency(),
          price = convertPriceFrom(coin.priceBtc, "btc", currencyCode, true),
          rawPrice = true, // remove currency symbol
          formattedPrice = formatCurrency(price, currencyCode, rawPrice),
          portfolioTitle = coin.portfolioTitle,
          transactionPath = coin.transactionPath;

    this._updateCurrency(currencyCode);
    this._initializeDatePicker();
    this._initializePreviewSpentUpdateOnChange();

    this._setPricePerCoin(formattedPrice);
    this._setTotalSpent(formattedPrice);
    this._setCoinSymbolTarget(coinSymbol);
    this._updateBalanceAmount(coin);
    this._updateModalPortfolioName(portfolioTitle);

    this.formTarget.action = transactionPath;
    this.coinIdTarget.value = coin.coinId;

    $("#new_transaction_modal").on("shown.bs.modal", e => {
      this.previewSpentInputFieldTarget.focus();
    });
  }

  onPostSuccess(e) {
    this._clearFormErrors();
    window.dispatchEvent(
      new CustomEvent(EventToast, {
        detail: {
          notice: "Created Successfully"
        }
      })
    )
    window.location.reload();
  }

  _setPricePerCoin(formattedPrice) {
    this.priceInputFieldTarget.value = formattedPrice;
    this.previewSpentInputFieldTarget.value = formattedPrice;
  }

  _setTotalSpent(formattedPrice) {
    this.previewSpentOutputFieldTarget.value = formattedPrice;
  }
}
