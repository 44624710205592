import { Controller } from "stimulus";
import { stockChartOptions, HIGHCHARTS_DATE_FORMAT } from "../config";

export default class extends Controller {
  static targets = ["chart", "rangeOption", "indexChange"];

  connect() {
    let chartData = JSON.parse(document.getElementById(this.chartTarget.id).getAttribute("data-prices"))
    let indexPercentageChange = JSON.parse(document.getElementById(this.chartTarget.id).getAttribute("data-index-percentage-change"))
    this.renderIndexChart(chartData, indexPercentageChange);
  }

  renderIndexChart(chartData, percentageChange) {
      let elementId = this.chartTarget.id
      this.updateChartStyle(percentageChange)

      Promise.all([
        import("highcharts/highstock")
      ]).then(res => {
        const [Highcharts] = res;
        return Highcharts.StockChart(
          Object.assign(
            {
              tooltip: {
                xDateFormat: HIGHCHARTS_DATE_FORMAT
              },
              xAxis: {
                lineWidth: 0,
                minorGridLineColor: "#22303B",
                tickColor: "#22303B",
                lineColor: 'transparent',
                labels: {
                  enabled: false
                },
              },
              yAxis: {
                visible: false
              },
              navigator: {
                  enabled: false
              },
              series: [
                {
                  color: percentageChange > 0 ? "#46A303" : "#ED5565",
                  name: "Price",
                  type: 'line',
                  lineWidth: 2,
                  data: chartData,
                  marker: {
                    enabled: false
                  }
                }
              ]
            },
            stockChartOptions(elementId),
            // disable credit after execute function above
            Object.assign(
              {
                rangeSelector: {
                  enabled: false
                },
                height: 400,
                credits: {
                  enabled: false
                },
              }
            )
          )
        );
      });
    }

  updateChart(e) {
    let dataUrl = e.srcElement.getAttribute("data-graph-stats-url")
    this.rangeOptionTarget.innerHTML = e.srcElement.innerText
    return fetch(dataUrl)
      .then(response => response.json())
      .then(res => {
        this.renderIndexChart(res.index_stats, res.percentage_change);
      })
    }

  updateChartStyle(percentageChange) {
    let indexChange = this.indexChangeTarget
    let percentageChangeSpan = indexChange.lastChild.lastChild
    indexChange.lastChild.lastChild.firstChild.innerText = percentageChange + "%"

    if(percentageChange >= 0){
      percentageChangeSpan.className = "text-green"
      percentageChangeSpan.lastChild.className = "far fa-level-up"
    } else {
      percentageChangeSpan.className = "text-danger"
      percentageChangeSpan.lastChild.className = "far fa-level-down"
    }
  }
}
