import { Controller } from "stimulus";
import Cookies from "js-cookie";
import { loadScript } from "../util/load_script";

export default class extends Controller {
  static targets = ["widget"];

  connect() {
    const changeDarkMode = this._changeDarkMode.bind(this);

    loadScript()
      .url("https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js")
      .then(() => { this._changeDarkMode(); })
  }

  _changeDarkMode() {
    const IS_DARK = "is_dark";
    const isDark = Cookies.get(IS_DARK) === "true";
    if (isDark) {
      this.widgetTarget.setAttribute("background-color", "#252525");
      this.widgetTarget.setAttribute("font-color", "#ffffff");
    } else {
      this.widgetTarget.setAttribute("background-color", "#ffffff");
      this.widgetTarget.setAttribute("font-color", "#252525");
    }
  }
}
