import { Controller } from "stimulus";
import { copyToClipboard } from "../util/copy_to_clipboard";

export default class extends Controller {
  static targets = ["code", "buttonIcon"];

  copyCode(e) {
    copyToClipboard(this.codeTarget, this.buttonIconTarget);
  }
}
