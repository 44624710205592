import { Controller } from "stimulus";
import { EventPriceChartReload } from "../events";

// Controls the time range dropdown in the portfolio dashboard
export default class extends Controller {
  updateRange(e) {
    const range = e.target.dataset.range;
    const event = new CustomEvent(EventPriceChartReload, {
      bubbles: true,
      detail: {
        range
      }
    });
    this.element.dispatchEvent(event);

    e.preventDefault();
  }
}
