import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pageLabel", "firstPage", "secondPage", "searchInput"];

  nextPage(e) {
    e.preventDefault();
    if (this.pageLabelTarget.innerHTML == "1/2") {
      this.pageLabelTarget.innerHTML = "2/2";
    } else {
      this.pageLabelTarget.innerHTML = "1/2";
    }
    this.secondPageTarget.classList.toggle("tw-hidden");
    this.firstPageTarget.classList.toggle("tw-hidden");
  }

  focusSearchInput(e) {
    e.preventDefault();
    this.searchInputTarget.focus();
  }
}
