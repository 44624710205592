import { Controller } from "stimulus";
import { loadScript } from "../util/load_script";
import showAds from "../util/show_ads";

export default class extends Controller {
  static targets = ["bsSponsored", "bsPopover", "lsPopover", "coinRowBuy"];

  connect() {
    if (window.bsa_loaded !== true && showAds()) {
      loadScript(true)
        .url("//m.servedby-buysellads.com/monetization.js")
        .then(() => {
          if (this.hasBsPopoverTarget) {
            this.bsPopoverTargets.forEach((el) => {
              el.onload = this.loadPopOverAds(el);
            });
          }

          if (this.hasLsPopoverTarget) {
            this.lsPopoverTargets.forEach((el) => {
              el.onload = this.loadPopOverAds(el);
            });
          }

          if (this.hasBsSponsoredTarget) {
            this.bsSponsoredTargets.forEach((el) => {
              el.onload = this.loadSponsoredExchangeAds(el);
            });
          }

          if (this.hasCoinRowBuyTarget) {
            this.coinRowBuyTargets.forEach((el) => {
              el.onload = this.loadCoinRowAds(el);
            });
          }

          this.loadSponsoredBoxAds();
          setTimeout(() => this._bsaInitTokens(), 5000);

          window.bsa_loaded = true;
        }, true);
    }
  }

  // Use CVADC53U for demo/test
  loadSponsoredBoxAds() {
    const sponsoredBoxKey = this._isProduction() ? "CK7DPK7J" : "CVADC53U";

    if (typeof _bsa !== "undefined" && _bsa) {
      const sponsoredBoxTarget = document.getElementById("sponsored_box");

      if (sponsoredBoxTarget) {
        sponsoredBoxTarget.innerHTML = null;

        // format, zoneKey, segment:value, options
        _bsa.init("default", sponsoredBoxKey, "placement:sponsored_box", {
          target: "#sponsored_box",
          align: "horizontal",
        });
      }
    }
  }

  loadPopOverAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init("custom", el.dataset.zoneKey, "placement:coingecko-button", {
        target: `#${el.id}`,
        template: `
              <div class="p-2 px-2">
                <div>
                  <a target="_blank" rel="noopener nofollow" href="##statlink##">
                    <img alt="##company##" src="##logo##" style="width: 20px; height: 20px;">##company##</img>
                    <div class="text-muted mt-1" style="line-height: 1;">
                      <small>##description##</small>
                    </div>
                  </a>
                </div>
              </div>
            `,
      });
    }
  }

  loadSponsoredExchangeAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init("custom", el.dataset.zoneKey, "placement:coingecko-exchange-02", {
        target: `#${el.id}`, //bitflyer exchange ads
        template: `
              <span>
                <span>
                  <img width="20" height="20" class="mr-1" src="##logo##">
                  <a rel="nofollow" href="##statlink##">##company##</a>
                </span>
                <span class="badge badge-warning" title="##description##">
                  Sponsored
                </span>
              </span>
            `,
      });
    }
  }

  loadCoinRowAds(el) {
    if (typeof _bsa !== "undefined" && _bsa) {
      _bsa.init("custom", el.dataset.zoneKey, `placement:coingecko-${el.dataset.apiSymbol}-token`, {
        target: `#token-${el.dataset.apiSymbol}-js`,
        template: `
          <details class="token-wrap">
            <summary class="token-buy">Buy</summary>

            <div class="token-card">
              <div class="token-sponsor"><img height=20 width=20 src="##icon##">##company##</div>
              <a class="token-img" href="##statlink##" rel="sponsored noopener" target="_blank"><img src="##banner##"></a>
              <div class="token-desc">##description##</div>
              <a class="token-button" href="##statlink##" rel="sponsored noopener" target="_blank">##callToAction##</a>
              <div class="token-via">Sponsored</div>
            </div>
          </details>
          `,
      });
    }
  }

  _bsaHideOtherTokens(target) {
    let parentEl = target.parentElement;
    if (parentEl.open === true) {
      parentEl.open === false;
    } else {
      document.querySelectorAll(".token-wrap").forEach((node, index) => {
        if (node.open === true) {
          node.open = false;
        }
      });
    }
  }

  _bsaInitTokens() {
    document.querySelectorAll(".token-buy").forEach((item) => {
      item.addEventListener("click", () => this._bsaHideOtherTokens(item));
    });
  }

  _isProduction() {
    return process.env.NODE_ENV === "production";
  }
}
