import { Controller } from "stimulus";
import { formatCurrency } from "../util/currency";

export default class extends Controller {
  connect() {
    this.drawCoinsTreeMap();
  }

  drawCoinsTreeMap() {
    const title = this.element.getAttribute("data-title");
    const subtitle = this.element.getAttribute("data-subtitle");
    const treeMapData = JSON.parse(this.element.getAttribute("data-coins-tree-map-data"));
    var chartHeight;

    if (JSON.parse(this.element.getAttribute("data-auto-height"))) {
      chartHeight = $(window).height() - 150;
    } else {
      chartHeight = 0;
    }

    Promise.all([
      import("highcharts/highstock"),
      import("highcharts/modules/heatmap"),
      import("highcharts/modules/treemap"),
    ]).then(res => {
      const Highcharts = res[0];
      const Heatmap = res[1].default;
      const Treemap = res[2].default;
      Heatmap(Highcharts);
      Treemap(Highcharts);

      Highcharts.chart({
        time: { useUTC: false },
        title: {
          text: title
        },
        subtitle: {
          text: subtitle
        },
        credits: {
          enabled: false
        },
        colorAxis: {
          dataClasses: [
            { from: 0, to: 5, color: "#8DBE84" },
            { from: 5, to: 15, color: "#5D8456" },
            { from: 15, color: "#325C32" },
            { from: -5, to: 0, color: "#DE7875" },
            { from: -15, to: -5, color: "#B94A45" },
            { to: -15, color: "#9D2E29" }
          ]
        },
        legend: {
          enabled: false
        },
        chart: {
          renderTo: this.element,
          type: "treemap",
          height: `${chartHeight}px`
        },
        tooltip: {
          useHTML: true,
          formatter: function() {
            var changeHTML;
            if (this.point.colorValue > 0) {
              changeHTML = `<span style="color:#5D8456;font-weight:700;">${parseFloat(
                this.point.colorValue
              ).toFixed(2)}%</span>`;
            } else {
              changeHTML = `<span style="color:#B94A45;font-weight:700;">${parseFloat(
                this.point.colorValue
              ).toFixed(2)}%</span>`;
            }

            return [
              "<div>",
              '<div style="font-weight:700;">',
              `<img style="width:20px; margin-right:5px;" src="${this.point.coinImageUrl}">`,
              `<span>${this.point.coinName}</span>`,
              "</div>",
              "<br>",
              `<div>Price: ${formatCurrency(this.point.price, "usd", false)}</div>`,
              `<div>24h Change: ${changeHTML}</div>`,
              `<div>Market Cap: ${formatCurrency(this.point.value, "usd", false)}</div>`,
              "</div>"
            ].join("");
          },
          style: {
            textAlign: "center",
            fontSize: "1rem"
          }
        },
        plotOptions: {
          treemap: {
            layoutAlgorithm: "squarified"
          }
        },
        series: [
          {
            dataLabels: {
              useHTML: true,
              style: {
                textAlign: "center"
              },
              formatter: function() {
                var changeHTML;
                if (this.point.colorValue > 0) {
                  changeHTML = `<span style=font-weight:700;">+${parseFloat(
                    this.point.colorValue
                  ).toFixed(2)}%</span>`;
                } else {
                  changeHTML = `<span style=font-weight:700;">${parseFloat(
                    this.point.colorValue
                  ).toFixed(2)}%</span>`;
                }
                var coinName = this.point.name.toUpperCase();
                var fontSize = this.point.shapeArgs.width / 8;

                return [
                  `<div>`,
                  `<div style="font-size:${fontSize}px">${coinName}</div>`,
                  `<div style="font-size:${fontSize}px">${changeHTML}</div>`,
                  "</div>"
                ].join("");
              }
            },
            data: treeMapData
          }
        ]
      });
    });
  }
}