import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "email", "password"];

  openModal(e) {
    let modalId = e.target.getAttribute("data-modal-id");

    let hideModalPromise = new Promise(resolve => {
      $(".modal").modal("hide");
      resolve();
    });

    hideModalPromise.then(() => {
      $(`#${modalId}`).modal("show");

      if (modalId === "signUpModal") {
        // var event = new Event("signUpModalOpen");
        // util.global.dispatchEvent(event);
        e.element.dispatchEvent(
          new CustomEvent("signUpModalOpen", {
            bubbles: true
          })
        );
      }
    });
  }

  submit(e) {
    event.preventDefault();

    let submitForm = true;

    if (this.emailTarget.value == "") {
      this.emailTarget.classList.add("border");
      this.emailTarget.classList.add("border-danger");
      submitForm = false;
    }

    if (this.passwordTarget.value == "") {
      this.passwordTarget.classList.add("border");
      this.passwordTarget.classList.add("border-danger");
      submitForm = false;
    }

    if (submitForm) {
      this.formTarget.submit();
    }
  }
}
