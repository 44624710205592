import PortfolioCoinTransactionController from "./portfolio_coin_transactions_controller";
import { EventToast } from "../events";

export default class extends PortfolioCoinTransactionController {
  static targets = PortfolioCoinTransactionController.targets.concat(["transactionFormTabs"]);

  editTransaction(event) {
    // portfolio coin transaction
    const transactionPath = event.currentTarget.dataset.transactionPath,
      editModalSelector = event.currentTarget.dataset.editModalSelector,
      coinSymbol = event.currentTarget.dataset.coinSymbol,
      portfolioTitle = event.currentTarget.dataset.portfolioTitle,
      coinBalance = event.currentTarget.dataset.coinBalance;

    this._updateBalanceAmount({
      coinSymbol: coinSymbol,
      coinBalance: coinBalance,
    });

    this._updateModalPortfolioName(portfolioTitle);

    fetch(transactionPath, {credentials: "same-origin"})
      .then(response => response.json())
      .then(transaction => {
        this._initializePreviewSpentUpdateOnChange();
        this._initializeDatePicker(transaction.transaction_timestamp);

        this._preFillTransactionForm(transaction);
        this._toggleTransactionFormTab(transaction);
        this.formTarget.action = transactionPath;

        this._updateCurrency(transaction.currency);
        this._setCoinSymbolTarget(coinSymbol);

        $(`#${editModalSelector}`).modal('show');
        $(`#${editModalSelector}`).on("shown.bs.modal", _e => {
          this._autoFocusInput(transaction.transaction_type);
        })
      });
  }

  _currencyChangedEvent() {
    return null; // not binding to currency changed event.
  }

  _toggleTransactionFormTab(transaction) {
    this._resetTab();

    if (["buy", "sell"].includes(transaction.transaction_type)) {
      const selectedTab = this.transactionFormTabsTarget
        .querySelector(`a[aria-control="#nav-${transaction.transaction_type}"]`);

      selectedTab.classList.add("active");
      selectedTab.setAttribute("aria-selected", true);
    } else {
      const selectedTab = this.transactionFormTabsTarget
        .querySelector('a[aria-control="#nav-transfer"]');

      selectedTab.classList.add("active");
      selectedTab.setAttribute("aria-selected", true);
    }

    this.toggleInputField(transaction.transaction_type);
  }

  _resetTab() {
    const tabs = this.transactionFormTabsTarget.querySelectorAll("a[role='tab']");
    tabs.forEach(tab => {
      tab.classList.remove("active");
      tab.setAttribute("aria-selected", true);
    });
  }

  _preFillTransactionForm(transaction) {
    this.previewSpentOutputFieldTarget.value = transaction.cost;
    this.priceInputFieldTarget.value = transaction.price;
    this.previewSpentInputFieldTarget.value = transaction.price;
    this.noteInputFieldTarget.value = transaction.notes;
    this.feeInputFieldTarget.value = transaction.fees;
    this.quantityInputFieldTarget.value = transaction.quantity;
    this.transferInputFieldTarget.querySelector("select").value = transaction.transaction_type;
    this.transactionTypeTarget.value = transaction.transaction_type;
  }

  onBeforeSend(xhr) {
    const queryParams = new URLSearchParams(xhr.detail[1].data);
    queryParams.set("_method", "patch");

    xhr.detail[1].data = queryParams.toString();
  }

  onPostSuccess(e) {
    this._clearFormErrors();
    window.dispatchEvent(
      new CustomEvent(EventToast, {
        detail: {
          notice: "Updated Successfully"
        }
      })
    )
    window.location.reload();
  }
}
