import DialogController from "./dialog_controller";
import { EventToast } from "../events";
import { getCSRFToken } from "../util";

export default class extends DialogController {
  static targets = [];

  connect() {}

  deleteTransaction(event) {
    const transaction = event.currentTarget,
          removeTransactionConfirmDialog = $(`#${transaction.dataset.confirmModal}`),
          removeTransactionConfirmButton = removeTransactionConfirmDialog.find(`#${transaction.dataset.confirmButton}`);

    this._confirmationDialog(
      () => { removeTransactionConfirmDialog.modal("show") },
      removeTransactionConfirmButton,
      () => {
        removeTransactionConfirmDialog.modal("hide");
        this._deleteTransactionCallBack(transaction);
      }
    );
  }

  _deleteTransactionCallBack(transaction) {
    const deletePath = transaction.dataset.transactionPath;

    fetch(deletePath,
      {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data.message == "success") {
          window.dispatchEvent(
            new CustomEvent(EventToast, {
              detail: {
                notice: "Successful"
              }
            })
          )
          window.location.reload();
        } else {
          throw new Error("Failed to delete");
        }
      })
      .catch(error => {
        window.dispatchEvent(
          new CustomEvent(EventToast, {
            detail: {
              notice: "Unsuccessful"
            }
          })
        )
      });
  }
}
