import { Controller } from "stimulus";
import { stockChartOptions } from "../config";

export default class extends Controller {
  static targets = ["chart"];
  connect() {
    var crix_charts = this.chartTargets;
    for (var i = 0; i < crix_charts.length; i++) {
      renderIndexChart(crix_charts[i].id);
    }
    
    function renderIndexChart(elementId) {
      Promise.all([
        import("highcharts/highstock")
      ]).then(res => {
        const [Highcharts] = res;
        return Highcharts.StockChart(
          Object.assign(
            {
              yAxis: {
                showLastLabel: true,
                opposite: false,
                labels: {
                  style: {
                    color: "#56b4e9"
                  },
                  align: "right",
                  x: -10
                }
              },
              series: [
                {
                  color: "#7cb5ec",
                  name: "Price",
                  data: $(`#${elementId}`).data("prices"),
                  marker: {
                    enabled: false
                  }
                }
              ]
            },
            stockChartOptions(elementId)
          )
        );
      });
    }
  }
}