import toggleChartTypeButtons from "util/toggle_chart_type_buttons";

export const API_ENDPOINTS = {
  production: "https://api.coingecko.com/api/v3",
  staging: "https://www.coingecko.xyz/api/v3",
  development: "http://localhost:3000/api/v3"
};

export const ASSET_ENDPOINTS = {
  production: "https://assets.coingecko.com",
  staging: "https://assets-staging.coingecko.com",
  development: "https://assets-development.coingecko.com"
};

export const HIGHCHARTS_DATE_FORMAT = "%a %d %b %Y, %H:%M:%S";

export const stockChartOptions = elementId => ({
  time: { useUTC: false },
  chart: {
    panning: false,
    marginTop: 50,
    renderTo: `${elementId}`,
    styledMode: true,
    zoomType: 'x',
    className: 'cg-highcharts'
  },
  rangeSelector: {
    buttons: [],
    inputPosition: {
        align: "right",
        x: 0,
        y: -20
    },
  },
  scrollbar: {
    enabled: false
  },
  exporting: {
    buttons: {
      customButton: {
        className: 'highcharts-button-linear highcharts-button-active',
        text: 'Linear',
        onclick: function() {
          this.yAxis.forEach((chart, _) => {
            chart.update({ type: 'linear' });
          });

          toggleChartTypeButtons();
        },
        y: -10
      },
      customButton2: {
        className: 'highcharts-button-log',
        text: 'Logarithmic',
        onclick: function() {
          this.yAxis.forEach((chart, _) => {
            chart.update({ type: 'logarithmic' });
          });

          toggleChartTypeButtons();
        },
        y: -10
      },
      contextButton: {
        y: -10
      }
    },
    chartOptions: {
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false
          }
        }
      }
    },
    fallbackToExportServer: false
  },
  credits: {
    enabled: false
  },
});

export default {
  API_ENDPOINTS
};
