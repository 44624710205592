import { Controller } from "stimulus";
import { getActiveCurrency, formatCurrency } from "../util/currency";
import { EventCurrencyChanged } from "../events";
import { stockChartOptions, HIGHCHARTS_DATE_FORMAT } from "../config";
import CoinGeckoThumbnailImage from "images/coingecko-greyscale.png";

let Highcharts;

export default class extends Controller {
  static values = { url: String, urlMax: String }
  static targets = ["chart"];

  afterSetExtremes(e) {
    const { chart } = e.target;
    chart.showLoading('Loading data from server...');
    fetch(`${this.urlValue}?start=${Math.abs(e.min)/1000}&end=${Math.abs(e.max)/1000}`)
        .then(res => res.ok && res.json())
        .then(data => {
          Object.entries(data).forEach(([key,value], index) => {
              chart.series[index].setData(value);
            })
            chart.hideLoading();
        }).catch(error => console.error(error.message));
  }

  series(data){
    let seriesData = []
    Object.keys(data).forEach((key, index)=>{
      let obj = {
        name: key.toUpperCase(),
        data: data[key],
        yAxis: index,
        dataGrouping: {
            enabled: false
        },
        tooltip: {
          valueDecimals: 2
        }
      }
      seriesData.push(obj)
    })
    console.log("seriesData", seriesData)
    return seriesData;
  }

  yAxis(data){
    let yAxisData = []
    Object.keys(data).forEach((key, index)=>{
      let obj = { // Secondary yAxis
        gridLineWidth: 0,
        title: {
              text: key.toUpperCase(),
              style: {
                  color: Highcharts.getOptions().colors[index]
              }
          },
          labels: {
              format: '{value}',
              style: {
                  color: Highcharts.getOptions().colors[index]
              }
          },
          opposite: false
  
      }
      yAxisData.push(obj)
    })
    console.log("yAxisData", yAxisData)
    return yAxisData;
  }

  // Returns the chart data in the format: { data: Object, key: String }
  _getChartData(url) {
    if (!url) return Promise.resolve();

    return fetch(url, { credentials: "same-origin" })
      .then(response => response.json())
      .then(json => ({ data: json }));
  }

  connect() {
    Promise.all([
      import("highcharts/highstock"),
      import("highcharts/modules/data"),
    ]).then(res => {
      Highcharts = res[0];
      const chartDataPromises = [];
      chartDataPromises.push(this._getChartData(this.urlValue));
      chartDataPromises.push(this._getChartData(this.urlMaxValue));
      console.log("chartDataPromises", chartDataPromises)
      Promise.all(chartDataPromises)
        .then(([dd, md]) => {
          let data = dd.data
          let maxData = md.data
            Highcharts.stockChart(this.chartTarget.id, {
              chart: {
                type: 'line',
                zoomType: 'x'
              },
    
              navigator: {
                adaptToUpdatedData: false,
                series: {
                  data: maxData[Object.keys(maxData)[0]]
                }
              },
    
              scrollbar: {
                liveRedraw: false
              },
    
              rangeSelector: {
                buttons: [{
                  type: 'day',
                  count: 1,
                  text: '1d'
                }, {
                  type: 'month',
                  count: 1,
                  text: '1m'
                }, {
                  type: 'all',
                  text: 'All'
                }],
                inputEnabled: false, // it supports only days
                selected: 1 // all
              },
    
              xAxis: {
                events: {
                  afterSetExtremes: e => {
                    this.afterSetExtremes(e)
                  }
                },
                minRange: 3600 * 1000 // one hour
              },
    
              yAxis: this.yAxis(data),
              series: this.series(data),
              credits: {
                enabled: false
              }
            });
        })
    });
  }
}
