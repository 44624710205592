import { Controller } from "stimulus";
import { HIGHCHARTS_DATE_FORMAT } from "../config";

export default class extends Controller {
  static targets = ["chart", "spinner", "toolbar"];

  connect() {
    this.renderChart(this.chartTarget.dataset.initialUrl);
  }

  renderChart(chartUrl) {
    fetch(chartUrl, { credentials: "same-origin" })
      .then(response => response.json())
      .then(data => {
        if (data === null) {
          return;
        }

        const seriesDataArray = data.series_data_array;
        seriesDataArray.push(seriesDataArray.shift()); //to move btc to the end of array to make it shown in botton

        seriesDataArray.forEach(function(el) {
          el.type = "area";
        });

        Promise.all([
          import("highcharts/highstock")
        ]).then(res => {
          const [Highcharts] = res;
          Highcharts.chart(this.chartTarget.id, {
            time: { useUTC: false },
            title: "",
            chart: {
              height: 500,
              type: "line",
              zoomType: 'x'
            },
            credits: {
              enabled: false
            },
            tooltip: {
              shared: true,
              useHTML: true,
              pointFormat:
                "<span style='color:{point.color}'>\u25CF</span> {series.name}: <b>{point.y}%</b><br/>",
              xDateFormat: HIGHCHARTS_DATE_FORMAT
            },
            navigator: {
              enabled: true
            },
            xAxis: {
              type: "datetime",
              title: {
                enabled: false
              }
            },
            yAxis: {
              title: {
                text: "Dominance Percentage"
              },
              labels: {
                formatter: function() {
                  return `${this.value}%`;
                }
              }
            },
            plotOptions: {
              area: {
                stacking: "percent",
                lineWidth: 2,
                fillOpacity: 0.05,
                marker: {
                  enabled: false
                },
                connectNulls: true
              }
            },
            exporting: {
              buttons: {
                overlappingButton: {
                  text: "Overlapping",
                  className: "overlapping-button",
                  onclick: function() {
                    const overlappingButton = this.exportingGroup.element.querySelector(
                      ".overlapping-button"
                    );
                    const stackedButton = this.exportingGroup.element.querySelector(
                      ".stacked-button"
                    );
                    stackedButton.classList.remove("active-button");
                    overlappingButton.classList.add("active-button");

                    this.update({
                      plotOptions: {
                        area: {
                          stacking: null
                        }
                      }
                    });
                  }
                },
                stackedButton: {
                  text: "Stacked",
                  className: "stacked-button active-button",
                  onclick: function() {
                    const overlappingButton = this.exportingGroup.element.querySelector(
                      ".overlapping-button"
                    );
                    const stackedButton = this.exportingGroup.element.querySelector(
                      ".stacked-button"
                    );
                    overlappingButton.classList.remove("active-button");
                    stackedButton.classList.add("active-button");

                    this.update({
                      plotOptions: {
                        area: {
                          stacking: "percent"
                        }
                      }
                    });
                  }
                }
              }
            },
            series: seriesDataArray
          });
        });
      });
  }

  updateChart(e) {
    const buttonEl = e.currentTarget;

    // Clear active on siblings
    const $buttonEl = $(buttonEl);
    $buttonEl.siblings().removeClass("active");
    $buttonEl.addClass("active");

    this.chartDataUrl = $buttonEl.data("coin-dominance-url");
    this.renderChart(this.chartDataUrl);
  }
}
