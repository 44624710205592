import { Controller } from "stimulus";
import { EventToast } from "../events";

export default class extends Controller {
  static targets = ["notice"];

  connect() {
    window.addEventListener(EventToast, e => {
      const timeout = e.detail.timeout || 3000;

      this.noticeTarget.innerHTML = e.detail.notice;

      if (this._timeoutID) {
        window.clearTimeout(this._timeoutID);
      }

      this.show(true);
      this._timeoutID = setTimeout(() => {
        this.show(false);
      }, timeout);
    });
  }

  show(show = true) {
    if (show) {
      this.element.classList.add("toast-show");
      this.element.classList.remove("toast-hide");
    } else {
      this.element.classList.add("toast-hide");
      this.element.classList.remove("toast-show");
    }
  }
}
