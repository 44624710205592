import { Controller } from "stimulus";
import { EventCheckUserLogin } from "../events";
import { getPortfoliosRequest } from "../routes/portfolio";

export default class extends Controller {
  static targets = [
    "beam",
    "candyIcon",
    "candyNotificationIcon",
    "candyNotificationIconMobile",
    "form",
    "loggedInNavi",
    "loggedOutNavi",
    "reportLink",
    "portfolioIcon",
    "userName",
    "subscribeButton",
    "developerMenu",
  ];

  connect() {
    fetch("/accounts/csrf_meta.json", { credentials: "same-origin" })
      .then(response => response.json())
      .then(json => {
        $("<meta>")
          .attr("name", "csrf-param")
          .attr("content", json.param)
          .appendTo("head");

        $("<meta>")
          .attr("name", "csrf-token")
          .attr("content", json.token)
          .appendTo("head");

        // add latest CSFR token to user sign in modal
        this._assignCSRFTokenToModalForm(json.token);
      })
      .then(function() {
        return fetch("/accounts/logged_in_user.json", { credentials: "same-origin" });
      })
      .then(response => response.json())
      .then(json => {
        if (json.current_user) {
          const name = json.current_user.name || json.current_user.email;
          const candyRedemptionAvailable = json.candy_redemption_available;
          this.userNameTargets.forEach(e => {
            e.textContent = name;
          });
          this._changeReportLink();
          this._showLoggedInNavi(true);
          this._showLoggedOutNavi(false);
          this._showCandyNotificationIcon(candyRedemptionAvailable);
          this._showBeamNavi(json.beam_enabled);
          getPortfoliosRequest(this._initializePortfolioNavigationMenu.bind(this));
          this._dispatchCheckUserLoginEvent(true);
        } else {
          this._showLoggedInNavi(false);
          this._showLoggedOutNavi(true);
          this._showLoggedInModal(true);
          this._showBeamNavi(false);
          this._dispatchCheckUserLoginEvent(false);
        }

        if (!json.has_subscription) {
          this._toggleSubscribeButton(true);
        }

        if (json.has_enterprise_api_subscription) {
          this._showDeveloperMenu();
        }
      });
  }

  _showCandyNotificationIcon(candyRedemptionAvailable = false){
    if(candyRedemptionAvailable === true) {
      this.candyNotificationIconTargets.forEach(e => {
        e.classList.remove("tw-hidden");
      });
    }
  }

  _showLoggedInModal(isLoggedOut = false){
    if(isLoggedOut === true) {
      this.candyIconTargets.forEach(e => {
        e.href = this.candyIconTarget.dataset.url;
      });

      this.portfolioIconTargets.forEach(e => {
        e.href = "#";
        e.dataset.target = "#signInModal";
        e.dataset.toggle = "modal";
      })
    }
  }

  _initializePortfolioNavigationMenu(portfolios) {
    let dropdownContent = ` \
      <a class="dropdown-item py-2" href="/portfolios/portfolio_overview"> \
        All Portfolios<span class="badge badge-danger tw-ml-1">New</span> \
      </a> \
    `;
    portfolios.forEach((portfolio) => {
      dropdownContent += ` \
        <a class="dropdown-item py-2" href="${portfolio.portfolio_path}"> \
          ${portfolio.title} \
        </a> \
      `;
    });

    this.portfolioIconTargets.forEach((portfolioIconTarget) => {
      const parentElement = portfolioIconTarget.parentNode,
            portfolioDropdown = document.createElement("div");

      portfolioIconTarget.dataset.toggle = "dropdown";
      portfolioDropdown.className = "dropdown-menu dropdown-menu-right text-sm mt-0";
      portfolioDropdown.innerHTML = dropdownContent;

      parentElement.classList.add("dropdown");
      parentElement.appendChild(portfolioDropdown);
    })
  }

  _showBeamNavi(show) {
    this.beamTargets.forEach(e => {
      show ? e.classList.remove("tw-hidden") : e.classList.add("tw-hidden");
    });
  }

  _showLoggedInNavi(show) {
    this.loggedInNaviTargets.forEach(e => {
      show ? e.classList.remove("tw-hidden") : e.classList.add("tw-hidden");
    });
  }

  _changeReportLink() {
    if (!this.hasReportLinkTarget) {
      return;
    }

    this.reportLinkTargets.forEach(e => {
      e.href = e.dataset.reportLink;
    });
  }

  _showLoggedOutNavi(show) {
    this.loggedOutNaviTargets.forEach(e => {
      show ? e.classList.remove("tw-hidden") : e.classList.add("tw-hidden");
    });
  }

  _assignCSRFTokenToModalForm(token) {
    this.formTargets.forEach(target => {
      var tokenHiddenField = document.createElement("input");
      tokenHiddenField.setAttribute("type", "hidden");
      tokenHiddenField.setAttribute("name", "authenticity_token");
      tokenHiddenField.setAttribute("value", token);
      target.appendChild(tokenHiddenField);
    });
  }

  _toggleSubscribeButton(show) {
    if (this.hasSubscribeButtonTarget) {
      this.subscribeButtonTargets.forEach(e => {
        show
          ? e.classList.remove("tw-hidden")
          : e.classList.add("tw-hidden");
      });
    }
  }

  _showDeveloperMenu() {
    if (!this.hasDeveloperMenuTarget) {
      return;
    }

    this.developerMenuTarget.classList.remove("tw-hidden");
  }

  _dispatchCheckUserLoginEvent(value) {
    window.dispatchEvent(
      new CustomEvent(EventCheckUserLogin, {
        detail: {
          userLoggedIn: value
        }
      })
    );
  }
}
