import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "container",
    "switch",
  ]

  connect() {
    this._setDefaultState();
  }

  toggle() {
    if (this._isEnabled()) {
      return this._disable();
    }

    this._enable()
  }

  _setDefaultState() {
    if (this._isEnabled()) {
      return this._enable();
    }

    this._disable();
  }

  _enable() {
    this.containerTarget.classList.remove("tw-bg-gray-200");
    this.containerTarget.classList.add("tw-bg-primary-500");
    this.switchTarget.classList.remove("tw-translate-x-0");
    this.switchTarget.classList.add("tw-translate-x-5");
    this.data.set("enabled", true);
  }

  _disable() {
    this.containerTarget.classList.remove("tw-bg-primary-500");
    this.containerTarget.classList.add("tw-bg-gray-200");
    this.switchTarget.classList.remove("tw-translate-x-5");
    this.switchTarget.classList.add("tw-translate-x-0");
    this.data.set("enabled", false);
  }

  _isEnabled() {
    return this.data.get("enabled") === "true"
  }
}
