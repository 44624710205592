import { Controller } from "stimulus";
import { EventCurrencyChanged } from "../events";
import { getActiveCurrency } from "../util/currency";

export default class extends Controller {
  static targets = ["widget"];

  connect() {
    this.widgetTarget.setAttribute("currency", getActiveCurrency());

    window.addEventListener(EventCurrencyChanged, e => {
      this.widgetTarget.setAttribute("currency", e.detail.currencyCode);
    });
  }
}
