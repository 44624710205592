import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = [
    "cardIcon",
    "showMoreLabel",
    "cardStat",
    "textStat",
    "cardIconAnimation",
    "globalStatsLong",
    "readMoreButton",
    "readMoreButton",
  ];
  connect() {
    if (this._cardStatEnable()) {
      this._showCardStat(e);
    }

    if (this._textStatEnable()) {
      this._showTextStat(e);
    }
  }

  showCardStat(e) {
    e.preventDefault();
    if (this._cardStatEnable()) {
      this._hideCardStat(e);
    } else {
      this._showCardStat(e);
    }
  }

  showTextStat(e) {
    e.preventDefault();
    if (this._textStatEnable()) {
      this._hideTextStat(e);
    } else {
      this._showTextStat(e);
    }
  }

  showGlobalStats(e) {
    e.preventDefault();
    this.globalStatsLongTarget.classList.toggle("tw-hidden");
    if (e.target.innerHTML === e.target.dataset.textHide) {
      e.target.innerHTML = e.target.dataset.textReadMore;
    } else {
      e.target.innerHTML = e.target.dataset.textHide;
    }
  }

  _cardStatEnable() {
    return Cookies.get("indexGlobalCardStat") === "true";
  }

  _showCardStat() {
    Cookies.set("indexGlobalCardStat", true, { path: "/" });
    this.cardIconTargets.forEach((element) => {
      element.classList.remove("tw-bg-gray-200");
      element.classList.add("tw-bg-primary-600");
    });
    this.cardIconAnimationTargets.forEach((element) => {
      element.classList.remove("tw-translate-x-0");
      element.classList.add("md:tw-translate-x-4");
      element.classList.add("tw-translate-x-5");
    });
    this.cardStatTarget.classList.remove("tw-hidden");
    this.cardStatTarget.classList.add("tw-block");
  }

  _hideCardStat() {
    Cookies.set("indexGlobalCardStat", false, { path: "/" });
    this.cardIconTargets.forEach((element) => {
      element.classList.remove("tw-bg-primary-600");
      element.classList.add("tw-bg-gray-200");
    });
    this.cardIconAnimationTargets.forEach((element) => {
      element.classList.remove("md:tw-translate-x-4");
      element.classList.add("tw-translate-x-0");
      element.classList.remove("tw-translate-x-5");
    });
    this.cardStatTarget.classList.remove("tw-block");
    this.cardStatTarget.classList.add("tw-hidden");
  }

  _textStatEnable() {
    return Cookies.get("indexGlobalTextStat") === "true";
  }

  _showTextStat() {
    Cookies.set("indexGlobalTextStat", true, { path: "/" });
    this.showMoreLabelTarget.innerHTML = "";
    this.showMoreLabelTarget.innerHTML = this.showMoreLabelTarget.dataset.textHide;
    this.textStatTarget.classList.remove("tw-hidden");
    this.textStatTarget.classList.add("tw-block");
  }

  _hideTextStat() {
    Cookies.set("indexGlobalTextStat", false, { path: "/" });
    this.showMoreLabelTarget.innerHTML = "";
    this.showMoreLabelTarget.innerHTML = this.showMoreLabelTarget.dataset.textReadMore;
    this.textStatTarget.classList.remove("tw-block");
    this.textStatTarget.classList.add("tw-hidden");
  }
}
