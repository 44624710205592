import { Controller } from "stimulus";

let Highcharts;
export default class extends Controller {
  static values = { sparkline7d: Array, unit: String }
  static targets = [ "spark" ]
  connect(){
    Promise.all([
      import("highcharts/highcharts"),
    ]).then(res => {
      Highcharts = res[0];
      Highcharts.SparkLine = function (a, b, c) {
        const hasRenderToArg = typeof a === 'string' || a.nodeName;
        let options = arguments[hasRenderToArg ? 1 : 0];
        const defaultOptions = {
          chart: {
            renderTo: (options.chart && options.chart.renderTo) || (hasRenderToArg && a),
            backgroundColor: null,
            borderWidth: 0,
            type: 'area',
            margin: [2, 0, 2, 0],
            width: 120,
            height: 20,
            style: {
              overflow: 'visible'
            },
            // small optimalization, saves 1-2 ms each sparkline
            skipClone: true
          },
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          xAxis: {
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            startOnTick: false,
            endOnTick: false,
            tickPositions: [],
          },
          yAxis: {
            endOnTick: false,
            startOnTick: false,
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            tickPositions: [0]
          },
          legend: {
            enabled: false
          },
          tooltip: {
            hideDelay: 0,
            outside: true,
            shared: true
          },
          plotOptions: {
            series: {
              animation: false,
              lineWidth: 1,
              shadow: false,
              states: {
                hover: {
                  lineWidth: 1
                }
              },
              marker: {
                radius: 1,
                states: {
                  hover: {
                    radius: 2
                  }
                }
              },
              fillOpacity: 0.25
            },
            column: {
              negativeColor: '#910000',
              borderColor: 'silver'
            }
          }
        };
      
        options = Highcharts.merge(defaultOptions, options);
      
        return hasRenderToArg ?
          new Highcharts.Chart(a, options, c) :
          new Highcharts.Chart(options, b);
      };

      Highcharts.SparkLine(this.sparkTarget, {
        series: [{
            data: this.sparkline7dValue.map(x => x[0]),
            pointStart: 1,
            tooltip: {
              // headerFormat: '<span style="font-size: 10px">' + this.index + '</span><br/>',
              headerFormat: "",
              pointFormat: `<b>{point.y} ${this.unitValue}</b>`
            },
        },],
        chart: {}
      });
    });
  }
}