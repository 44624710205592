import { Controller } from "stimulus";
import {
  debounce,
  getCSRFToken,
  dispatchToast,
  keyBinding,
} from "../util";
import {
  addPortfolioCoinRequest,
  sharePortfolioRequest,
  unSharePortfolioRequest,
  updatePortfolioShareLinkRequest,
  setPortfolioAsDefault,
} from "../routes/portfolio";
import { confirmationDialog } from "../helpers/dialog";
import { validateInput } from "../helpers/validate";

export default class extends Controller {
  static targets = [
    "coinResultsList",
    "coinSearchInput",
    "portfolioCoin",
    "emptyNotice",
    "shareLink",
    "shareLinkToolTip",
    "slug",
    "defaultCheck",
    "defaultStatus",
  ];

  initialize() {
    this.debouncedSearchCoin = debounce(this.searchCoin.bind(this), 300);
    // initialize callback when modal closes
    this.addCoinModal.on("hidden.bs.modal", e => {
      this.coinSearchInputTarget.value = "";
      this.coinSearchInputTarget.classList.remove("loading");
      this.coinResultsListTarget.innerHTML = "";
    });

    // initialize callback when modal opens.
    this.addCoinModal.on("shown.bs.modal", e => {
      this.coinSearchInputTarget.focus();
      this.coinResultsListTarget.innerHTML = this.trendingCoinTemplate;
    });

    this.connect();
  }

  get portfolioId() {
    return this.data.get("portfolioId");
  }

  get trendingCoinTemplate() {
    const template = this.trendingCoins.map(({item: coin}) => {
      const imgSrc = `<img src="${coin.small}" alt="${coin.name}" height="18px" width="18px" class="mr-2" />`,
            content = imgSrc + `${coin.name} (${coin.symbol})`;

      if (this.coinAdded.includes(coin.coin_id)) {
        return `
          <li class="list-group-item tw-flex tw-items-center"
            href="#new_transaction_modal"
            data-action="click->portfolio-coin-transactions-new#updateCoinIdValue"
            data-toggle="modal"
            data-dismiss="modal"
            data-coin-id="${coin.coin_id}"
            data-action="click->portfolio-coin-transactions-new#updateCoinIdValue"
            data-coin-symbol="${coin.symbol}"
            data-transaction-path="/en/portfolios/${this.portfolioId}/${coin.slug}/transactions"
            data-price-btc="#${coin.price_btc}">
            ${content}
          </li>
        `;
      } else {
        return `
          <li class="list-group-item tw-flex tw-items-center"
            data-action="click->portfolios#addCoin"
            data-dismiss="modal"
            data-refresh="true"
            data-portfolio-id="${this.portfolioId}"
            data-coin-id="${coin.coin_id}"
            data-coin-symbol="${coin.symbol}">
            ${content}
          </li>
        `;
      }
    }).join("")

    return `
      <ul class="list-group">
        <li class="p-2 font-semibold font-bold tw-list-none">Trending Search 🔥</li>
        ${template}
      </ul>
    `
  }

  get trendingCoins() {
    return (this.coinSearchInputTarget.dataset.trending ?
      JSON.parse(this.coinSearchInputTarget.dataset.trending).coins :
      []); // refer to portfolio/_add_coin_modal.html.erb
  }

  get coinAdded() {
    return JSON.parse(this.data.get("coinAdded"));
  }

  get addCoinModal() {
    return $(`#addCoinModal_${this.portfolioId}`);
  }

  connect() {
    if (this.hasPortfolioCoinTarget) {
      $(this.portfolioCoinTarget).sortable({
        handle: ".handle",
        nested: false,
        vertical: false,
        placeholder:
          "portfolio_coin col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-5-cols dashboard-favorite ui-sortable-placeholder portfolio-placeholder portfolio_coin",
        items: ".portfolio_coin:not(.locked)",
        start() {
          $(".price-highlight-placeholder").css("z-index", 9999);

          // Inspired from http://stackoverflow.com/questions/4299241/jquery-sortable-lists-and-fixed-locked-items
          return $(".locked", this).each(function() {
            const $this = $(this);
            return $this.data("pos", $this.index() - 2);
          });
        },

        //# Hack minus 2 to make it work here for some unknown reason :(
        change() {
          const $sortable = $(this);
          const $statics = $(".locked", this).detach();
          const $helper = $(
            '<div class="col-md-4" style="border: 1px solid rgb(239, 239, 239); height: 512px; "></div>'
          ).prependTo(this);
          $statics.each(function() {
            const $this = $(this);
            const target = $this.data("pos");
            return $this.insertAfter($(".portfolio_coin", $sortable).eq(target));
          });
          return $helper.remove();
        },

        stop() {
          return $(".price-highlight-placeholder").css("z-index", -9999);
        },

        update() {
          return fetch($(this).data("update-url"), {
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": getCSRFToken()
            },
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify({
              metric_current: $(this)
                .sortable("toArray")
                .map(id => {
                  const splitArray = id.split("_");
                  return +splitArray[splitArray.length - 1];
                })
            })
          });
        }
      });
    }
  }

  _renderFormError = (errorTextSelector, message) => {
    errorTextSelector.innerText = message;
    this.slugTarget.classList.add("tw-border-red-500");
  }

  _resetForm = (errorTextSelector) => {
    errorTextSelector.innerText = null;
    this.slugTarget.classList.remove("tw-border-red-500");
  }

  updateShareLink(e) {
    const params = { portfolioId: this.portfolioId },
          target = e.currentTarget,
          editSharedLinkDialog = $(`#${target.dataset.confirmModal}`),
          editSharedLinkConfirmButton = editSharedLinkDialog.find(`#${target.dataset.confirmButton}`),
          errorTextSelector = editSharedLinkDialog.find(".text-danger")[0],
          success = (res) => {
            this.shareLinkTarget.innerText = res.path_preview;
            this.shareLinkToolTipTarget.
              querySelector(".fa-copy").
              dataset.shareLink = res.path; // update link for copy button.
            this._resetForm(errorTextSelector);

            editSharedLinkDialog.modal("hide"); // close the dialog after success.
            dispatchToast("Portfolio shared link Updated!");
          },
          error = (res) => {
            this._renderFormError(errorTextSelector, res.message);
            e.stopPropagation();
          },
          formAction = () => {
            // check if is empty input
            const valid = validateInput(this.slugTarget, () => {
              this._renderFormError(errorTextSelector, "cannot be empty");
            })

            if (valid) {
              this._resetForm(errorTextSelector);
              updatePortfolioShareLinkRequest({...params, slug: this.slugTarget.value}, success, error);
            }
          };


    // binding key event listener
    keyBinding(editSharedLinkDialog[0], {enterAction: formAction})
    // open modal dialog
    confirmationDialog(
      () => { editSharedLinkDialog.modal("show") },
      editSharedLinkConfirmButton,
      formAction,
    )
  }

  copySharedLink(e) {
    const sharedLink = e.target.getAttribute("data-share-link");
    this._copyToClipboard(sharedLink);
    this._switchToCheckIcon(e);

    dispatchToast("Portfolio Shared Link copied!");

    setTimeout(() => {
      this._switchToCopyIcon(e);
    }, 1000);

    e.preventDefault();
    e.stopPropagation();
  }

  _copyToClipboard(text) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  _switchToCheckIcon(e) {
    e.target.classList.remove("fa-copy");
    e.target.classList.add("fa-check-circle", "text-primary");
  }

  _switchToCopyIcon(e) {
    e.target.classList.remove("fa-check-circle", "text-primary");
    e.target.classList.add("fa-copy");
  }

  toggleSharing(e) {
    const target = e.currentTarget,
          toggle = target.querySelector("span"),
          portfolioId = target.dataset.portfolioId;

    if (target.classList.contains("tw-bg-primary-500")) {
      target.classList.add("tw-bg-gray-200");
      target.classList.remove("tw-bg-primary-500");
      toggle.classList.add("tw-translate-x-0");
      toggle.classList.remove("tw-translate-x-5");

      this._unshare(portfolioId);
    } else {
      target.classList.add("tw-bg-primary-500");
      target.classList.remove("tw-bg-gray-200");
      toggle.classList.add("tw-translate-x-5");
      toggle.classList.remove("tw-translate-x-0");

      this._share(portfolioId);
    }

    e.stopPropagation();
  }

  setDefault(e) {
    const { portfolioId } = e.currentTarget.dataset;
    const params = { portfolioId: portfolioId, default: true }

    const success =(_res => {
      dispatchToast("Portfolio has been set as the default");

      // Reset other portfolios status
      [...document.querySelectorAll("[data-portfolios-target='defaultStatus']")].forEach(elem => (
        elem.classList.add("tw-hidden")
      ));
      [...document.querySelectorAll("[data-portfolios-target='defaultCheck']")].forEach(elem => (
        elem.classList.add("tw-invisible")
      ));

      // Show for the default portfolio
      this.defaultCheckTarget.classList.remove("tw-invisible");
      [...this.defaultStatusTargets].forEach(elem => (
        elem.classList.remove("tw-hidden")
      ));

      // Specific to dropdown in Portfolio Overview
      [...document.querySelectorAll("[data-portfolios-target='defaultStatus']")]
        .forEach(elem => {
          if (elem.dataset.portfolioId != portfolioId.toString()) {
            return;
          }

          elem.classList.remove("tw-hidden")
        });
    });

    setPortfolioAsDefault(params, success);
    e.stopPropagation();
  }

  _share(portfolioId) {
    const params = {
      portfolioId: portfolioId
    };
    this.shareLinkToolTipTarget.classList.remove("tw-hidden");

    const success =(_res => {
      dispatchToast("Portfolio Shared!");
    });

    sharePortfolioRequest(params, success);
  }

  _unshare(portfolioId) {
    const params = {
      portfolioId: portfolioId
    };
    this.shareLinkToolTipTarget.classList.add("tw-hidden");

    const success =(_res => {
      dispatchToast("Portfolio Un-shared!");
    });

    unSharePortfolioRequest(params, success);
  }

  searchCoin() {
    const searchPath = this.coinSearchInputTarget.dataset.actionPath,
          coinSearchInput = this.coinSearchInputTarget.value.trim();

    if (coinSearchInput === "") {
      // show trending coin if search input was blank
      this.coinResultsListTarget.innerHTML = this.trendingCoinTemplate;
    } else {
      this.coinResultsListTarget.innerHTML = null;
      this.coinSearchInputTarget.classList.add("loading");

      // Keep track of latest search query
      this._searchCount = this._searchCount || 0;
      this._searchCount += 1;
      const searchCount = this._searchCount;

      fetch(`${searchPath}?query=${coinSearchInput}`, {
        credentials: "same-origin"
      })
        .then(response => response.text())
        .then(res => {
          if (searchCount != this._searchCount) {
            // Old search result coming in late. Ignore.
            return;
          }

          if (res) {
            this.coinSearchInputTarget.classList.remove("loading");
            this.coinResultsListTarget.innerHTML = res;
          }
        });
    }
  }

  // Used in dashboard and coin list (star)
  addCoin(e) {
    const coinId = e.currentTarget.getAttribute("data-coin-id"),
          source = e.currentTarget.getAttribute("data-source"),
          refresh = e.currentTarget.getAttribute("data-refresh"),
          params = {
            portfolioId: e.currentTarget.getAttribute("data-portfolio-id"),
            coinId: coinId,
            source: source,
          };

    addPortfolioCoinRequest(params, "POST", (res) => {
      if (refresh.toString() === "true") {
        window.location.reload();
      }
    })

    e.preventDefault();
  }
}
