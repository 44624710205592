import { Controller } from "stimulus";
import { EventCurrencyChanged, EventTickersMoreLoaded } from "../events";
import { EventPriceLoaded } from "../events";
import { reloadBootstrapTooltip } from "../util/tooltip";
import { sortWithTableSort } from "../util";
import "floatthead";

export default class extends Controller {
  static targets = [
    "moreFilters",
    "tableContainer",
    "table",
    "tab",
    "paginate",
    "paginatedShowMoreTbody",
    "showMore",
    "loading",
    "tableSearchFilter",
    "line",
  ];

  connect() {
    if (this.hasPaginatedShowMoreTbodyTarget) {
      this.page = 0;
      this.perPage = 100;
      this.rowCount = this.paginatedShowMoreTbodyTarget.children.length;
      // Hide button if first page not full
      var hasMoreToLoad = this.rowCount == this.perPage;
      this._showButton(hasMoreToLoad);
    }

    this.queryString = window.location.search;

    this._setupTableSorting()
  }

  toggleMoreFilters(e) {
    e.target.classList.toggle("tw-border-primary-500");
    this.lineTarget.classList.toggle("tw-hidden");
    this.moreFiltersTarget.classList.toggle("tw-hidden");
  }

  executeFilter(event) {
    var hashing_algorithm = event.target.getAttribute("data-hashing-algorithm");
    var asset_platform_id = event.target.getAttribute("data-asset-platform-id");
    var category_id = event.target.getAttribute("data-category-id");

    var searchParams = new URLSearchParams("");
    if (hashing_algorithm != null) {
      searchParams.append("hashing_algorithm", hashing_algorithm);
    } else {
      searchParams.delete("hashing_algorithm");
    }
    if (asset_platform_id != null) {
      searchParams.append("asset_platform_id", asset_platform_id);
    } else {
      searchParams.delete("asset_platform_id");
    }
    if (category_id != null) {
      searchParams.append("category_id", category_id);
    } else {
      searchParams.delete("category_id");
    }
    
    window.location.search = searchParams.toString();
  }

  /* Triggers */
  triggerFloatThead() {
    const $table = $(this.tableTarget);

    window.requestAnimationFrame(() => {
      $table.floatThead("destroy");
      $table.floatThead({
        position: "absolute",
        responsiveContainer: function($table) {
          return $table.closest(".table-responsive");
        }
      });
    });
  }

  /* Helper Methods */

  filterTable(e) {
    var value = this.tableSearchFilterTarget.value.toLowerCase();
    var tables = this.tableTargets;
    var trs = [];
    tables.forEach(function(table) {
      for (let tr of table.getElementsByTagName("tbody")[0].getElementsByTagName("tr")) {
        trs.push(tr);
      }
    });
    $(trs).filter(function() {
      $(this).toggle(
        $(this)
          .text()
          .toLowerCase()
          .indexOf(value) > -1
      );
    });

    // Meant to target Derivatives ticker filtering to hide section
    tables.forEach(function(table) {
      var table_trs = $(table.getElementsByTagName("tbody")[0].getElementsByTagName("tr"));

      if (
        table_trs.filter(function() {
          return $(this).css("display") == "none";
        }).length == table_trs.length
      ) {
        $(table)
          .parents(".contract-table")
          .hide();
      } else {
        $(table)
          .parents(".contract-table")
          .show();
      }
    });
  }

  showMore(e) {
    this._showButton(false);
    this._showSpinner(true);
    this.page += 1;

    fetch(`${this.showMoreTarget.dataset.url}?page=${this.page}&per_page=${this.perPage}`, {
      credentials: "same-origin"
    })
      .then(response => response.text())
      .then(res => {
        if (res) {
          this.paginatedShowMoreTbodyTarget.insertAdjacentHTML("beforeend", res);

          this._showButton(true);
          this._showSpinner(false);
          reloadBootstrapTooltip();

          const newRowCount = this.paginatedShowMoreTbodyTarget.children.length;
          if (this.perPage > newRowCount - this.rowCount) {
            // Nothing more to load.
            this._showButton(false);
            this._showSpinner(false);
          }
          this.rowCount = newRowCount;
          // Incoming rows are in USD, we need to convert to target currency. Run price formatter.
          this.element.dispatchEvent(
            new CustomEvent(EventPriceLoaded, {
              bubbles: true
            })
          );
          this.element.dispatchEvent(
            new CustomEvent(EventTickersMoreLoaded, {
              bubbles: true
            })
          );
        } else {
          this._showButton(false);
          this._showSpinner(false);
        }
      });

    // Prevent hash from showing in URL
    e.preventDefault();
  }

  _showButton(show) {
    if (!this.hasShowMoreTarget) {
      return;
    }

    this.showMoreTarget.style.display = show ? "" : "none";
  }

  _showSpinner(show) {
    this.loadingTarget.style.display = show ? "" : "none";
  }

  _setupTableSorting() {
    if (!this.hasTableTarget) {
      return;
    }

    // portfolio table has data populated through API, sorting needs to be done later.
    if (this.data.get("page") !== "portfolio") {
      sortWithTableSort(this.tableTargets)
    }

    // For short table, no need to stick thead
    if(this.tableTarget.rows.length > 10){
      const observer = new IntersectionObserver( (changes)=> {
        for (const change of changes) {
          if (this.triggeredFloatThead === true){
            return
          }
          if (change.isIntersecting === false) {
            return
          }
          this.loadedChart = true
          this.triggerFloatThead();
        }
      },  { threshold: 0.1 });
      observer.observe(this.tableTarget, { delay: 100 })
    }
  }
}
