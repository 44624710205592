import { Controller } from "stimulus";
import { EventToast } from "../events";
import { createPortfolioRequest } from "../routes/portfolio";
import { confirmationDialog } from "../helpers/dialog";
import { validateInput } from "../helpers/validate";

export default class extends Controller {
  static targets = ["title"];

  connect() {}

  createPortfolio(event) {
    const currentTarget = event.currentTarget,
          newPortfolioConfirmModal = $(`#${currentTarget.dataset.confirmModal}`),
          newPortfolioConfirmButton = newPortfolioConfirmModal.find(`#${currentTarget.dataset.confirmButton}`);

    confirmationDialog(
      () => { newPortfolioConfirmModal.modal("show") },
      newPortfolioConfirmButton,
      () => {
        const valid = validateInput(this.titleTarget, () => {
          this.titleTarget.classList.add("border-danger");
          this.titleTarget.parentNode.querySelector(".text-danger").innerText = "cannot be empty";
        })

        if (valid) {
          this.titleTarget.classList.remove("border-danger");
          this.titleTarget.parentNode.querySelector(".text-danger").innerText = "";
          newPortfolioConfirmModal.modal("hide");
          this._createPortfolioCallback(currentTarget);
        }
      }
    );
  }

  _createPortfolioCallback(target) {
    const params = {
            portfolio: {
              title: this.titleTarget.value,
            }
          };

    createPortfolioRequest(params, "POST", this._createPortfolioSuccess);
  }

  _createPortfolioSuccess(response) {
    if (response.message == "success") {
      window.dispatchEvent(
        new CustomEvent(EventToast, {
          detail: {
            notice: "Successful"
          }
        })
      )
      window.location.reload()
    } else {
      throw new Error("Failed to create");
    }
  }
}
