import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["searchInput", "itemList"];

  filterItems() {
    const searchInput = this.searchInputTarget.value.toLowerCase();
    const searchItemsEl = this.itemListTarget.getElementsByTagName("li");

    for(var item of searchItemsEl){
      item.hidden = !(item.textContent.toLowerCase().indexOf(searchInput) > -1);
    }
  }

  preventCollapse(e) {
    e.stopPropagation();
  }
}
