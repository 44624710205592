import { Controller } from "stimulus";
import { EventCurrencyChanged } from "../events";
import { convertPrice } from "../util/convert";
import { getActiveCurrency, displayRawNumber } from "../util/currency";

// Direction of conversion: From LEFT to RIGHT
const DIRECTION = {
  FROM_FIAT: "fromFiat", // To coin currency
  TO_FIAT: "toFiat" // To fiat currency (from menu)
};

// Reference to LEFT/RIGHT input boxes
const INPUT = {
  FROM: "from", // the left box
  TO: "to" // the right box
};

export default class extends Controller {
  static targets = ["fromInput", "fromSymbol", "toInput", "toSymbol", "price"];

  connect() {
    // Set the default direction: Coin to Fiat
    this.direction = DIRECTION.TO_FIAT;

    window.addEventListener(EventCurrencyChanged, e => {
      const currencyCode = e.detail.currencyCode;
      if (currencyCode == null) {
        return;
      }

      if (this.direction === DIRECTION.TO_FIAT) {
        this.toSymbolTarget.textContent = currencyCode;
      } else {
        this.fromSymbolTarget.textContent = currencyCode;
      }
      this.recalculate();
    });

    if (typeof MutationObserver !== "undefined") {
      // Observe price changes and trigger recalculation
      const observer = new MutationObserver(mutationsList => {
        for (var mutation of mutationsList) {
          if (mutation.type == "attributes" && mutation.attributeName === "data-price-btc") {
            this.recalculate();
          }
        }
      });
      observer.observe(this.priceTarget, { attributes: true });
    }
  }

  recalculate() {
    if (this.direction === DIRECTION.TO_FIAT) {
      this.calculateTo();
    } else {
      this.calculateFrom();
    }
  }

  calculateFrom() {
    this.calculate(INPUT.FROM);
  }

  calculateTo() {
    this.calculate(INPUT.TO);
  }

  changeDirection() {
    // Flip the direction
    this.direction =
      this.direction === DIRECTION.FROM_FIAT ? DIRECTION.TO_FIAT : DIRECTION.FROM_FIAT;

    const tempSym = this.toSymbolTarget.textContent;
    this.toSymbolTarget.textContent = this.fromSymbolTarget.textContent;
    this.fromSymbolTarget.textContent = tempSym;

    const tempInput = this.toInputTarget.value;
    this.toInputTarget.value = this.fromInputTarget.value;
    this.fromInputTarget.value = tempInput;
  }

  calculate(target) {
    // This rate is from coin to fiat currency.
    const priceBTC = this.priceTarget.getAttribute("data-price-btc");
    if (!priceBTC) {
      throw new Error("Missing BTC price");
    }

    const conversionRate = +convertPrice(+priceBTC, getActiveCurrency(), true);

    let amountEntered;
    if (target === INPUT.TO) {
      amountEntered = this.fromInputTarget.value;
    } else {
      amountEntered = this.toInputTarget.value;
    }

    if (amountEntered == null || amountEntered.trim().length === 0) {
      return;
    }

    let total;

    if (this.fromSymbolTarget.textContent.trim() === this.toSymbolTarget.textContent.trim()) {
      // Conversion for same currency is same amount, skip conversion
      total = amountEntered;
    } else if (
      (target === INPUT.TO && this.direction === DIRECTION.TO_FIAT) ||
      (target === INPUT.FROM && this.direction === DIRECTION.FROM_FIAT)
    ) {
      total = conversionRate * amountEntered;
    } else {
      total = amountEntered / conversionRate;
    }

    if (target === INPUT.TO) {
      this.toInputTarget.value = displayRawNumber(total);
    } else {
      this.fromInputTarget.value = displayRawNumber(total);
    }
  }
}
