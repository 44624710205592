import { Controller } from "stimulus";
import { loadScript } from "../util/load_script";
import showAds from "../util/show_ads";

export default class extends Controller {
  static targets = ["banner"]

  connect() {
    if (!this.hasBannerTarget) {
      return;
    }

    if (showAds()) {
      this._showAds();

      loadScript(true)
        .url(
          "https://cdn4.buysellads.net/pub/coingecko.js?"+ (new Date() - new Date() % 600000)
        ).then(() => {
          this._setAdsTargeting();
        });
    }
  }

  _showAds() {
    this.bannerTargets.forEach((e) => e.classList.remove("tw-hidden"));
  }

  _setAdsTargeting() {
    const WHITELISTED_CONTROLLER_ACTIONS = {
      derivatives: ["index", "futures"],
      exchanges: ["index", "index_derivatives"],
      etf: ["index"]
    };

    const data = this.element.dataset;
    const coinName = data["coinName"];
    const controllerName = data["controllerName"];
    const actionName = data["actionName"];
    const categoryKey = data["categoryKey"];
    const locale = data["locale"];

    let targets = [];

    if (coinName !== "") {
      targets = ["coins", [coinName, locale]];
    } else if (controllerName === "defi" && actionName === "index") {
      targets = ['coingecko_page', ['defi', locale]];
    } else if (controllerName === "coins" && actionName === "index") {
      targets = ["coins", ["hp", locale]];
    } else if (controllerName === "pools" && actionName === "index") {
      targets = ['coingecko_page', ['yield-farming', locale]];
    } else if (controllerName === "coins" && actionName === "category_index" ) {
      targets = ["categories", [categoryKey, locale]];
    } else {
      const whitelistedControllerAction = WHITELISTED_CONTROLLER_ACTIONS[controllerName];

      if (!!(whitelistedControllerAction?.find(action => action === actionName))) {
        targets = [controllerName, [actionName, locale]];
      }
    }

    if (targets.length > 0) {
      window.optimize = window.optimize || { queue: [] };
      window.optimize.queue.push(() => {
        window.optimize.customTargeting.push(targets);
      });
    }
  }
}
