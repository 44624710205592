import { Controller } from "stimulus";
import { EventCurrencyChanged } from "../events";
import { formatCurrency } from "../util/currency";

export default class extends Controller {
  static targets = ["currencyInput", "currentPrice", "initialPriceInput", "loggedInContent", "loggedOutContent", "form"]

  connect() {
    const that = this;

    this.currencyInputTarget.addEventListener("change", function(e) {
      that.updateCurrentPrice(this.value);
    }, false);

    this.formTarget.addEventListener("submit", function(e) {
      that.requestNotificationPermission();
    });

    window.addEventListener(EventCurrencyChanged, e => {
      this.updateCurrentPrice(e.detail.currencyCode);
    });

    this._toggleLoggedInContent();
  }

  requestNotificationPermission() {
    const fcmController = this.application.getControllerForElementAndIdentifier(
      document.getElementById("firebaseFCM"),
      "firebase-fcm"
    );

    fcmController.subscribeDeviceToFCM(window.messagingFCM);
  }

  _toggleLoggedInContent() {
    fetch("/accounts/logged_in_user.json", { credentials: "same-origin" })
      .then(response => response.json())
      .then(json => {
        if (json.current_user) {
          this._toggleContent(true);
        } else {
          this._toggleContent(false);
        }
      });
  }

  _toggleContent(loggedIn) {
    if (loggedIn) {
      this.loggedOutContentTarget.style.display = "none";
      this.loggedInContentTarget.style.display = "";
    } else {
      this.loggedOutContentTarget.style.display = "";
      this.loggedInContentTarget.style.display = "none";
    }
  }

  updateCurrentPrice(vsCurrency) {
    const lowerVsCurrency = vsCurrency.toLowerCase();

    const currencyValue = JSON.parse(this.currentPriceTarget.dataset.priceJson)[lowerVsCurrency];
    const formattedCurrency = formatCurrency(currencyValue, lowerVsCurrency);

    this.currentPriceTarget.textContent = formattedCurrency;
    this.initialPriceInputTarget.value = currencyValue;

    if (this.currencyInputTarget.value !== vsCurrency.toUpperCase()) {
      this.currencyInputTarget.value = vsCurrency.toUpperCase();
    }
  }

  updateAlertPrice(e) {
    const userInput = parseFloat(e.currentTarget.innerText)/100;
    const currentPriceCurrency = document.querySelectorAll('[data-target="add-alerts.currentPrice"]')[0].innerText;
    const currentPriceValue = Number(currentPriceCurrency.replace(/[^0-9\.]+/g,""));

    document.getElementById('target_price').value = currentPriceValue * (1 + userInput);
  }
}
