import { Controller } from "stimulus";
import { debounce, getCSRFToken } from "../util";

const INPUT_DEBOUNCE_DURATION_MS = 300;

export default class extends Controller {
  static targets = ["password", "submit", "weakPassword", "mediumPassword", "strongPassword", "passwordIndicator", "errorMessage"];

  connect() {
    this._debouncedValidatePassword = debounce(this._validatePassword, INPUT_DEBOUNCE_DURATION_MS);
  }

  showPassword(e) {
    if (this.passwordTarget.type === "password") {
      e.target.classList.remove("fa-eye");
      e.target.classList.add("fa-eye-slash");
      this.passwordTarget.type = "text";
    } else {
      e.target.classList.remove("fa-eye-slash");
      e.target.classList.add("fa-eye");
      this.passwordTarget.type = "password";
    }
  }

  onInputChanged() {
    this._debouncedValidatePassword();
  }

  _validatePassword() {
    fetch("/account/validate_password", {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken()
      },
      body: JSON.stringify({password: this.passwordTarget.value})
    })
      .then((response) => response.json())
      .then((data) => {
        var target = this._keyTargetMap()[data["score"]]
        this._togglePasswordCheck(target)
        this._toggleErrorMessage(data["error_message"])
        this._setWindowVariable(data["valid"])
        this._toggleInput(data["valid"])
      })
  }

  _toggleErrorMessage(message) {
    this.errorMessageTarget.innerHTML = message || "";
  }

  _setWindowVariable(flag) {
    window.passwordVerified = flag ? true : false;
  }

  _hasCaptcha() {
    return this.passwordTarget.dataset.withcaptcha === "true";
  }

  _toggleInput(value) {
    if (this._hasCaptcha()) {
      if (window.captchaVerified !== true) {
        return;
      }
    }

    if (value === true) {
      this.passwordTarget.classList.remove("border-danger");
      this.submitTarget.disabled = false;
    } else {
      this.passwordTarget.classList.add("border-danger");
      this.submitTarget.disabled = true;
    }
  }

  _togglePasswordCheck(elementTarget) {
    this.passwordIndicatorTargets.forEach((e) => {
      if (e === elementTarget) {
        e.classList.remove("tw-hidden");
        return;
      }
      e.classList.add("tw-hidden");
    });
  }

  _keyTargetMap() {
    return {
      "0": this.weakPasswordTarget,
      "1": this.weakPasswordTarget,
      "2": this.mediumPasswordTarget,
      "3": this.mediumPasswordTarget,
      "4": this.strongPasswordTarget
    }
  }
}
