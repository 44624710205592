import DialogController from "./dialog_controller";
import { EventToast } from "../events";
import { getCSRFToken } from "../util";

import { resetPortfolioRequest } from "../routes/portfolio";
export default class extends DialogController {
  static targets = ["title"];

  connect() {}

  updatePortfolio(event) {
    const portfolio = event.currentTarget,
          editPortfolioDialog = $(`#${portfolio.dataset.confirmModal}`),
          editPortfolioConfirmButton = editPortfolioDialog.find(`#${portfolio.dataset.confirmButton}`);

    this._confirmationDialog(
      () => { editPortfolioDialog.modal("show") },
      editPortfolioConfirmButton,
      () => {
        editPortfolioDialog.modal("hide");
        this._updatePortfolioCallBack(portfolio);
      }
    );
  }

  resetPortfolio(event) {
    const portfolio = event.currentTarget.dataset,
          resetPortfolioDialog = $(`#${portfolio.confirmModal}`),
          resetPortfolioConfirmButton = resetPortfolioDialog.find(`#${portfolio.confirmButton}`),
          successCb = () => window.location.reload();

    this._confirmationDialog(
      () => { resetPortfolioDialog.modal("show") },
      resetPortfolioConfirmButton,
      () => {
        resetPortfolioDialog.modal("hide");
        resetPortfolioRequest({portfolioId: portfolio.portfolioId}, successCb);
      }
    );
  }

  _updatePortfolioCallBack(portfolio) {
    const actionPath = portfolio.dataset.actionPath,
          params = {
            portfolio: {
              title: this.titleTarget.value,
            }
          };

    fetch(`${actionPath}`,
      {
        credentials: "same-origin",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCSRFToken()
        },
        body: JSON.stringify(params)
      })
      .then(res => res.json())
      .then(data => {
        if (data.message == "success") {
          window.dispatchEvent(
            new CustomEvent(EventToast, {
              detail: {
                notice: "Successful"
              }
            })
          )
          window.location.reload();
        } else {
          throw new Error("Failed to update");
        }
      })
      .catch(error => {
        window.dispatchEvent(
          new CustomEvent(EventToast, {
            detail: {
              notice: "Unsuccessful"
            }
          })
        )
      });
  }
}
