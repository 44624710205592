import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["banner", "iOSBanner", "androidBanner"];

  connect() {
    if (this._isAndroid() && this._closeBannerFalse()) {
      this._showBanner();
      this.androidBannerTarget.classList.remove("tw-hidden");
      return;
    }

    if (this._isIos() && this._closeBannerFalse()) {
      this._showBanner();
      this.iOSBannerTarget.classList.remove("tw-hidden");
      return;
    }
  }

  closeBanner() {
    Cookies.set("closeAppFlyerBanner", true, { expires: 7, path: "" });
    this._removeBanner();
  }

  _closeBannerFalse() {
    return !Cookies.get("closeAppFlyerBanner");
  }

  _isAndroid() {
    return /android/.test(navigator.userAgent.toLowerCase());
  }

  _isIos() {
    return /iphone/.test(navigator.userAgent.toLowerCase());
  }

  _showBanner() {
    this.bannerTarget.classList.remove("tw-hidden");
    this.bannerTarget.classList.add("fixed-bottom");
  }

  _removeBanner() {
    this.bannerTarget.classList.remove("fixed-bottom");
    this.bannerTarget.classList.add("tw-hidden");
  }
}
