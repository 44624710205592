import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
 static targets = ["topBannerContainer"];

 closeBanner() {
   this.topBannerContainerTarget.style.display = "none";
   Cookies.set("top_banner_disabled", 1, { expires: 1, path: "/" });
 }
}
