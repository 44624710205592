import { Controller } from "stimulus";
import { getCSRFToken } from "../util";

export default class extends Controller {
  static targets = ["email", "emailLabel", "submitButton", "campaignId"];

  subscribeNewsletter(e) {
    e.preventDefault();
    let params = {
      email: this.emailTarget.value,
      campaignId: this.campaignIdTarget.value
    };

    fetch("/newsletter/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken()
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        window._vis_opt_queue = window._vis_opt_queue || [];
        window._vis_opt_queue.push(function() {_vis_opt_goal_conversion(200);});
        return response.json();
      })
      .then(data => {
        if (data.httpStatus == 202) {
          this._displaySuccessMessage();
        } else if (data.httpStatus == 406) {
          this._displayErrorMessage("Invalid Campaign Id");
        } else {
          let errorMessage = "";

          if (data.context.length > 0) {
            errorMessage = data.context[0]["errorDescription"];
          } else {
            errorMessage = data.message;
          }

          this._displayErrorMessage(errorMessage);
        }
      });
  }

  _displaySuccessMessage() {
    this.emailTarget.classList.remove("border");
    this.emailTarget.classList.remove("border-danger");
    this.emailLabelTarget.classList.add("text-primary");
    this.emailLabelTarget.innerText = this.submitButtonTarget.getAttribute("data-success-text");
  }

  _displayErrorMessage(message) {
    this.emailTarget.classList.add("border");
    this.emailTarget.classList.add("border-danger");
    this.emailLabelTarget.classList.remove("text-primary");
    this.emailLabelTarget.innerHTML = message;
  }
}
