import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["banner"];

  connect() {
    if (this._isBannerDisabled() === false) {
      this._showBanner();
    }
  }

  closeBanner() {
    Cookies.set("isNewsletterBannerDisabled", true, { expires: 14, path: "" });
    this.bannerTarget.classList.add("tw-hidden");
  }

  _isBannerDisabled() {
    return Cookies.get("isNewsletterBannerDisabled") === "true" ? true : false;
  }

  _showBanner() {
    this.bannerTarget.classList.remove("tw-hidden");
  }
}
