import { Controller } from "stimulus";
import { EventToast } from "../events";

export default class extends Controller {
  changeCursorToPointer(e) {
    e.target.style.cursor = "pointer";
  }

  copyAddress(e) {
    e.preventDefault();
    let address = e.target.getAttribute("data-address");
    this._copyToClipboard(address);
    this._switchToCheckIcon(e);
    this._showToast("Contract address copied!");
  }

  clickContractAddressModal(e) {
    e.stopPropagation();
  }

  copyTokenToMetamask(e) {
    e.preventDefault();
    try {
      let tokenAddress = e.target.getAttribute("data-address");
      let tokenSymbol = e.target.getAttribute("data-symbol");
      let tokenDecimals = e.target.getAttribute("data-decimals");
      let tokenImage = e.target.getAttribute("data-image");
      let chainId = e.target.getAttribute("data-chain-id");
      if(ethereum.networkVersion === chainId){
        try {
          // wasAdded is a boolean. Like any RPC method, an error may be thrown.
          const wasAdded = ethereum.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address: tokenAddress, // The address that the token is at.
                symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                decimals: tokenDecimals, // The number of decimals in the token
                image: tokenImage, // A string url of the token logo
              },
            },
          });

          if (wasAdded) {
            this._showToast("Added to Metamask!");
          } else {
          }
        } catch (error) {
          console.log(error);
        }
      }else{
        this._showToast("This token does not appear to be supported. Please check your Metamask network.");
      }
    } catch (e){
    }
  }

  switchBackCopyIcon(e) {
    e.preventDefault();
    let address = e.target.getAttribute("data-address");
    setTimeout(() => {
      this._switchToCopyIcon(e);
    }, 1000);
  }

  _copyToClipboard(text) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  _showToast(message) {
    window.dispatchEvent(
      new CustomEvent(EventToast, {
        detail: {
          notice: message
        }
      })
    );
  }

  _switchToCopyIcon(e) {
    e.target.classList.remove("fa-check-circle", "text-primary");
    e.target.classList.add("fa-copy");
  }

  _switchToCheckIcon(e) {
    e.target.classList.remove("fa-copy");
    e.target.classList.add("fa-check-circle", "text-primary");
  }
}
