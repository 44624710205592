import { Controller } from "stimulus";
import { EventPricePercentageChanged, EventCurrencyChanged } from "../events";
import { getActiveCurrency } from "../util/currency";

// This controller is responsible for executing price percentage changes from websocket updates or user actions.
// There is only one instance of this controller per page for performance reasons.
export default class extends Controller {
  static targets = ["percent"];

  connect() {
    window.addEventListener(EventPricePercentageChanged, this._updatePercentage.bind(this));
    window.addEventListener(EventCurrencyChanged, this._updatePercentage.bind(this));
  }

  _updatePercentage(e) {
    this.percentTargets.forEach(percentTarget => {
      // If event.detail missing data, help ourselves with json in attribute
      const priceChangePercentageJSON =
        (e.detail && e.detail.priceChangePercentage24H) ||
        JSON.parse(percentTarget.getAttribute("data-json"));
      // If coin id is provided in event detail, we only want to update if coin id matches
      const coinId = e.detail && e.detail.coinId;

      // We only want to live update 24h values.
      if (
        coinId == null ||
        (+percentTarget.getAttribute("data-coin-id") === +coinId &&
          percentTarget.getAttribute("data-24h") === "true")
      ) {
        // Store the price change JSON object into the attribute for currency switching use.
        percentTarget.setAttribute("data-json", JSON.stringify(priceChangePercentageJSON));

        // Coin match. Proceed with update.
        const currencyCode = (e.detail && e.detail.currencyCode) || getActiveCurrency();
        const percentChange = priceChangePercentageJSON[currencyCode.toLowerCase()];

        if (percentChange == null) {
          return;
        }
        percentTarget.textContent = `${percentChange.toFixed(1)}%`;

        const upClass = percentTarget.getAttribute("data-up-class");
        const downClass = percentTarget.getAttribute("data-down-class");
        if (percentChange > 0) {
          percentTarget.classList.add(upClass);
          percentTarget.classList.remove(downClass);
        } else {
          percentTarget.classList.add(downClass);
          percentTarget.classList.remove(upClass);
        }

        const percentIconTarget = percentTarget.getElementsByTagName("i")[0];
        if (percentIconTarget) {
          const iconUpClass = percentIconTarget.getAttribute("data-icon-up-class");
          const iconDownClass = percentIconTarget.getAttribute("data-icon-down-class");

          if (percentChange > 0) {
            percentIconTarget.classList.add(iconUpClass);
            percentIconTarget.classList.remove(iconDownClass);
          } else {
            percentIconTarget.classList.add(iconDownClass);
            percentIconTarget.classList.remove(iconUpClass);
          }
        }
      }
    });
  }
}
