import { Controller } from "stimulus";
import { loadScript } from "../util/load_script";
import { EventPriceChartReload, EventCurrencyChanged, EventPriceChanged } from "../events";
import { getActiveCurrency, formatCurrency } from "../util/currency";
import { stockChartOptions, HIGHCHARTS_DATE_FORMAT } from "../config";
import { getCookie } from "../util";
import CoinGeckoThumbnailImage from "images/coingecko-greyscale.png";

// Action type
const ACTION_TYPE = {
  DURATION: "duration",
  CHART_MODE: "chart-mode",
  TYPE_PRICE: "type-price",
  TYPE_MARKET_CAP: "type-market-cap",
  TYPE_TRADING_VIEW: "type-trading-view"
};

// Price vs market cap
const CHART_DATA_TYPE = {
  PRICE: "price",
  MARKET_CAP: "market-cap"
};

// Whether or not we display markers
const CHART_MODE = {
  LINE_WITH_CIRCLE: "line-with-circle",
  LINE: "line",
  CANDLESTICK: "candlestick"
};

// Dummy URL for trading view chart
const TRADING_VIEW_URL = "TradingViewURL";

// Trusted markets
const TRUSTED_MARKETS_MAP = {
  binance: true,
  bitfinex: true,
  bitstamp: true,
  bittrex: true,
  hitbtc: true,
  kraken: true,
  poloniex: true,
  bithumb: true,
  bitflyer: true,
  ftx: true,
};

let Highcharts;
let priceChartLiveUpdateIsListening = true;

export default class extends Controller {
  static targets = [
    "chart",
    "spinner",
    "toolbar",
    "filterChart",
    "chartModeButton",
    "chartModeButtonGroup",
    "tvChart",
    "checkboxes",
    "mainCheckbox",
    "btcCheckbox",
    "ethCheckbox",
    "openInterestCheckbox",
    "priceChartCheckbox",
    "ohlcSorry",
    "chartMode",
    "marketCapButton",
    "geckoterminalLiveChart",
  ];

  connect(){
    // Rebase coin page swithces tab causes intersectionobserver to not work well
    if(this.chartTarget.getAttribute("data-is-rebase") == "false"){
      this.loadedChart = false;
      const observer = new IntersectionObserver( (changes)=> {
        for (const change of changes) {
          if (this.loadedChart === true){
            return
          }
          if (change.isIntersecting === false) {
            return
          }
          this.loadedChart = true
          this.initChart();
        }
      });
      let priceCharts = document.querySelectorAll('.price-chart')
      for(let i = 0; i < priceCharts.length; i++){
        observer.observe(priceCharts[i], {
          delay: 100
        });
      }
    } else {
      this.initChart();
    }
    this.checkGeckoTerminalLiquidity();
  }

  initChart() {
    $(this.spinnerTarget).show();

    // COMPONENT PROPERTIES
    this.chartDataType =
      this.chartTarget.getAttribute("data-chart-data-type") || CHART_DATA_TYPE.PRICE;
    this.chartDataReferenceUrl = this.chartTarget.getAttribute("data-initial-url");
    this.ohlcDataReferenceUrl = this.chartTarget.getAttribute("data-ohlc-url");
    this.customChartDataReferenceUrl = this.chartTarget.getAttribute("data-custom-chart-url");
    this.navMaxChartUrl = this.chartTarget.getAttribute("data-nav-max-chart-url");
    this.durationKey = "24_hours";

    this.chartMode =
      this.chartTarget.getAttribute("data-chart-mode") || CHART_MODE.LINE;
    this.isPortfolio = JSON.parse(this.chartTarget.getAttribute("data-is-portfolio"));

    this.secondaryChartState = {
      "main": true
    }

    if (this.hasOpenInterestCheckboxTarget) {
      if (getCookie("derivatives_price_chart_open_interest") == "false"){
        this.showOpenInterestState = false;
        this.openInterestCheckboxTarget.checked = false;
      } else {
        this.showOpenInterestState = true;
        this.openInterestCheckboxTarget.checked = true;
      }
    }

    if (this.hasPriceChartCheckboxTarget) {
      if (getCookie("derivatives_price_chart_price") == "false") {
        this.showPriceChartState = false;
        this.priceChartCheckboxTarget.checked = false;
      } else {
        this.showPriceChartState = true;
        this.priceChartCheckboxTarget.checked = true;
      }
    }

    // On browser navigate back these may come checked already.
    if (this.hasBtcCheckboxTarget && this.hasEthCheckboxTarget) {
      // set eth/btc status based on cookies
      if (getCookie("price_chart_currency_btc") == "true"){
        this.btcCheckboxTarget.checked = true;
      }
      if (getCookie("price_chart_currency_eth") == "true"){
        this.ethCheckboxTarget.checked = true;
      }
      // set chart's state when page load
      this.secondaryChartState["btc"] = this.btcCheckboxTarget.checked
      this.secondaryChartState["eth"] = this.ethCheckboxTarget.checked
    }

    Promise.all([
      import("highcharts/highstock"),
      import("highcharts/modules/boost"),
      import("highcharts/modules/exporting"),
      import("highcharts/modules/offline-exporting"),
    ]).then(res => {
      Highcharts = res[0];
      const Exporting = res[1].default;
      const OfflineExporting = res[2].default;

      Exporting(Highcharts);
      OfflineExporting(Highcharts);

      Highcharts.setOptions({
        lang:{
          rangeSelectorZoom: ''
        },
        plotOptions: {
          series: {
            animation: false
          }
        }
      });

      this.renderChart(this.chartDataReferenceUrl, this.chartMode);

      const chartButton = this.toolbarTarget.querySelector(`[data-action-detail='${this._getChartParameter()}']`);

      if (chartButton !== null) {
        chartButton.click();
      }

      if (this.chartTarget.getAttribute("data-is-rebase").toString() === "true") {
        this.marketCapButtonTarget.click();
      }

      window.addEventListener(EventCurrencyChanged, e => this._forceRerender(e));
      window.addEventListener(EventPriceChartReload, e => {
        if (e.detail && e.detail.range) {
          const $rangeButton = $(this.toolbarTarget).find(`.graph-stats-btn-${e.detail.range}`);
          $rangeButton.addClass("active");
          $rangeButton.siblings().removeClass("active");
          this.chartDataReferenceUrl = $rangeButton.data("graph-stats-url");
        }

        this._forceRerender(e);
      });

      //window.addEventListener(EventPriceChanged, e => this._chartLiveUpdate(e));
    });
  }

  get isSecondaryChartOn() {
    return this.secondaryChartState.btc || this.secondaryChartState.eth;
  }

  _forceRerender(e) {
    this.renderChart(
      this.chartMode === CHART_MODE.CANDLESTICK ? this.ohlcDataUrl : this.chartDataReferenceUrl,
      this.chartMode,
      Object.assign(
        {
          forceReload: true
        },
        e.detail || {}
      )
    );
  }

  _chartLiveUpdate(e) {
    let currentTimestamp = Math.round(new Date());
    let currentPrice = fx(e.detail.priceBTC)
      .from("btc")
      .to(getActiveCurrency());
    let currentPriceEth = fx(e.detail.priceBTC)
      .from("btc")
      .to("eth");
    let currentCoinId = this.chartTarget.getAttribute("data-coin-id");
    let currentActionDetail = this.chartModeButtonGroupTarget
      .querySelector(".active")
      .getAttribute("data-action-detail");
    let currentActionType;

    if (this.isPortfolio) {
      currentActionType = ACTION_TYPE.TYPE_PRICE;
    } else {
      currentActionType = this.chartModeTarget
        .querySelector(".active")
        .getAttribute("data-action-type");
    }

    //fixed eth price in chart to 1 if user in ETH page
    if (currentCoinId === 279) {
      currentPriceEth = 1;
    }

    if (
      currentActionType === ACTION_TYPE.TYPE_PRICE &&
      currentActionDetail != CHART_MODE.CANDLESTICK &&
      currentCoinId == e.detail.coinId &&
      priceChartLiveUpdateIsListening == true
    ) {
      $(this.chartTarget)
        .highcharts()
        .series[0].addPoint([currentTimestamp, currentPrice]);

      // Fix volume to -1 since websocket do not push this info
      // To exclude it in tooltip below
      $(this.chartTarget)
        .highcharts()
        .series[1].addPoint([currentTimestamp, -1]);

      $(this.chartTarget)
        .highcharts()
        .series[2].addPoint([currentTimestamp, e.detail.priceBTC]);

      $(this.chartTarget)
        .highcharts()
        .series[3].addPoint([currentTimestamp, currentPriceEth]);
    }
  }

  _getChartDataUrl(vsCurrency) {
    return this.chartDataReferenceUrl.replace(
      this.chartTarget.getAttribute("data-vs-currency"),
      vsCurrency
    );
  }

  seriesChartToggle(e) {
    const vsCurrency = e.currentTarget.value;
    const activate = e.currentTarget.checked;
    this.secondaryChartState[vsCurrency] = activate;
    document.cookie = `price_chart_currency_${vsCurrency}=${this.btcCheckboxTarget.checked}`;
    this.renderChart(this.chartDataReferenceUrl, this.chartMode, { forceReload: true });
  }

  openInterestToggle(e) {
    const activate = this.openInterestCheckboxTarget.checked;
    document.cookie = `derivatives_price_chart_open_interest=${activate}`;
    this.showOpenInterestState = activate;

    this.renderChart(this.chartDataReferenceUrl, this.chartMode, { forceReload: true });
  }

  priceChartToggle(e) {
    const activate = this.priceChartCheckboxTarget.checked;
    document.cookie = `derivatives_price_chart_price=${activate}`;
    this.showPriceChartState = activate;

    this.renderChart(this.chartDataReferenceUrl, this.chartMode, { forceReload: true });
  }

  renderChart(chartUrl, chartMode, options = {}) {
    // We try to remember previous values and don't update if nothing changed.
    if (chartUrl === TRADING_VIEW_URL) {
      this._prevChartUrl = chartUrl;

      this._showPriceChartTools({
        chart: false,
        chartMode: false,
        checkboxes: false,
        toolbar: false
      });
      this._showSpinner(true);
      this.renderProChart(options.forceReload).then(() => {
        this._showSpinner(false);
      });
      return;
    }
    if (
      !options.forceReload &&
      chartUrl === this._prevChartUrl &&
      chartMode === this._prevChartMode
    ) {
      this._showPriceChartTools({
        tvChart: false
      });
      this._showSpinner(false);
      return;
    }
    this._prevChartMode = chartMode;
    this._prevChartUrl = chartUrl;
    if (options.unit) {
      this._prevChartUnit = options.unit;
    }
    options.unit = options.unit || this._prevChartUnit;
    if (this.showOpenInterestState) options.showOpenInterestState = true;
    
    if (chartMode === CHART_MODE.CANDLESTICK) {
      this._showPriceChartTools({
        checkboxes: false,
        tvChart: false
      });
      this._renderCandlestickChart(chartUrl, options).then(() => {
        this._showSpinner(false);
      });
    } else {
      if (this.hasOhlcSorryTarget) {
        this.ohlcSorryTarget.style.display = "none";
      }

      this._showPriceChartTools({
        tvChart: false
      });
      this._renderLineChart(options).then(() => {
        this._showSpinner(false);
      });
    }
  }

  _renderLineChart(options = {}) {
    // Get the latest chart URL with the latest currency to be used
    const activeCurrencyCode = getActiveCurrency();

    const chartDataPromises = [];
    chartDataPromises.push(this._getChartData(this._getChartDataUrl(activeCurrencyCode)));
    chartDataPromises.push(this._getChartData(this.navMaxChartUrl));
    if (this.secondaryChartState["btc"]) {
      chartDataPromises.push(this._getChartData(this._getChartDataUrl("btc"), "btc"));
    } else {
      chartDataPromises.push(Promise.resolve());
    }
    if (this.secondaryChartState["eth"]) {
      chartDataPromises.push(this._getChartData(this._getChartDataUrl("eth"), "eth"));
    } else {
      chartDataPromises.push(Promise.resolve());
    }

    return Promise.all(chartDataPromises).then(
      ([activeCurrencyCodeData, maxData, btcData, ethData]) => {
        const pricesData = activeCurrencyCodeData.data.stats;
        const totalVolumesData = activeCurrencyCodeData.data.total_volumes;

        const secondaryDatas = [];
        const secondarySeries = [];
        const secondaryAxes = [];
        const primarySeries = [];
        const primaryAxes = [{
            // main yaxis (price)
            className: `yaxis-primary`,
            showLastLabel: true,
            opposite: false,
            labels: {
              align: "left",
              x: 0,
              formatter() {
                return formatCurrency(this.value, activeCurrencyCode);
              }
            },
            height: "80%"
          },
          {
            // yaxis (volume)
            visible: false,
            height: "20%",
            top: "80%",
          }
        ];

        if (btcData) secondaryDatas.push(btcData);
        if (ethData) secondaryDatas.push(ethData);
        if (this.showOpenInterestState) {
          const formattedOpenInterestData = this._formatOpenInterestData(activeCurrencyCodeData.data.open_interests);
          secondaryDatas.push(formattedOpenInterestData);
        }
        if (this.showPriceChartState || !this.hasPriceChartCheckboxTarget) {
          // Main currency series
          if(this.secondaryChartState["main"] === true){
            primarySeries.push({
              colorIndex: "primary",
              boostThreshold: 500,
              name: "Price",
              type: "line",
              data: pricesData,
              marker: {
                enabled: !this.isPortfolio && this.chartMode === CHART_MODE.LINE_WITH_CIRCLE,
                radius: this.isSecondaryChartOn ? 0 : 1
              },
              lineWidth: this.isSecondaryChartOn ? 1 : 2,
              states: {
                hover: {
                  lineWidth: this.isSecondaryChartOn ? 1 : 2
                }
              },
              dataGrouping: {
                enabled: false
              },
              yAxis: 0,
            })
          }

          // Volume
          primarySeries.push({
            colorIndex: "primary-volume",
            boostThreshold: 500,
            name: "Volume",
            type: "column",
            data: totalVolumesData,
            yAxis: 1,
            maxPointWidth: 250,
            dataGrouping: {
              enabled: false
            },
          })
        }

        // Optional currencies BTC, ETH, dynamically added based on ticked check boxes
        for (let i = 0; i < secondaryDatas.length; i++) {
          const chartData = secondaryDatas[i];
          const cssChartKey = chartData.key.replace(" ", "-");

          secondarySeries.push({
            colorIndex: cssChartKey,
            boostThreshold: 500,
            name: chartData.key.toUpperCase(),
            type: "line",
            data: chartData.data.stats,
            yAxis: i + 2,
            marker: {
              enabled: !this.isPortfolio && this.chartMode === CHART_MODE.LINE_WITH_CIRCLE,
              radius: 1,
            },
            maxPointWidth: 250,
            dataGrouping: {
              enabled: false
            },
            lineWidth: 2,
            states: {
              hover: {
                lineWidth: 2
              }
            },
            yAxis: 2 + i, // Series definition number after price and volume (2)
          });
          secondaryAxes.push({
            boostThreshold: 500,
            className: `yaxis-${cssChartKey}`,
            title: {
              text: chartData.key.toUpperCase(),
            },
            labels: {
              format: `{value} ${chartData.key.toUpperCase()}`,
              formatter() {
                return formatCurrency(this.value, options.showOpenInterestState ? "USD" : chartData.key);
              }
            },
            height: "70%",
            showLastLabel: true,
            // opposite: false,
          });
        }

        // line
        const chart = Highcharts.StockChart(
          this.chartTarget.id,
          Object.assign(
            {
              subtitle: {
                text: `<span class=''></span>`,
                align: "left",
                floating: true,
                margin: 0,
                y: 35,
                useHTML: true,
              },
              tooltip: {
                formatter() {
                  let formatter = `<b>${chart.time.dateFormat(
                    HIGHCHARTS_DATE_FORMAT,
                    this.x
                  )}</b><br/>`;
                  // Secondary currencies
                  for (let i = 0; i < this.points.length; i++) {
                    if (this.points[i]) {
                      formatter += "<br/>";
                      // Primary Volume
                      if (this.points[i].colorIndex == "primary-volume" && this.points[i].y != -1) {
                        formatter +=
                          "<b>Vol: </b>" + formatCurrency(this.points[i].y, activeCurrencyCode);
                      } else {
                      // Price - Primary or Secondary
                        if (options.unit == "market-cap") {
                          formatter += '<b>Market Cap: </b>';
                        } else if (options.showOpenInterestState)  {
                          formatter += '<b>Open Interest: </b>';
                        } else {
                          formatter += '<b>Price: </b>';
                        }

                        let currency = null;
                        if (this.points[i].colorIndex == "primary") {
                          currency = activeCurrencyCode
                        } else {
                          currency = options.showOpenInterestState ? "USD" : this.points[i].series.name;
                        }
                        formatter += formatCurrency(this.points[i].y, currency);
                      }
                    }
                  }
                  return formatter;
                }
              },
              responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        subtitle: {
                            y: 10,
                        }
                    }
                }]
              },
              navigator:
                maxData != null
                  ? {
                      adaptToUpdatedData: false,
                      series: {
                        data: maxData.data.stats
                      },
                      height: this.isPortfolio ? 20 : 40
                    }
                  : {},
              xAxis: {
                events: {
                  afterSetExtremes: e => {
                    if (e.trigger == "navigator" || e.trigger == "rangeSelectorInput") {
                      priceChartLiveUpdateIsListening = false;
                      var customURL = new URL(this.customChartDataReferenceUrl);
                      var fromTs = Math.round(e.min / 1000);
                      var toTs = Math.round(e.max / 1000);

                      // Range is less than 24 hours, make it 48 hours
                      if (toTs - fromTs <= 86400 * 2) {
                        fromTs = fromTs - 86400;
                        toTs = toTs + 86400;
                      }

                      customURL.searchParams.set("from", fromTs);
                      customURL.searchParams.set("to", toTs);
                      this.chartDataReferenceUrl = customURL.href;
                      this.renderChart(this.chartDataReferenceUrl, this.chartMode);
                    }
                  }
                },
                minRange: 3600 * 1000 // one hour
              },
              yAxis: [].concat(primaryAxes).concat(secondaryAxes),
              series: [].concat(primarySeries).concat(secondarySeries)
            },
            stockChartOptions(this.chartTarget.id)
          )
        );

        if (this.data.get('watermark') == "true") {
          // Watermark positioning may be tricky when applied to other charts
          // Currently positioned well for coin price chart
          chart.renderer.image(
            CoinGeckoThumbnailImage,
            chart.chartWidth - 115,
            chart.chartHeight - 220,
            100,
            28,
          ).addClass("dark:tw-opacity-25").add();
        }
      }
    );
  }

  _renderCandlestickChart(chartUrl = "", options = {}) {
    const activeCurrencyCode = getActiveCurrency();

    const chartDataPromises = [];
    chartDataPromises.push(this._getChartData(this.ohlcDataUrl));

    return Promise.all(chartDataPromises).then(([ohlcData]) => {
      if (this.hasOhlcSorryTarget) {
        if (!ohlcData || !ohlcData.data || ohlcData.data.ohlc.length === 0) {
          this.ohlcSorryTarget.style.display = "";
        } else {
          this.ohlcSorryTarget.style.display = "none";
        }
      }

      // candle
      const chart = Highcharts.StockChart(
        this.chartTarget.id,
        Object.assign(
          {
            subtitle: {
              text: `<span class='text-secondary'>${this.data.get('title') || ""}</span>`,
              align: "left",
              floating: true,
              margin: 0,
              y: 35,
              useHTML: true,
            },
            yAxis: [
              {
                showLastLabel: true,
                opposite: false,
                labels: {
                  style: {
                    color: "#56b4e9"
                  },
                  align: "left",
                  x: 0,
                  formatter() {
                    return formatCurrency(this.value, activeCurrencyCode);
                  }
                }
              }
            ],
            navigator: {
              enabled: false
            },
            series: [
              {
                type: "candlestick",
                name: "Coin OHLC",
                data: ohlcData.data.ohlc,
                groupPadding: 0.0,
              }
            ],
            tooltip: {
              formatter() {
                let formatter = `<b>${chart.time.dateFormat(
                  HIGHCHARTS_DATE_FORMAT,
                  this.x
                )}</b><br/>`;
                formatter += `<b>O: </b>${formatCurrency(
                  this.points[0].point.open,
                  activeCurrencyCode
                )}<br>`;
                formatter += `<b>H: </b>${formatCurrency(
                  this.points[0].point.high,
                  activeCurrencyCode
                )}<br>`;
                formatter += `<b>L: </b>${formatCurrency(
                  this.points[0].point.low,
                  activeCurrencyCode
                )}<br>`;
                formatter += `<b>C: </b>${formatCurrency(
                  this.points[0].point.close,
                  activeCurrencyCode
                )}<br>`;
                return formatter;
              }
            },
            responsive: {
              rules: [{
                  condition: {
                      maxWidth: 500
                  },
                  chartOptions: {
                      subtitle: {
                          y: 10,
                      }
                  }
              }]
            },
          },
          stockChartOptions(this.chartTarget.id)
        )
      );

      if(this.data.get('watermark') == "true"){
        // Watermark positioning may be tricky when applied to other charts
        // Currently positioned well for coin price chart
        chart.renderer.image(CoinGeckoThumbnailImage, chart.chartWidth - 115,chart.chartHeight - 80,100,28).add();
      }
    });
  }

  updateChart(e) {
    this._showSpinner(true);
    priceChartLiveUpdateIsListening = true;
    const buttonEl = e.currentTarget;
    const actionType = buttonEl.getAttribute("data-action-type");
    const actionDetail = buttonEl.getAttribute("data-action-detail");
    const assignActiveClass = buttonEl.getAttribute("data-assign-active-class");

    // Clear active on siblings
    const $buttonEl = $(buttonEl);

    $buttonEl.siblings().removeClass("active");
    if(assignActiveClass != "false"){
      setTimeout(() => {
        // not sure why setTimeout is needed, otherwise won't catch
        $buttonEl.addClass("active");
      });
    }

    switch (actionType) {
      case ACTION_TYPE.CHART_MODE: {
        this.chartMode = actionDetail;

        if (this.chartMode === CHART_MODE.CANDLESTICK) {
          this.renderChart(this.ohlcDataUrl, this.chartMode);
        } else {
          this.renderChart(this.chartDataReferenceUrl, this.chartMode);
        }
        break;
      }
      case ACTION_TYPE.TYPE_MARKET_CAP: {
        // No OHLC for market cap
        this._showCandlestickButton(false);

        this.customChartDataReferenceUrl = this.chartTarget.getAttribute(
          "data-custom-chart-market-cap-url"
        );
        this.chartDataReferenceUrl = $(this.toolbarTarget)
          .find(".active")
          .data("market-cap-graph-stats-url");
        this.chartDataType = CHART_DATA_TYPE.MARKET_CAP;

        this.renderChart(this.chartDataReferenceUrl, this.chartMode, { unit: "market-cap" });
        break;
      }
      case ACTION_TYPE.TYPE_PRICE: {
        this._showCandlestickButton(true);

        this.customChartDataReferenceUrl = this.chartTarget.getAttribute("data-custom-chart-url");
        this.chartDataReferenceUrl = $(this.toolbarTarget)
          .find(".active")
          .data("graph-stats-url");
        this.chartDataType = CHART_DATA_TYPE.PRICE;
        this.renderChart(this.chartDataReferenceUrl, this.chartMode, { unit: "price" });
        break;
      }
      case ACTION_TYPE.TYPE_TRADING_VIEW: {
        this.chartDataReferenceUrl = TRADING_VIEW_URL;
        this.renderChart(this.chartDataReferenceUrl, this.chartMode);
        break;
      }
      case ACTION_TYPE.DURATION: {
        let dataUrl = null;
        if (this.chartDataType === CHART_DATA_TYPE.PRICE) {
          dataUrl = buttonEl.getAttribute("data-graph-stats-url");
        } else if (this.chartDataType == CHART_DATA_TYPE.MARKET_CAP) {
          dataUrl = buttonEl.getAttribute("data-market-cap-graph-stats-url");
        }
        this.chartDataReferenceUrl = dataUrl;

        this.durationKey = actionDetail;
        if (this.chartMode === CHART_MODE.CANDLESTICK) {
          this.renderChart(this.ohlcDataUrl, this.chartMode);
        } else {
          this.renderChart(this.chartDataReferenceUrl, this.chartMode);
        }
        break;
      }
    }
  }

  checkGeckoTerminalLiquidity() {
    let token_address = this.geckoterminalLiveChartTarget.getAttribute("data-token")
    let network_id = this.geckoterminalLiveChartTarget.getAttribute("data-network")

    fetch(`/geckoterminal/token_is_valid?token=${token_address}&network=${network_id}`)
    .then(response => response.json())
    .then(res => {
      if(res === true){
        this.geckoterminalLiveChartTarget.classList.remove("tw-hidden")  
      }
    });
  }

  renderProChart(forceReload = false) {
    if (this._tvLoadPromise && !forceReload) {
      return this._tvLoadPromise;
    }

    this._tvLoadPromise = this._tvLoadPromise || loadScript(true).url("https://s3.tradingview.com/tv.js");

    return this._tvLoadPromise.then(() => {
      const coinApiSymbol = this.tvChartTarget.getAttribute("data-coin-api-symbol");

      return fetch(`https://api.coingecko.com/api/v3/coins/${coinApiSymbol}?localization=false`)
        .then(response => response.json())
        .then(res => {
          const currencyCode = getActiveCurrency();

          // Try to pick (1) tickers from trusted exchange (2) ticker with matching target as active currency
          const tickersInSupportedExchanges = [];
          const tickersWithTargetCurrency = [];
          res.tickers.forEach(t => {
            if (TRUSTED_MARKETS_MAP[t.market.name.toLowerCase()]) {
              tickersInSupportedExchanges.push(t);

              // Note: Allow USDT to be considered if its USD
              if (currencyCode.toUpperCase() == "USD") {
                if (t.target === "USDT") {
                  tickersWithTargetCurrency.push(t);
                }
              }
              if (t.target === currencyCode.toUpperCase()) {
                tickersWithTargetCurrency.push(t);
              }
            }
          });

          // Prioritize selecting ticker with target active currency
          // todo(json): Might end up with false positive because TV symbols from exchange is not complete.
          const ticker = tickersWithTargetCurrency[0] || tickersInSupportedExchanges[0];
          if (ticker) {
            new TradingView.widget({
              autosize: true,
              symbol: `${ticker.market.name.toUpperCase()}:${ticker.base}${ticker.target}`,
              interval: "60",
              theme: "Light",
              style: "1",
              locale: "en",
              toolbar_bg: "#f1f3f6",
              enable_publishing: false,
              withdateranges: true,
              hide_side_toolbar: false,
              allow_symbol_change: true,
              container_id: this.tvChartTarget.id
            });
          } else {
            this.tvChartTarget.innerHTML =
              '<div class="text-lg text-center my-2">TradingView widget unavailable.</div>';
          }
        });
    });
  }

  get ohlcDataUrl() {
    return this.ohlcDataReferenceUrl
      .replace("DURATION", this.durationKey)
      .replace("CURRENCY", getActiveCurrency());
  }

  // Returns the chart data in the format: { data: Object, key: String }
  _getChartData(url, key = "chart") {
    if (!url) return Promise.resolve();

    return fetch(url, { credentials: "same-origin" })
      .then(response => response.json())
      .then(json => ({ data: json, key }));
  }

  _formatOpenInterestData(raw_data) {
    return Object.assign(
      {
        data: {
          stats: raw_data
        },
        key: "open interest"
      }
    )
  }

  _showPriceChartTools({
    toolbar = true,
    chart = true,
    chartMode = true,
    tvChart = true,
    checkboxes = true
  } = {}) {
    if (this.hasToolbarTarget) {
      this.toolbarTarget.style.display = toolbar ? "" : "none";
    }
    if (this.hasChartTarget) {
      this.chartTarget.style.display = chart ? "" : "none";
    }
    if (this.hasCheckboxesTarget) {
      this.checkboxesTarget.style.visibility = checkboxes ? "" : "hidden"; // clashing with flex
    }
    if (this.hasChartModeButtonGroupTarget) {
      this.chartModeButtonGroupTarget.style.display = chartMode ? "" : "none";
    }
    if (this.hasTvChartTarget) {
      this.tvChartTarget.style.display = tvChart ? "" : "none";
    }
  }

  _showSpinner(show = true) {
    this.spinnerTarget.style.display = show ? "" : "none";
  }

  _showCandlestickButton(show = true) {
    // Reset to non-candlestick
    if (!show) {
      this.chartMode = CHART_MODE.LINE;
    }

    this.chartModeButtonTargets.forEach(button => {
      button.classList.remove("active");

      if (button.dataset.actionDetail === this.chartMode) {
        button.classList.add("active");
      }

      if (button.dataset.actionDetail === CHART_MODE.CANDLESTICK) {
        this._candlestickModeButton = button;
      }
    });

    // note(jon): Why not just display: none?
    // Because btn-group has CSS rounding effect and if we just hide button, the rounding effect for button is gone.
    if (show) {
      if (!this.chartModeButtonGroupTarget.contains(this._candlestickModeButton)) {
        this.chartModeButtonGroupTarget.appendChild(this._candlestickModeButton);
      }
    } else {
      this._candlestickModeButton.remove();
    }
  }

  _getChartParameter() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("chart");
  }
}
