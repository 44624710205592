import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["chart", "derivativeGraphModal"];

  renderChart(e) {
    const openInterestTitle = e.currentTarget.getAttribute("data-title-open-interest");
    const tradingVolumeTitle = e.currentTarget.getAttribute("data-title-trading-volume");
    const chart_data = JSON.parse(e.currentTarget.getAttribute("data-chart-data"));
    const open_interest_categories = chart_data["exchanges_open_interest"].map(x => x["name"]);
    const volumes_categories = chart_data["exchanges_volumes"].map(x => x["name"]);
    
    Promise.all([import("highcharts")]).then(res => {
      const Highcharts = res[0];
      Highcharts.chart("derivatives-open-interest-chart", {
        chart: {
          type: "bar"
        },
        title: {
          text: openInterestTitle
        },
        xAxis: {
          categories: open_interest_categories,
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          minRange: 0.5,
          title: {
            text: null
          },
          labels: {
            overflow: "justify"
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: "{point.options.labelText}"
            }
          }
        },
        legend: {
          layout: "vertical",
          align: "right",
          x: -10,
          y: -40,
          floating: true,
          borderWidth: 1,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
          shadow: true
        },
        credits: {
          text: "CoinGecko.com",
          href: "javascript:;",
          position: {
            align: "right",
            verticalAlign: "top",
            y: 40
          },
          style: {
            cursor: "default"
          }
        },
        series: [
          {
            name: "Open Interest",
            data: chart_data["exchanges_open_interest"],
            tooltip: {
              valuePrefix: "$"
            },
            color: "#428bca"
          },
        ]
      });

      Highcharts.chart("derivatives-volume-chart", {
        chart: {
          type: "bar"
        },
        title: {
          text: tradingVolumeTitle
        },
        xAxis: {
          categories: volumes_categories,
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          minRange: 0.5,
          title: {
            text: null
          },
          labels: {
            overflow: "justify"
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: "{point.options.labelText}"
            }
          }
        },
        legend: {
          layout: "vertical",
          align: "right",
          x: -10,
          y: -40,
          floating: true,
          borderWidth: 1,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
          shadow: true
        },
        credits: {
          text: "CoinGecko.com",
          href: "javascript:;",
          position: {
            align: "right",
            verticalAlign: "top",
            y: 40
          },
          style: {
            cursor: "default"
          }
        },
        series: [
          {
            name: "Volume",
            data: chart_data["exchanges_volumes"],
            tooltip: {
              valuePrefix: "$"
            },
            color: "#4a4a4a"
          }
        ]
      });
    });

    $('#derivativesModalGraph').modal()
  }
}
