import { Controller } from "stimulus";
import { EventPriceChanged, EventCurrencyChanged } from "../events";
import { getActiveCurrency, formatCurrency } from "../util/currency";
import Cookies from "js-cookie";
import { throttle, sortWithTableSort } from "../util";

export default class extends Controller {
  static targets = [
    "holdingChangeValue",
    "holdingChangePercentage",
    "profitAndLossValue",
    "profitAndLossChangePercentage",
    "loading",
    "totalHoldingsValue",
    "portfolio",
    "rowLoading",
    "portfolioCoinRow",
    "table"
  ];

  connect() {
    this._showSpinner(true);

    const lazyLoad = () => {
      if (
        this.portfolioTarget.getBoundingClientRect().top <= window.innerHeight &&
        this.portfolioTarget.getBoundingClientRect().bottom >= 0 &&
        this._holdingsValueEmpty()
      ) {
        this._fetchOverview(getActiveCurrency());
      }
    };

    // run method here to let browser get the first portfolio to load from viewport
    lazyLoad();

    // add throttle here to prevent scroll event recurring
    document.addEventListener("scroll", throttle(lazyLoad, 500));
    window.addEventListener("orientationchange", throttle(lazyLoad, 500));

    window.addEventListener(EventCurrencyChanged, (e) =>
      this._fetchOverview(e.detail.currencyCode)
    );
  }

  _fetchOverview(currency) {
    const overviewPath = this.portfolioTarget.dataset.portfolioOverviewPath;
    const overviewPathWithParams = `${overviewPath}?vs_currency=${currency}`;

    fetch(overviewPathWithParams, { credentials: "same-origin" })
      .then((response) => response.json())
      .then((json) => {
        this._updateOverview(json);
        this._updatePortfolioCoins(json.portfolio_coins);
        this._showSpinner(false);
        sortWithTableSort(this.tableTargets);
      });
  }

  _updateOverview(overview) {
    this.holdingChangeValueTarget.innerText = this._formattedPrice(overview.holdings_change);
    this.totalHoldingsValueTarget.innerText = this._formattedPrice(overview.total_holdings_value);

    this.profitAndLossValueTarget.innerText = this._formattedPrice(overview.profit_loss);

    const holdingChangePercentageTarget = this.holdingChangePercentageTarget,
      holdingChangeUpClass = holdingChangePercentageTarget.getAttribute("data-up-class"),
      holdingChangeDownClass = holdingChangePercentageTarget.getAttribute("data-down-class"),
      profitLossChangeUpClass = this.profitAndLossChangePercentageTarget.getAttribute(
        "data-up-class"
      ),
      profitLossChangeDownClass = this.profitAndLossChangePercentageTarget.getAttribute(
        "data-down-class"
      );

    if (overview.holdings_change_percentage >= 0) {
      holdingChangePercentageTarget.classList.add(holdingChangeUpClass);
      holdingChangePercentageTarget.classList.remove(holdingChangeDownClass);
      holdingChangePercentageTarget.innerText = `(+${overview.holdings_change_percentage}%)`;
    } else {
      holdingChangePercentageTarget.classList.add(holdingChangeDownClass);
      holdingChangePercentageTarget.classList.remove(holdingChangeUpClass);
      holdingChangePercentageTarget.innerText = `(${overview.holdings_change_percentage}%)`;
    }

    if (overview.profit_loss_change_percentage > 0) {
      this.profitAndLossChangePercentageTarget.classList.add(profitLossChangeUpClass);
      this.profitAndLossChangePercentageTarget.classList.remove(profitLossChangeDownClass);
      this.profitAndLossChangePercentageTarget.innerText = `(+${overview.profit_loss_change_percentage}%)`;
    } else if (overview.profit_loss_change_percentage == 0) {
      this.profitAndLossChangePercentageTarget.classList.remove(profitLossChangeUpClass);
      this.profitAndLossChangePercentageTarget.classList.remove(profitLossChangeDownClass);
      this.profitAndLossChangePercentageTarget.innerText = "(-)";
    } else {
      this.profitAndLossChangePercentageTarget.classList.add(profitLossChangeDownClass);
      this.profitAndLossChangePercentageTarget.classList.remove(profitLossChangeUpClass);
      this.profitAndLossChangePercentageTarget.innerText = `(${overview.profit_loss_change_percentage}%)`;
    }

    this.element.dispatchEvent(
      new CustomEvent(EventPriceChanged, {
        bubbles: true,
      })
    );
  }

  _updatePortfolioCoins(portfolioCoins) {
    this.portfolioCoinRowTargets.forEach((portfolioCoinTarget) => {
      const portfolioCoinId = portfolioCoinTarget.getAttribute("data-portfolio-coin-id"),
        portfolioCoin = portfolioCoins.filter(
          (t) => t.portfolio_coin_id.toString() == portfolioCoinId.toString()
        )[0],
        pnlValTarget       = portfolioCoinTarget.querySelector("span[class~='pnl-val']"),
        pnlPercTarget      = portfolioCoinTarget.querySelector("span[class~='pnl-perc']"),
        pnlValTdTarget     = portfolioCoinTarget.querySelector("td[class~='pnl-val']"),
        holdingValTarget   = portfolioCoinTarget.querySelector("span[class~='holding-val']"),
        holdingValTdTarget = portfolioCoinTarget.querySelector("td[class~='holding-val']"),
        pnlVal             = this._formattedPrice(portfolioCoin.profit_loss),
        holdingVal         = this._formattedPrice(portfolioCoin.total_market_value),
        pnlPerc            = portfolioCoin.profit_loss_change;

      holdingValTarget.textContent = `${holdingVal}`;

      // profit loss
      if (portfolioCoin.profit_loss > 0) {
        pnlValTarget.textContent = `+${pnlVal}`;
        pnlPercTarget.textContent = `+${pnlPerc}%`;
        pnlPercTarget.classList.add("text-green", "text-small");
      } else {
        pnlValTarget.textContent = `${pnlVal}`;
        pnlPercTarget.textContent = `${pnlPerc}%`;
        pnlPercTarget.classList.add("text-danger", "text-small");
      }
      // add data-val attribute for table-sorting, used in gecko_table_controller.js
      pnlValTdTarget.dataset.val = pnlVal;
      holdingValTdTarget.dataset.val = holdingVal;
    });
  }

  _formattedPrice(price) {
    return formatCurrency(parseFloat(price), getActiveCurrency());
  }

  _showSpinner(show) {
    if (show) {
      this.loadingTargets.forEach((loadingTarget) => {
        loadingTarget.classList.remove("tw-hidden");
      });
      this.totalHoldingsValueTarget.classList.add("tw-hidden");
      this.holdingChangeValueTarget.classList.add("tw-hidden");
      this.profitAndLossValueTarget.classList.add("tw-hidden");
      this.holdingChangePercentageTarget.classList.add("tw-hidden");
      this.rowLoadingTargets.forEach((loadingTarget) => {
        loadingTarget.innerText = "Loading...";
      });
    } else {
      this.loadingTargets.forEach((loadingTarget) => {
        loadingTarget.classList.add("tw-hidden");
      });
      this.totalHoldingsValueTarget.classList.remove("tw-hidden");
      this.holdingChangeValueTarget.classList.remove("tw-hidden");
      this.profitAndLossValueTarget.classList.remove("tw-hidden");
      this.holdingChangePercentageTarget.classList.remove("tw-hidden");
    }
  }

  _holdingsValueEmpty() {
    return this.totalHoldingsValueTarget.innerHTML === "";
  }
}
