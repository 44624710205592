import { Controller } from "stimulus";
import { getCookie } from "../util";
import { EventTickersMoreLoaded } from "../events";
export default class extends Controller {

  static targets = ["displayUnconvertDataSwitch", "displayMarketCapSwitch",]

  connect() {
    if (getCookie("displayUnconvertData") == "true") {
      this.displayUnconvertDataSwitchTarget.checked = true;
      this.displayUnconvertedData();
    }

    document.addEventListener(EventTickersMoreLoaded, e => {
      this.displayUnconvertedData();
      this.displayMarketCap();
    })
  }

  displayUnconvertedData(){
    let elements = document.getElementsByClassName("unconverted-data");
    if (this.displayUnconvertDataSwitchTarget.checked){
      document.cookie = "displayUnconvertData=true";
      for (let item of elements) {
        item.classList.remove("tw-hidden");
      }
    } else {
      document.cookie = "displayUnconvertData=false";
      for (let item of elements) {
        item.classList.add("tw-hidden");
      }
    }
  }

  displayMarketCap(){
    let elements = document.getElementsByClassName("market-cap-column");
    if (this.displayMarketCapSwitchTarget.checked){
      for (let item of elements) {
        item.classList.remove("tw-hidden");
      }
    } else {
      for (let item of elements) {
        item.classList.add("tw-hidden");
      }
    }
  }
}
