import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "password",
    "confirmPassword",
    "confirmPasswordLabel",
    "resetPasswordButton"
  ];

  checkConfirmPassword(e) {
    if (this.passwordTarget.value != this.confirmPasswordTarget.value) {
      this.confirmPasswordLabelTarget.innerText = e.target.getAttribute("data-warning");
      this.resetPasswordButtonTarget.disabled = true;
    } else {
      this.confirmPasswordLabelTarget.innerText = "";
      this.resetPasswordButtonTarget.disabled = false;
    }
  }
}
