import { Controller } from "stimulus";

export default class extends Controller {
  // TODO: To remove this once we settle for params view for cards
  
  // static targets = ["button", "primaryToggleView", "secondaryToggleView"];
  // connect() {
  //   this._toggleIcon();
  //   this._toggleView();
  // }

  // trigger(event) {
  //   const toggle = parseInt(this.buttonTarget.dataset.toggle);
  //   this._toggleView();
  //   this._toggleIcon();

  //   this.buttonTarget.dataset.toggle = toggle ^ 1;
  // }

  // _toggleView() {
  //   if (this._togglePrimaryView()) {
  //     this.primaryToggleViewTarget.classList.add("tw-hidden");
  //     this.secondaryToggleViewTarget.classList.remove("tw-hidden");
  //   } else {
  //     this.secondaryToggleViewTarget.classList.add("tw-hidden");
  //     this.primaryToggleViewTarget.classList.remove("tw-hidden");
  //   }
  // }

  // _toggleIcon() {
  //   const primaryToggleIcon = this.buttonTarget.dataset.primaryToggleIcon,
  //         secondaryToggleIcon = this.buttonTarget.dataset.secondaryToggleIcon;

  //   if (this._togglePrimaryView()) {
  //     this.buttonTarget.classList.remove(secondaryToggleIcon)
  //     this.buttonTarget.classList.add(primaryToggleIcon);
  //   } else {
  //     this.buttonTarget.classList.remove(primaryToggleIcon);
  //     this.buttonTarget.classList.add(secondaryToggleIcon);
  //   }
  // }

  // _togglePrimaryView() {
  //   return this.buttonTarget.dataset.toggle.toString() === "0";
  // }
}