import DialogController from "./dialog_controller";
import { EventToast, EventCheckUserLogin } from "../events";
import { getCSRFToken } from "../util";
import { portfolioCoinAdded } from "../template/toast_notification";
import { createPortfolioRequest, addPortfolioCoinRequest, removePortfolioCoinRequest } from "../routes/portfolio";
import { confirmationDialog } from "../helpers/dialog";
import { validateInput } from "../helpers/validate";
import { getPortfolioCoins } from "../routes/portfolio";
export default class extends DialogController {
  static targets = ["solidStar", "emptyStar", "title", "tableWrapper", "tabWrapper", "portfolioDropdownContent"];

  connect() {
    const portfolioId = this._fetchPortfolioId(),
          params = {
            portfolio_ids: portfolioId
          };

    getPortfolioCoins(params, "POST", (response) => {
      // validate user's login status
      if (response.status != 200) {
        this.userLoggedIn = false;
        this.solidStarTargets.forEach(star => {
          star.href = "#";
          star.dataset.target = "#signInModal";
          star.dataset.toggle = "modal";
        });
        return false;
      }

      this.userLoggedIn = true;
      return true;
    }, (res) => {
      if (!res) {
        return;
      }

      this.portfolios = res;
      this.solidStarTargets.forEach(star => {
        if (this._hasPortfolio(star.dataset.coinId)) {
          this.markStar(star);
        }
      });

    })

    window.addEventListener(EventCheckUserLogin, e => {
      this.userLoggedIn = e.detail.userLoggedIn;
    });
  }

  markStar(currentStar) {
    currentStar.classList.remove("far");
    currentStar.classList.add("fas", "star-color");
  }

  unmarkStar(currentStar) {
    currentStar.classList.remove("fas", "star-color");
    currentStar.classList.add("far");
  }

  _fetchPortfolioId() {
    if (!this.hasTableWrapperTarget) { return; }

    return this.tableWrapperTarget.dataset.portfolioId;
  }

  _hasPortfolio(coin_id) {
    const parsedCoinId = parseInt(coin_id);
    return (this.portfolios.some((portfolio) => portfolio.coin_ids.indexOf(parsedCoinId) > -1));
  }

  _removePortfolio(portfolioId, coinId) {
    const parsedCoinId = parseInt(coinId),
          parsedPortfolioId = parseInt(portfolioId);

    for (const i in this.portfolios) {
      const portfolio = this.portfolios[i];

      if (portfolio.id == parsedPortfolioId) {
        const coinIndex = portfolio.coin_ids.indexOf(parsedCoinId);
        this.portfolios[i].coin_ids.splice(coinIndex, 1);

        break;
      }
    }
  }

  _updatePortfolio(portfolioId, coinId) {
    const parsedCoinId = parseInt(coinId),
          parsedPortfolioId = parseInt(portfolioId);

    for (const i in this.portfolios) {
      const portfolio = this.portfolios[i];

      if (portfolio.id == parsedPortfolioId) {
        this.portfolios[i].coin_ids = portfolio.coin_ids || [];
        this.portfolios[i].coin_ids.push(parsedCoinId);
        break;
      }
    }
  }

  _coin(coin) {
    const coinAttributes = [
      "coinId", "coinSymbol", "coinImage",
      "priceBtc", "toastSuccessMessage",
      "coinSlug", "source", "toastSuccessHyperlinkText",
    ]

    return coinAttributes.reduce((result, key) => {
      result[key] = coin.dataset[key];
      return result;
    }, {});
  }

  _initPortfolioItem(attrs, portfolio) {
    const coinId = parseInt(attrs.coinId),
          addedToPortfolio = (portfolio.coin_ids.indexOf(coinId) > -1),
          dataAction = addedToPortfolio ? "click->favorites#unfavorite" :
                        "click->favorites#favorite",
          checkStatus = addedToPortfolio ? "d-block" : "tw-hidden",
          unCheckStatus = !addedToPortfolio ? "d-block" : "tw-hidden",
          fontBold = addedToPortfolio ? "font-bold" : "font-normal";

    return ` \
      <div class="row cursor-pointer pt-3 pl-2 ml-0 pb-3 dropdown-item pr-0" \
          data-unfavorite-coin-confirm-modal="${attrs.unfavoriteCoinConfirmModal}" \
          data-unfavorite-coin-confirm-button="${attrs.unfavoriteCoinConfirmButton}" \
          data-toast-success-message="${attrs.toastSuccessMessage}" \
          data-toast-success-hyperlink-text="${attrs.toastSuccessHyperlinkText}" \
          data-portfolio-id="${portfolio.id}" \
          data-portfolio-title="${portfolio.title}" \
          data-coin-id="${coinId}" \
          data-coin-slug="${attrs.coinSlug}" \
          data-coin-image="${attrs.coinImage}" \
          data-coin-symbol="${attrs.coinSymbol}" \
          data-action="${dataAction}" \
          data-price-btc="${attrs.priceBtc}" \
          data-refresh="${attrs.refresh}" \
          data-source="${attrs.source}"> \
        <div class="${checkStatus}"><span class="fas fa-check float-right text-green"></span></div> \
        <div class="${unCheckStatus}"><span class="far fa-plus float-right"></span></div> \
        <div class="col-9 pl-0 text-left text-truncate ${fontBold}">${portfolio.title}</div> \
      </div>`;
  }

  trigger(event) {

    const star = event.currentTarget;
    this._togglePortfolioList(star);
  }

  tabUnfavorite(event) {
    const coinId = parseInt(event.currentTarget.dataset.coinId),
          portfolioId = parseInt(event.currentTarget.dataset.portfolioId),
          starTarget = this.solidStarTargets.filter(
            (solidStarTarget) => parseInt(solidStarTarget.dataset.coinId) === coinId
          )[0],
          coin = this._coin(starTarget),
          unfavoriteCoinConfirmModal = $(`#${starTarget.dataset.unfavoriteCoinConfirmModal}`),
          modal = {
            confirmModal:  unfavoriteCoinConfirmModal,
            confirmButton: unfavoriteCoinConfirmModal.find(`#${starTarget.dataset.unfavoriteCoinConfirmButton}`)
          },
          removable = starTarget.dataset.removable || false; // TODO : refactor

    this._unfavoriteAction(coin, starTarget, modal, portfolioId, removable);
  }

  _toggleFavorite(portfolio, starTarget) {
    const parsedCoinId = parseInt(starTarget.dataset.coinId),
          coin = this._coin(starTarget),
          unfavoriteCoinConfirmModal = $(`#${starTarget.dataset.unfavoriteCoinConfirmModal}`),
          modal = {
            confirmModal:  unfavoriteCoinConfirmModal,
            confirmButton: unfavoriteCoinConfirmModal.find(`#${starTarget.dataset.unfavoriteCoinConfirmButton}`)
          },
          removable = starTarget.dataset.removable || false;
    if (portfolio.coin_ids.includes(parsedCoinId)) {
      this._unfavoriteAction(coin, starTarget, modal, portfolio.id, removable);
    } else {
      this._favoriteAction(coin, starTarget, portfolio.id, portfolio.title);
    }
  }

  _togglePortfolioList(star) {
    const portfolioDropdown = star.previousElementSibling;
    let dropDownContent = `
      <div class="d-lg-none ml-1 row mb-3">
        <button type="button" class="close float-left">
          <span class="text-5xl font-light" style="color: #707070;">&times;</span>
        </button>
      </div>`; // close button

    this.portfolios.forEach((portfolio) => {
      dropDownContent += this._initPortfolioItem(star.dataset, portfolio);
    });

    dropDownContent += `
      <div class="row cursor-pointer border-top pt-3 pl-2 ml-0 pb-2 dropdown-item pr-0" \
        data-unfavorite-confirm-modal="${star.dataset.unfavoriteCoinConfirmModal}" \
        data-unfavorite-confirm-button="${star.dataset.unfavoriteCoinConfirmButton}" \
        data-new-portfolio-confirm-modal="${star.dataset.newPortfolioConfirmModal}" \
        data-new-portfolio-confirm-button="${star.dataset.newPortfolioConfirmButton}" \
        data-toast-success-message="${star.dataset.toastSuccessMessage}" \
        data-toast-success-hyperlink-text="${star.dataset.toastSuccessHyperlinkText}" \
        data-coin-id="${star.dataset.coinId}" \
        data-coin-slug="${star.dataset.coinSlug}" \
        data-coin-image="${star.dataset.coinImage}" \
        data-coin-symbol="${star.dataset.coinSymbol}" \
        data-action="click->favorites#favoriteNewPortfolio" \
        data-price-btc="${star.dataset.priceBtc}" \
        data-refresh="${star.dataset.refresh}" \
        data-source="${star.dataset.source}"> \
        <span class="far fa-plus pl-0 col-1 text-left"></span> \
        <span class="ml-1">${star.dataset.newPortfolioButtonLabel}</span> \
      </div> `

    portfolioDropdown.innerHTML = dropDownContent;
  }

  favoriteNewPortfolio(event) {
    const currentTarget = event.currentTarget, // add to new portfolio button
          newPortfolioConfirmModal = $(`#${currentTarget.dataset.newPortfolioConfirmModal}`),
          newPortfolioConfirmButton = newPortfolioConfirmModal.find(`#${currentTarget.dataset.newPortfolioConfirmButton}`);

    confirmationDialog(
      () => { newPortfolioConfirmModal.modal("show") },
      newPortfolioConfirmButton,
      () => {
        const valid = validateInput(this.titleTarget, () => {
          this.titleTarget.classList.add("border-danger");
          this.titleTarget.parentNode.querySelector(".text-danger").innerText = "cannot be empty";
        })

        if (valid) {
          newPortfolioConfirmModal.modal("hide");
          this.titleTarget.classList.remove("border-danger");
          this.titleTarget.parentNode.querySelector(".text-danger").innerText = "";
          this._favoriteNewPortfolioCallback(currentTarget);
        }
      }
    );
  }

  _favoriteNewPortfolioCallback(target) {
    const portfolioDropdown = target.parentNode,
          coin = this._coin(target),
          starTarget = portfolioDropdown.nextElementSibling,
          params = {
              portfolio: {
                title: this.titleTarget.value
            }
          };
    createPortfolioRequest(params, "POST", (res) => {
      this.portfolios.push(res.portfolio);
      this._favoriteAction(coin, starTarget, res.portfolio.id, res.portfolio.title);
    });
  }

  unfavorite(event) {
    if (!this.userLoggedIn) { return; }

    const portfolioTarget = event.currentTarget,
          coin = this._coin(portfolioTarget),
          portfolioId = portfolioTarget.dataset.portfolioId,
          portfolioDropdown = portfolioTarget.parentNode,
          starTarget = portfolioDropdown.nextElementSibling,
          removable = portfolioTarget.dataset.removable || false,
          unfavoriteConfirmModal = $(`#${portfolioTarget.dataset.unfavoriteCoinConfirmModal}`),
          modal = {
            confirmModal: unfavoriteConfirmModal,
            confirmButton: unfavoriteConfirmModal.find(`#${portfolioTarget.dataset.unfavoriteCoinConfirmButton}`)
          };

    this._unfavoriteAction(coin, starTarget, modal, portfolioId, removable);
  }

  favorite(event) {
    if (!this.userLoggedIn) { return; }

    const portfolioTarget = event.currentTarget,
          portfolioId = portfolioTarget.dataset.portfolioId,
          portfolioTitle = portfolioTarget.dataset.portfolioTitle,
          coin = this._coin(portfolioTarget),
          portfolioDropdown = portfolioTarget.parentNode,
          starTarget = portfolioDropdown.nextElementSibling;

    this._favoriteAction(coin, starTarget, portfolioId, portfolioTitle);
  }

  _favoriteAction(coin, starTarget, portfolioId, portfolioTitle) {
    const params = {
      portfolioId: portfolioId,
      coinId: coin.coinId,
      source: coin.source,
    }
    addPortfolioCoinRequest(params, "POST", (res) => {
      this._updatePortfolio(res.portfolio_id, res.coin_id);
      this.markStar(starTarget);

      this._showTransactionToast(coin, res.portfolio_id, portfolioTitle);
    })
  }

  _unfavoriteAction(coin, starTarget, modal, portfolioId, removable) {
    const unfavoriteConfirmModal = modal.confirmModal,
          unfavoriteConfirmButton = modal.confirmButton;

    this._confirmationDialog(
      () => { unfavoriteConfirmModal.modal("show") },
      unfavoriteConfirmButton,
      () => {
        unfavoriteConfirmModal.modal("hide");
        this._unfavoriteActionCallback(coin, portfolioId, starTarget, removable);

      }
    );
  }

  _showTransactionToast(coin, portfolioId, portfolioTitle) {
    const coinSymbol = coin.coinSymbol.toUpperCase(),
          coinSlug = coin.coinSlug;
    window.dispatchEvent(
      new CustomEvent(EventToast, {
        detail: {
          notice: this._transactionToast(
            coin.coinId,
            coin.coinImage,
            `/en/portfolios/${portfolioId}/${coinSlug}/transactions`,
            coin.priceBtc,
            coinSymbol,
            coin.toastSuccessMessage + ` - <b>${portfolioTitle}</b>`,
            coin.toastSuccessHyperlinkText
          ),
          timeout: 8000,
        }
      })
    );
  }

  _unfavoriteActionCallback(coin, portfolioId, starTarget, removable) {
    const params = {
            coinId: coin.coinId,
            source: coin.source,
            portfolioId: portfolioId,
          };

    removePortfolioCoinRequest(params, (res) => {
      this._removePortfolio(res.portfolio_id, res.coin_id);

      if (!this._hasPortfolio(coin.coinId)) {
        this.unmarkStar(starTarget);
      }

      // only apply for portfolio page. TODO: refactor
      if (removable.toString() === "true") {
        const removableCoinRow = starTarget.parentNode.parentNode,
              removableCoinCard = document.getElementById(`tab-${res.coin_id}`);

        removableCoinRow && removableCoinRow.remove();
        removableCoinCard && removableCoinCard.remove();

        if (this.solidStarTargets.length <= 0) {
          this._renderPortfolioEmpty();
        }
      }

      this._showUnfavoriteToast(coin, res.portfolio_title);
    })
  }

  _showUnfavoriteToast(coin, portfolioTitle) {
    const coinSymbol = coin.coinSymbol.toUpperCase();

    window.dispatchEvent(
      new CustomEvent(EventToast, {
        detail: {
          notice: `Removed ${coinSymbol} from ${portfolioTitle}`
        }
      })
    );
  }

  _transactionToast(coinId, coinImage, transactionPath, priceBtc, coinSymbol, successMessage, hyperlinkText) {
    return portfolioCoinAdded(
      {
        image: coinImage,
        id: coinId,
        transactionPath: transactionPath,
        priceBtc: priceBtc,
        symbol: coinSymbol,
      },
      successMessage,
      hyperlinkText
    )
  }

  _renderPortfolioEmpty() {
    if (!this.hasTableWrapperTarget) { return; }
    if (!this.hasTabWrapperTarget) { return; }
    const noPortfolioCoins = this.tableWrapperTarget.dataset.noPortfolioCoin,
          newCoinStarted = this.tableWrapperTarget.dataset.newCoinStarted,
          image = this.tableWrapperTarget.dataset.image;

    this.tableWrapperTarget.innerHTML = `
      <div class="d-flex flex-column center">
        <div class="mb-3"><img src="${image}" /></div>
        <div>${noPortfolioCoins}</div>
        <div><a href="#" data-toggle="modal" data-target="#addCoinModal">
          ${newCoinStarted}
        </a></div>
      </div>`;

    this.tabWrapperTarget.innerHTML = `
      <div class="d-flex flex-column center mx-auto">
        <div class="mb-3"><img src="${image}" /></div>
        <div>${noPortfolioCoins}</div>
        <div><a href="#" data-toggle="modal" data-target="#addCoinModal">
          ${newCoinStarted}
        </a></div>
      </div>`;
  }

  checkUserLogin(e) {
    if (this.userLoggedIn === false) {
      e.preventDefault();
      e.target.parentNode.href = "#";
      e.target.parentNode.dataset.toggle = "modal";
      e.target.parentNode.dataset.target = "#signInModal";
    }
  }
}
