import { Controller } from "stimulus";
import { debounce, kebabFromCamel } from "../util";
import Choices from "choices.js";

export default class extends Controller {
  static targets = ["widget", "code", "select", "field", "amp"];

  connect() {
    this.name = this.data.get("name");

    this.fieldMap = {};

    // Create debounced version of fieldChanged
    this.fieldChanged = debounce(this._fieldChanged.bind(this), 750);

    // Make the widget the source of truth
    // Set default fields directly from the widget itself.
    this.fieldTargets.forEach(f => {
      const fieldName = f.dataset.fieldName;
      const val = this.widgetTarget.getAttribute(kebabFromCamel(fieldName));

      if (val != null || (val && val.length > 0)) {
        this.fieldMap[fieldName] = val;

        if (f.nodeName === "SELECT" && f.multiple) {
          // Multi-select value
          // Set option.selected one by one.
          const vals = val.split(",");
          const valsMap = {};
          vals.forEach(val => {
            valsMap[val.trim()] = true;
          });
          Array.from(f.options).forEach(option => {
            option.selected = valsMap[option.value] != null;
          });
        } else {
          f.value = val;
        }
      }
    });

    
    this.selectTargets.forEach(selectTarget => {
      new Choices(selectTarget, {
        removeItemButton: true
      });
    });

    this.updateCode();
  }

  _fieldChanged(e) {
    const fieldName = e.target.dataset.fieldName;

    // Multi-select field, retrieve selected options from select.options
    if (e.target.nodeName === "SELECT" && e.target.multiple) {
      const vals = Array.from(e.target.options).reduce((acc, option) => {
        if (option.selected) {
          acc.push(option.value);
        }
        return acc;
      }, []);

      this.fieldMap[fieldName] = vals.join(",");
    } else {
      this.fieldMap[fieldName] = e.target.value;
    }
    this.widgetTarget.setAttribute(kebabFromCamel(fieldName), this.fieldMap[fieldName]);

    this._runConditionals(e);
    this.updateCode();
  }

  toggleAmp() {
    this.updateCode()
  }

  _runConditionals(e) {
    this.fieldTargets.forEach(f => {
      const showIf = f.dataset.showIf;
      if (showIf) {
        const parts = showIf.split(":");
        const key = parts[0];
        const value = parts[1];

        if (key === e.target.dataset.fieldName) {
          if (e.target.value === value) {
            f.closest(".form-group").style.display = "";

            const fieldName = f.dataset.fieldName;

            // add to fieldmap, update widget
            this.fieldMap[fieldName] = f.value;
            this.widgetTarget.setAttribute(kebabFromCamel(fieldName), f.value);
          } else {
            f.closest(".form-group").style.display = "none";
          }
        }
      }
    });
  }

  updateCode() {
    let attributes = "";
    let divWidth = "";
    let backgroundColor = "";
    let code = null;
    Object.keys(this.fieldMap).forEach(fieldName => {
      attributes += ` ${kebabFromCamel(fieldName)}="${this.fieldMap[fieldName]}"`;
    });

    if(this.hasAmpTarget && this.ampTarget.checked === true){
      if(this.fieldMap['width'] == undefined){
      }else{
        if(this.fieldMap['width'] < 300){
          divWidth = 300;
        }else{
          divWidth = this.fieldMap['width'];
        }
      }

      if(this.fieldMap['backgroundColor'] != undefined){
        backgroundColor = this.fieldMap['backgroundColor']
      }

      code = `<div data-background-color="${backgroundColor}" data-currency="${this.fieldMap['currency']}" data-coin-id="${this.fieldMap['coinId']}" data-locale="${this.fieldMap['locale']}" data-height="${this.fieldMap['height']}" data-width="${divWidth}" class="${this.name}"></div>\n`;
      code += `<script src="https://widgets.coingecko.com/div/${this.name}-div.js"></script>`;
    }else{
      code = `<script src="https://widgets.coingecko.com/${this.name}.js"></script>\n`;
      code += `<${this.name} ${attributes}></${this.name}>`;
    }

    this.codeTarget.textContent = code.trim();
  }
}
