import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const socialTab = document.getElementById("social-tab");
    var securityTab = document.getElementById("security-tab");
    var developerTab = document.getElementById("developer-tab");
    var widgetsTab = document.getElementById("widgets-tab");
    var guidesTab = document.getElementById("guides-tab");
    var newsTab = document.getElementById("news-tab");
    var ratingsTab = document.getElementById("ratings-tab");
    var analysisTab = document.getElementById("analysis-tab");
    var beamTab = document.getElementById("beam-tab");

    [
      socialTab,
      securityTab,
      developerTab,
      developerTab,
      widgetsTab,
      guidesTab,
      newsTab,
      beamTab,
      ratingsTab,
      analysisTab,
    ].forEach(tab => {
      if (tab) {
        var idName = tab.id.split("-")[0]
        tab.addEventListener("click", (event) => {
          fetch(event.target.dataset.url)
            .then(response => {
              return response.text();
            }).then( html => {
              var ele = document.querySelector(`#${idName} .card-block`)
              ele.innerHTML = html;
            });
        });
      }
    });
  }
}
