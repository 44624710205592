import { Controller } from "stimulus";

const drawDeveloperChart = function({ elementId, chartType = "spline", xDateFormat = undefined }) {
  if ($(`#${elementId}`).length > 0) {
    Promise.all([
      import("highcharts")
    ]).then(res => {
      const [Highcharts] = res;
      return Highcharts.chart(`${elementId}`, {
        time: { useUTC: false },
        chart: {
          type: chartType,
          backgroundColor: null,
          panning: false,
          height: 250
        },
        title: {
          text: ""
        },
        tooltip: {
          xDateFormat: xDateFormat
        },
        rangeSelector: {
          selected: 1
        },
        rangeSelector: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        legend: {
          enabled: true
        },
        scrollbar: {
          enabled: false
        },
        plotOptions: {
          spline: {
            connectNulls: true,
            lineWidth: 4,
            states: {
              hover: {
                lineWidth: 5
              }
            },
            marker: {
              enabled: false
            },
            animation: false
          }
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%b %Y",
            year: "%b"
          },
          title: {
            text: "Date"
          }
        },
        yAxis: {
          showLastLabel: true,
          labels: {
            align: "right",
            x: -10
          }
        },
        series: $(`#${elementId}`).data("series"),
        credits: {
          enabled: false
        }
      });
    });
  }
};

export default class extends Controller {
  static targets = ["developerChart", "issuesChart", "commitChart", "additionDeletionChart"];

  connect() {
    this.developerChartTargets.forEach(e => drawDeveloperChart({ elementId: e.id }));
    this.issuesChartTargets.forEach(e => drawDeveloperChart({ elementId: e.id }));
    this.commitChartTargets.forEach(e => {
      var params = {
        elementId: e.id,
        chartType: "column",
        xDateFormat: "Week of %b %d"
      };
      drawDeveloperChart(params);
    });
    this.additionDeletionChartTargets.forEach(e => {
      var params = {
        elementId: e.id,
        chartType: "column",
        xDateFormat: "Week of %b %d"
      };
      drawDeveloperChart(params);
    });

    if ($("#coin_community_chart").length > 0) {
      drawDeveloperChart({ elementId: "coin_community_chart" });
    }
  }
}
