import { Controller } from "stimulus";

const TEXT_MAX_CHARS_COUNT = 500;

export default class extends Controller {
  static targets = ["textAreaCharacterCount"];

  characterCount(e) {
    var textAreaLength = e.currentTarget.value.length;
    var counter = TEXT_MAX_CHARS_COUNT - textAreaLength;
    this.textAreaCharacterCountTarget.textContent = counter;
  }
}
