export function loadScript(preconnect = false) {
  return (
    {
      url: (url) => {
        return new Promise((resolve, reject) => {
          let script = document.createElement("script");

          if (preconnect === true) {
            script.rel = "preconnect"
          }
          script.type = "text/javascript";
          script.src = url;

          script.addEventListener("load", () => resolve(script), false);
          script.addEventListener("error", () => reject(script), false);

          document.body.appendChild(script);
        });
      },
      urls: (urls) => {
        return Promise.all(urls.map(loadScript(preconnect).url));
      }
    }
  );
}
