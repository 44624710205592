import { Controller } from "stimulus";
import { debounce, getCSRFToken } from "../util";
import { EventCheckUserLogin } from "../events";

const INPUT_DEBOUNCE_DURATION_MS = 300;

const categorylistTemplate = (slug, name) => `
  <div class="tw-block tw-px-4 tw-py-2 hover:tw-bg-gray-100 dark:tw-hover-bg-white dark:hover:tw-bg-opacity-10">
    <a href="#" class="tw-text-xs tw-text-black" data-slug="${slug}" data-name="${name}" data-action="custom-tabs#addCategoryItem" data-type="category">${name}</a>
  </div>`;

const customTabButton = `
  <a class="tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300
    tw-whitespace-nowrap tw-py-3 tw-border-b-2 tw-font-medium tw-text-sm dark:tw-text-white"
    data-user-login="true"
    data-target="custom-tabs.customTabsBtn"
    data-action="custom-tabs#openCustomTabsModal"
    href="#">
    <i class="fa fa-plus-circle"></i> Custom Tabs <span class="badge badge-danger tw-ml-1">New</span>
  </a>`;

const customCategoryItemTemplate = (number, path, name, type) => `
  <div class="tw-bg-gray-100 tw-p-2 tw-rounded-md md:tw-rounded-full tw-font-medium tw-text-gray-900 dark:tw-bg-gray-600 dark:tw-bg-opacity-60 tw-mb-1 md:tw-mb-0"
      data-name="${name}"
      data-path="${path}"
      data-type="${type}">
   <span class="tw-py-0.5 tw-px-2 tw-bg-gray-300 tw-rounded-lg tw-mr-2 dark:tw-bg-gray-400 dark-tw-text-white">${number}</span>
   <span class="tw-mr-2 dark:tw-text-white">${name}</span>
   <span class="tw-hidden tw-float-right md:tw-float-none dark:tw-text-white"><i class="fa fa-bars"></i></span>
  </div>`;

const currentLocale = document.getElementsByTagName("body")[0].getAttribute("data-locale");

const defaultTabData = [
  {
    name: "Portfolio",
    path: `/${currentLocale}/portfolio`,
    type: "general",
  },
  {
    name: "Coins",
    path: `/${currentLocale}`,
    type: "general",
  },
  {
    name: "Recently Added",
    path: `/${currentLocale}/coins/recently_added`,
    type: "general",
  },
  {
    name: "Large Movers",
    path: `/${currentLocale}/coins/trending`,
    type: "general",
  },
  {
    name: "Categories",
    path: `/${currentLocale}/categories`,
    type: "general",
  },
];

export default class extends Controller {
  static targets = [
    "categoryList",
    "searchInput",
    "customTabList",
    "customTabsBtn",
    "customTabNav",
  ];

  initialize() {
    this.debouncedFilterCategory = debounce(
      this._fetchCategoryList.bind(this),
      INPUT_DEBOUNCE_DURATION_MS
    );

    const _this = this;

    $("#customTabsCategoryDropdown").on("shown.bs.dropdown", () => {
      _this.searchInputTarget.focus();
    });
  }

  connect() {
    window.addEventListener(EventCheckUserLogin, (e) => {
      if (e.detail.userLoggedIn === true) {
        this.customTabsBtnTarget.dataset.userLogin = true;
        fetch(`/custom_tab/list`, {
          credentials: "same-origin",
        })
          .then((response) => response.json())
          .then((json) => {
            this._loadCustomTabNavItem(json);
            this._loadCustomTabModalItem(json);
          });
      }
    });
  }

  resetTabItem() {
    this.customTabListTarget.innerHTML = "";
    defaultTabData.forEach((item, index) => {
      this.customTabListTarget.innerHTML += customCategoryItemTemplate(
        index + 1,
        item.path,
        item.name,
        item.type
      );
    });

    $("#resetCustomTabItemModal").modal("toggle");
  }

  edit(e) {
    e.preventDefault();
    if (e.target.innerText === e.target.dataset.textEdit) {
      e.target.innerText = e.target.dataset.textCancelEdit;
    } else {
      e.target.innerText = e.target.dataset.textEdit;
    }

    this._changeEditableItemStyle();
  }

  openCustomTabsModal() {
    if (this.customTabsBtnTarget.dataset.userLogin === "true") {
      $("#customTabsModal").modal("show");
    } else {
      $("#signInModal").modal("show");
    }
  }

  addCategoryItem(e) {
    e.preventDefault();

    this.customTabListTarget.innerHTML += customCategoryItemTemplate(
      this.customTabListTarget.children.length + 1,
      `/${currentLocale}/categories/${e.target.dataset.slug}`,
      e.target.dataset.name,
      "category"
    );
  }

  removeCategoryItem(e) {
    const element = e.target.parentElement.parentElement;
    if (element.dataset.type === "category") {
      element.remove();
    }
  }

  triggerResetModal(e) {
    e.preventDefault();
    $("#customTabsModal").modal("hide");
    $("#resetCustomTabItemModal").modal("show");

    $("#resetCustomTabItemModal").on("hidden.bs.modal", (e) => {
      $("#customTabsModal").modal("show");
    });
  }

  submit(e) {
    let array = [];

    Array.from(this.customTabListTarget.children).forEach((item) => {
      if (item.dataset.name !== undefined || item.dataset.path !== undefined) {
        let object = {};
        object.name = item.dataset.name;
        object.path = item.dataset.path;
        object.type = item.dataset.type;
        array.push(object);
      }
    });

    fetch(e.target.dataset.url, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken(),
      },
      method: "POST",
      credentials: "same-origin",
      body: JSON.stringify({
        custom_tab: array,
      }),
    }).then((response) => {
      window.location.reload();
    });
  }

  _fetchCategoryList() {
    fetch(`/${currentLocale}/categories/search_category/${this.searchInputTarget.value}`, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => {
        this.categoryListTarget.innerHTML = "";
        json.forEach((category) => {
          this.categoryListTarget.innerHTML += categorylistTemplate(category.slug, category.name);
        });
      });
  }

  _loadCustomTabNavItem(json) {
    if (json.length !== 0) {
      this.customTabNavTarget.innerHTML = "";
      json.forEach((item) => {
        if (item.name == "Portfolio") {
          this.customTabNavTarget.innerHTML += this._customTabNavItemTemplate(
            item.path,
            item.name,
            '<i type="solid" class="fas star-color mr-1 fa-star"></i>'
          );
        } else {
          this.customTabNavTarget.innerHTML += this._customTabNavItemTemplate(
            item.path,
            item.name,
            ""
          );
        }
      });
      this.customTabNavTarget.innerHTML += customTabButton;
    }
  }

  _loadCustomTabModalItem(json) {
    if (json.length !== 0) {
      this.customTabListTarget.innerHTML = "";
      json.forEach((item, index) => {
        this.customTabListTarget.innerHTML += customCategoryItemTemplate(
          index + 1,
          item.path,
          item.name,
          item.type
        );
      });
    }
  }

  _customTabNavItemTemplate(path, name, icon) {
    let className = "";
    if (
      window.location.pathname === path ||
      (window.location.pathname === `/${currentLocale}` && name === "Coins") ||
      (window.location.pathname === `/${currentLocale}/stablecoins` && name === "Stablecoins") ||
      (window.location.pathname === `/${currentLocale}/defi` &&
        name === "Decentralized Finance (DeFi)") ||
      (window.location.pathname === `/${currentLocale}/nft` &&
        name === "Non-Fungible Tokens (NFT)")
    ) {
      className =
        "tw-border-primary-500 tw-text-gray-900 tw-font-semibold tw-whitespace-nowrap tw-py-3 tw-border-b-2 tw-font-medium tw-text-sm dark:tw-text-white";
    } else {
      className =
        "tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-py-3 tw-border-b-2 tw-font-medium tw-text-sm dark:tw-text-white";
    }

    return `<a class="${className}"
              href="${path}">
              ${icon}
              ${name}
            </a>`;
  }

  _changeEditableItemStyle() {
    Array.from(this.customTabListTarget.children).forEach((item) => {
      if (item.dataset.type === "category") {
        item.classList.toggle("tw-bg-gray-100");
        item.classList.toggle("tw-bg-primary-100");
        item.classList.toggle("dark:tw-bg-gray-600");
        item.lastElementChild.firstElementChild.classList.toggle("fa-bars");
        item.lastElementChild.firstElementChild.classList.toggle("fa-times-circle");
        item.lastElementChild.firstElementChild.classList.toggle("cursor-pointer");
        item.lastElementChild.classList.toggle("tw-hidden");
        if (
          item.lastElementChild.firstElementChild.dataset.action ===
          "click->custom-tabs#removeCategoryItem"
        ) {
          item.lastElementChild.firstElementChild.dataset.action = "";
        } else {
          item.lastElementChild.firstElementChild.dataset.action =
            "click->custom-tabs#removeCategoryItem";
        }
      }
    });
  }
}
