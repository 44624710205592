import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "spotTab",
    "perpetualsTab",
    "futuresTab",
    "spotButton",
    "perpetualsButton",
    "futuresButton"
  ];

  connect() {
    window.bsa_loaded = false;
  }

  openSpotTab(e) {
    e.preventDefault();
    this.spotButtonTarget.classList.add("points-table-tabs-active");
    this.perpetualsButtonTarget.classList.remove("points-table-tabs-active");
    this.futuresButtonTarget.classList.remove("points-table-tabs-active");

    this.spotTabTarget.classList.remove("tw-hidden");
    this.perpetualsTabTarget.classList.add("tw-hidden");
    this.futuresTabTarget.classList.add("tw-hidden");
  }
  openPerpetualsTab(e) {
    e.preventDefault();
    this.spotButtonTarget.classList.remove("points-table-tabs-active");
    this.perpetualsButtonTarget.classList.add("points-table-tabs-active");
    this.futuresButtonTarget.classList.remove("points-table-tabs-active");

    this.spotTabTarget.classList.add("tw-hidden");
    this.perpetualsTabTarget.classList.remove("tw-hidden");
    this.futuresTabTarget.classList.add("tw-hidden");
  }
  openFuturesTab(e) {
    e.preventDefault();
    this.spotButtonTarget.classList.remove("points-table-tabs-active");
    this.perpetualsButtonTarget.classList.remove("points-table-tabs-active");
    this.futuresButtonTarget.classList.add("points-table-tabs-active");

    this.spotTabTarget.classList.add("tw-hidden");
    this.perpetualsTabTarget.classList.add("tw-hidden");
    this.futuresTabTarget.classList.remove("tw-hidden");
  }
}
