import { Controller } from "stimulus";
import { EventPriceLoaded } from "../events";

export default class extends Controller {
  connect() {
    var marketsTab = document.getElementById("navigationTabMarketsChoice");
    var marketsTabLoaded = false;

    if (marketsTab) {
      marketsTab.addEventListener("click", (event) => {
        if(marketsTabLoaded == true){
          return
        }
        marketsTabLoaded = true
        fetch(event.target.dataset.url)
          .then(response => {
            return response.text();
          }).then( html => {
            var ele = document.querySelector("#markets")
            ele.innerHTML = html;

            this.element.dispatchEvent(
              new CustomEvent(EventPriceLoaded, {
                bubbles: true
              })
            );
          });
      });
    }
  }
}
