import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["topAnnouncementHeaderContainer"];

  closeHeader() {
    this.topAnnouncementHeaderContainerTarget.style.display = "none";
    Cookies.set("top_announcement_header_disabled", 1, { expires: 3, path: "/" });
  }
}
