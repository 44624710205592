import { Controller } from "stimulus";
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["form", "select"];

  connect() {
    this.selectTargets.forEach(selectTarget => {
      new Choices(selectTarget, {
        removeItemButton: true,
        classNames: {
          containerOuter: "choices choices-sm"
        }
      });
    });
  }

  submitFilterForm() {
    this.formTarget.submit();
  }
}
