import { Controller } from "stimulus";
import { EventCurrencyChanged } from "../events";
import { setActiveCurrency, getActiveCurrency } from "../util/currency";

export default class extends Controller {
  static targets = ["button", "currencyList", "currencySearchInput"];

  connect() {
    window.addEventListener(EventCurrencyChanged, () => {
      this._updateButton(getActiveCurrency());
    });
    document.addEventListener("currency-selector-loaded", (event) => {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent(EventCurrencyChanged, {
            detail: {
              currencyCode: getActiveCurrency()
            }
          })
        );
      }, 100);
    });
  }

  _updateButton(currencyCode) {
    this.buttonTarget.textContent = currencyCode.toUpperCase();
  }

  _resetDropdownMenuState(){
    // reset dropdown menu state
    var activeCurrency = document.getElementsByClassName("active-currency");
    if(activeCurrency.length !== 0){
      activeCurrency[0].firstElementChild.firstElementChild.classList.add("text-muted");
      activeCurrency[0].classList.remove("active");
      activeCurrency[0].classList.remove("active-currency");
    }
  }

  selectCurrency(e) {
    const currencyCode = e.currentTarget.getAttribute("data-iso-code");
    this._resetDropdownMenuState()
    // set new state
    e.currentTarget.classList.add("active");
    e.currentTarget.classList.add("active-currency");
    e.currentTarget.firstElementChild.firstElementChild.classList.remove("text-muted");

    setActiveCurrency(currencyCode);
    // Dispatch currency changed event
    this.element.dispatchEvent(
      new CustomEvent(EventCurrencyChanged, {
        bubbles: true,
        detail: {
          currencyCode: currencyCode
        }
      })
    );
    e.preventDefault();
  }
}
