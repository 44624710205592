import { Controller } from "stimulus";
import { copyToClipboard } from "../util/copy_to_clipboard";

export default class extends Controller {
  static targets = ["address", "buttonIcon"];

  copyAddress(e) {
    copyToClipboard(this.addressTarget, this.buttonIconTarget);
  }
}
