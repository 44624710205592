import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  connect() {
    this.toggleView();
  }
  toggleView(e) {
    var cookie_notice_accept = Cookies.get("cookie_notice_accept");
    if (cookie_notice_accept == 1) {
      document.getElementById("cookie-notice").style.display = "none";
    } else {
      document.getElementById("cookie-notice").style.display = "block";
    }
  }
  accept(e) {
    e.preventDefault();
    Cookies.set("cookie_notice_accept", 1, { expires: 365, path: "/" });
    this.toggleView();
  }
}
