import  { EventToast } from "./events";
// https://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// function to bind key event listener.
export function keyBinding(selector, {enterAction}) {
  if(selector) {
    selector.addEventListener("keydown", e => {
      // keyboard event code
      switch(e.which) {
        case 13:
          enterAction && enterAction();
          break;
        default:
          null // do nothing
      }
    })
  }
}

// function to dispatch toast message event.
export function dispatchToast(message) {
  window.dispatchEvent(
    new CustomEvent(EventToast, {
      detail: {
        notice: message
      }
    })
  )
}

export function getCSRFToken() {
  return document.querySelector('meta[name="csrf-token"]')?.content;
}


export function kebabFromCamel(input) {
  return input.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

export function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

export const throttle = (func, wait) => {
  let time = Date.now();
  return () => {
    if ((time + wait - Date.now()) < 0) {
      func();
      time = Date.now();
    }
  }
}

export const sortWithTableSort = (tables) => {
  if (tables.length == 0) {
    return;
  }

  Promise.all([import("tablesort")])
  .then(res => [res[0], import("tablesort/src/sorts/tablesort.number")])
  .then(res => {
    window.Tablesort = res[0].default;

    tables.forEach(target => {
      new Tablesort(target, {
        descending: true
      });
    });

    let table = tables[0]

    table.addEventListener("afterSort", () => {
      const c = table.querySelectorAll(
        'th[aria-sort="ascending"],th[aria-sort="descending"]'
      );
      const ele = c[0];
      const idx = ele.cellIndex;

      table.querySelectorAll("tr").forEach(function(tr) {
        tr.querySelectorAll("td").forEach(function(td) {
          td.classList.remove("table-active");
          if (td.cellIndex == idx) {
            td.classList.add("table-active");
          }
        });
      });
    });
  });
}
