import { Controller } from "stimulus";
import loadJS from "../util/loadJS";

export default class extends Controller {
  connect() {
    loadJS( "https://www.gstatic.com/firebasejs/5.8.3/firebase-app.js", true );
    loadJS( "https://www.gstatic.com/firebasejs/5.8.3/firebase-messaging.js", true );
    loadJS( "https://cdnjs.cloudflare.com/ajax/libs/localforage/1.7.3/localforage.min.js", res => {
      window.messagingFCM = this._initializeFirebaseFCM(this.element.dataset.fcmSenderId);
      window.messagingFCM.onMessage(this._displayNotification);
    }, true );
  }

  subscribeDeviceToFCM(messaging) {
    const that = this;

    messaging.requestPermission()
      .then(function() {
        console.log("Have Permission");
        return messaging.getToken();
      })
      .then(function(token) {
        // Workaround to make sure csrf is made available in csrf_meta_controller.js
        // Todo: refactor to wait for promise cross-controller (or use Event)
        // setTimeout(function() {
        //   console.log(token);
        //   that._registerToken(token);
        // }, 1000);
        console.log(token);
        that._registerToken(token);
      })
      .catch(function(err) {
        console.log(err);
        return false;
      }
    );
  }

  _displayNotification(payload) {
    localforage.getItem("fcmUid").then(fcmUid => {
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: payload.data.icon,
      };

      if (!("Notification" in window)) {
        console.log("This browser does not support system notifications");
      } else if (Notification.permission === "granted" && payload.data.fcm_uid == fcmUid) {
        // Let's check whether notification permissions have already been granted
        // and verify user by fcmUid

        var notification = new Notification(notificationTitle, notificationOptions);
        notification.onclick = function(event) {
          event.preventDefault();
          // prevent the browser from focusing the Notification's tab
          window.open(payload.data.link, '_blank');
          notification.close();
        }
      }
    })
  }

  _initializeFirebaseFCM(senderId) {
    firebase.initializeApp({'messagingSenderId': senderId});
    return firebase.messaging();
  }

  _registerToken(token) {
    const data = {
      device_registration_token: token,
    };
    const csrfToken = document.querySelector("meta[name=csrf-token]").content;

    fetch("/notification_subscriptions.json", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      credentials: "same-origin"
    })
    .then(res => res.json())
    .then(response => {
      localforage.setItem('fcmUid', response.uid).then(uid => {
        console.log(response.message);
      })

      return true;
    })
  }
}
