import { Controller } from "stimulus";
import Cookies from "js-cookie";
const IS_DARK = "is_dark";

export default class extends Controller {
  static targets = ["icon"];

  connect() {
    const isDark = Cookies.get(IS_DARK) === "true";
    this._applyMode(isDark);
  }

  lightMode() {
    this._setDarkMode(false);
  }

  darkMode() {
    this._setDarkMode(true);
  }

  _setDarkMode(isDark) {
    Cookies.set(IS_DARK, String(isDark), { expires: 365 }); // 'true' or 'false'
    this._applyMode(isDark);
  }

  _applyMode(isDark) {
    const lightClassName = this.iconTarget.dataset.lightClassName
    const darkClassName = this.iconTarget.dataset.darkClassName

    if (isDark) {
      // Add .darktheme to body
      document.body.classList.add("darktheme");
      document.documentElement.classList.add("tw-dark")
      this.iconTarget.className = this.iconTarget.className.replace(lightClassName, darkClassName);
    } else {
      document.body.classList.remove("darktheme");
      document.documentElement.classList.remove("tw-dark")
      this.iconTarget.className = this.iconTarget.className.replace(darkClassName, lightClassName);
    }
  }

  toggle() {
    const isDark = Cookies.get(IS_DARK) === "true";
    this._setDarkMode(!isDark);
  }
}
