import { Controller } from "stimulus";
import { loadScript } from "../util/load_script";

// Used in Widgets coin tab.
export default class extends Controller {
  isLoad = false;

  connect() {
    const widgetName = this.data.get("widget-name");

    if (window.customElements && !window.customElements.get(widgetName)) {
      // init
      this._checkAndLoadWidget(widgetName);
    }
  }

  _checkAndLoadWidget(widgetName) {
    const { top } = this.element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const topHeightElement = top + scrollTop;
    const shouldLoad =
      document.documentElement.scrollTop + document.documentElement.clientHeight > topHeightElement;

    if (shouldLoad) {
      loadScript()
        .url(`https://widgets.coingecko.com/${widgetName}.js`)
        .then(() => { this.isLoad = true; })
    }
  }
}
