import { Controller } from "stimulus";
import { formatCurrency, getActiveCurrency } from "../util/currency";
import { convertPriceFrom } from '../util/convert';
const getFlatpickr = () => import("flatpickr")

export default class extends Controller {
  static targets = [
    "coinId", "currencyField", "currencyButton", "currencyList",
    "form", "transactionType", "noteInputField",
    "balance", "transferInputField", "priceInputField",
    "transferTypeSelection", "dateInputField", "feeInputField",
    "quantityInputField", "previewSpentOutputField",
    "currencySymbol", "coinSymbol", "toggleSpent",
    "previewSpentInputField", "previewSpentInputLabel", "previewSpentOutputLabel",
    "balanceAmount",
    "maxButton",
  ]

  connect() {
    this.toggleTotalPreview = 1;
  }

  _initializePreviewSpentUpdateOnChange() {
    this.previewSpentInputFieldTarget.addEventListener("keyup", this._syncPreviewSpent.bind(this));
    this.quantityInputFieldTarget.addEventListener("keyup", this._syncPreviewSpent.bind(this));
  }

  _syncPreviewSpent() {
    const previewSpentInput = this.previewSpentInputFieldTarget,
          quantityInput = this.quantityInputFieldTarget,
          currencyCode = this.currencyFieldTarget.value,
          previewSpentOutput = this.previewSpentOutputFieldTarget;

    if (this.toggleTotalPreview) {
      const totalSpent = formatCurrency(
        parseFloat(previewSpentInput.value) * parseFloat(quantityInput.value || quantityInput.placeholder),
        currencyCode,
        true,
      );

      previewSpentOutput.value = totalSpent;
      this.priceInputFieldTarget.value = previewSpentInput.value;
    } else {
      const pricePerCoin = formatCurrency(
        parseFloat(previewSpentInput.value) / parseFloat(quantityInput.value || quantityInput.placeholder),
        currencyCode,
        true
      );

      previewSpentOutput.value = pricePerCoin;
      this.priceInputFieldTarget.value = pricePerCoin;
    }
  }

  selectCurrency(e) {
    const activeCurrencyTarget = e.currentTarget,
          activeCurrencyCode = activeCurrencyTarget.getAttribute("data-iso-code"),
          prevActiveCurrencyTargets = document.getElementsByClassName("active-currency"),
          prevActiveCurrencyTarget = prevActiveCurrencyTargets.length !== 0 && prevActiveCurrencyTargets[0],
          previewSpentInput = this.previewSpentInputFieldTarget;

    if (prevActiveCurrencyTarget) {
      // remove css selector from previous mtive currency.
      prevActiveCurrencyTarget.classList.remove("active", "active-currency");
      prevActiveCurrencyTarget.
        firstElementChild.
        firstElementChild.
        classList.add("text-muted");
    }

    if (prevActiveCurrencyTarget && activeCurrencyTarget) {
      const prevCurrencyCode = prevActiveCurrencyTarget.getAttribute("data-iso-code");

      // convert price (preview spent input field) from previous currency to the just selected one.
      previewSpentInput.value = convertPriceFrom(previewSpentInput.value, prevCurrencyCode, activeCurrencyCode, true);
    }

    this._updateCurrency(activeCurrencyCode);
    this._syncPreviewSpent();
  }

  _updateCurrency(currencyCode) {
    // set value to hidden currency field.
    this.currencyFieldTarget.value = currencyCode;
    this.currencyButtonTarget.innerText = currencyCode.toUpperCase();
    this._setCurrencySymbolTarget(currencyCode);

    const currencyTarget = this.currencyListTarget.querySelector(`a[data-iso-code="${currencyCode}"]`);

    if (currencyTarget) {
      currencyTarget.classList.add("active", "active-currency");
      currencyTarget.
        firstElementChild.
        firstElementChild.
        classList.remove("text-muted");
    }

    if (this.hasCurrencyTarget) {
      this._updateCurrency(getActiveCurrency());
    }
  }

  togglePreviewSpent() {
    this.toggleTotalPreview = !this.toggleTotalPreview;

    this._updatePreviewSpentFieldText();
    this._syncPreviewSpent();
  }

  _updatePreviewSpentFieldText() {
    const totalSpentText = this.toggleSpentTarget.dataset.totalSpentText,
          totalReceivedText = this.toggleSpentTarget.dataset.totalReceivedText,
          totalPreviewText = this.transactionTypeTarget.value == "buy" ? totalSpentText : totalReceivedText,
          pricePerCoinText = this.toggleSpentTarget.dataset.pricePerCoinText;

    if (this.toggleTotalPreview) {
      this.previewSpentOutputLabelTarget.innerText = totalPreviewText;
      this.previewSpentInputLabelTarget.innerText = pricePerCoinText;
      this.toggleSpentTarget.innerHTML = `
        <span class="fas fa-exchange"></span> \
        ${totalPreviewText}
      `
    } else {
      this.previewSpentOutputLabelTarget.innerText = pricePerCoinText;
      this.previewSpentInputLabelTarget.innerText = totalPreviewText;
      this.toggleSpentTarget.innerHTML = `
        <span class="fas fa-exchange"></span> \
        ${pricePerCoinText}
      `;
    }
  }

  _disablePreviewSpent() {
    this.previewSpentOutputFieldTarget.parentElement.classList.add("tw-hidden");
    this.previewSpentInputFieldTarget.parentElement.classList.add("tw-hidden");
    this.toggleSpentTarget.classList.add("tw-hidden");
    this.previewSpentInputFieldTarget.required = false;
  }

  _enablePreviewSpent() {
    this.previewSpentOutputFieldTarget.parentElement.classList.remove("tw-hidden");
    this.previewSpentInputFieldTarget.parentElement.classList.remove("tw-hidden");
    this.previewSpentInputFieldTarget.required = true;
    this.toggleSpentTarget.classList.remove("tw-hidden");
  }


  _initializeDatePicker(dateValue = Date.now()) {
    if (!window.flatPickrLoaded) {
      // only load css once.
      var link = document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.setAttribute("href", "https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css");
      document.getElementsByTagName("head")[0].appendChild(link);

      window.flatPickrLoaded = true // only load once
    }

    getFlatpickr().then((flatpickr) => {
      // get default module
      flatpickr.default(
        this.dateInputFieldTarget,
        {
          allowInput: true,
          enableTime: true,
          altInput: true,
          altFormat: "Y-m-d H:i",
          dateFormat: "Z",
          defaultDate: dateValue,
        }
      );
    })

  }

  updateTransactionType(event) {
    if (["buy", "sell"].includes(event.currentTarget.dataset.type)) {
      this.transactionTypeTarget.value = event.currentTarget.dataset.type;
    } else {
      const selectInput = this.transferTypeSelectionTarget,
            selectedOption = selectInput.options[selectInput.selectedIndex];

      if (selectedOption) {
        this.transactionTypeTarget.value = selectedOption.value;
      }
    }
  }

  toggleTransactionForm(event) {
    if (["buy", "sell"].includes(event.currentTarget.dataset.type)) {
      this.toggleInputField(event.currentTarget.dataset.type);
    } else {
      this.toggleInputField("transfer");
    }

    this.updateTransactionType(event);
    this._updatePreviewSpentFieldText();
  }

  toggleInputField(type) {
    if (["buy", "sell"].includes(type)) {
      this.transferInputFieldTarget.classList.add("tw-hidden");
      this.transferInputFieldTarget.querySelector("select").required = false;

      this._enablePreviewSpent();
      this._syncPreviewSpent();
    } else {
      this.priceInputFieldTarget.value = null;
      this.transferInputFieldTarget.classList.remove("tw-hidden");
      this.transferInputFieldTarget.querySelector("select").required = true;

      this._disablePreviewSpent();
    }

    if (["sell", "transfer"].includes(type)) {
      this._toggleBalance(true);
    } else {
      this._toggleBalance(false);
    }

    this._toggleMaxButton(type == "sell")
    this._autoFocusInput(type);
  }

  onPostError(e) {
    const [_data, _status, xhr] = e.detail;
    const errors = JSON.parse(xhr.response).errors;

    this._renderFormErrors(errors);
  }

  fillBalance(e) {
    e.preventDefault();

    const { balance } = e.currentTarget.dataset;

    this.quantityInputFieldTarget.value = balance;
  }


  _toggleBalance(show) {
    if (show) {
      this.balanceTarget.classList.remove("tw-hidden");

      return;
    }

    this.balanceTarget.classList.add("tw-hidden");

  }

  _toggleMaxButton(show) {
    if (show) {
      this.maxButtonTarget.classList.remove("tw-hidden");

      return;
    }

    this.maxButtonTarget.classList.add("tw-hidden");
  }

  _updateBalanceAmount(coin) {
    this.balanceAmountTarget.innerHTML = `${coin.coinBalance} ${coin.coinSymbol}`;
    this.maxButtonTarget.dataset.balance = coin.coinBalance;
  }

  _updateModalPortfolioName(portfolioTitle) {
    [...document.getElementsByClassName("js-portfolio-title")].forEach(e =>
      e.innerHTML = portfolioTitle
    );
  }

  _renderFormErrors(errors) {
    const formEl = this.formTarget;
    const model = formEl.getAttribute("data-model");

    for (const [field, messages] of Object.entries(errors)) {
      const input = formEl.querySelector('input[name="' + model + '[' + field + ']"]');
      input.classList.add("is-invalid");
      input.closest('.form-group').querySelector('.text-danger').innerHTML = messages.join(" & ");
    }
  }

  _clearFormErrors() {
    const formEl = this.formTarget;
    formEl.querySelectorAll("input").forEach(inputEl => {
      inputEl.classList.remove("is-invalid");
    })
    formEl.querySelectorAll(".text-danger").forEach(node => {
      node.innerHTML = "";
    })
  }

  _setCurrencySymbolTarget(currencyCode) {
    this.currencySymbolTargets.forEach(currencySymbolTarget => {
      currencySymbolTarget.innerText = currencyCode.toUpperCase();
    });
  }

  _setCoinSymbolTarget(coinSymbol) {
    this.coinSymbolTarget.innerText = coinSymbol;
  }

  _autoFocusInput(type) {
    if (["buy", "sell"].includes(type)) {
      this.previewSpentInputFieldTarget.focus();
    } else {
      this.quantityInputFieldTarget.focus();
    }
  }
}
