export function portfolioCoinAdded(coin, successMessage, hyperlink_text) {
  return ` \
    <div class="d-flex justify-content-center"> \
      <div class="pr-2 pt-2"> \
        <img src="${coin.image}"> \
      </div> \
      <div> \
        ${successMessage} \
        <br/><br/> \
        <a href="#new_transaction_modal" \
          data-toggle="modal" \
          data-coin-id="${coin.id}" \
          data-price-btc="${coin.priceBtc}" \
          data-coin-symbol="${coin.symbol}" \
          data-transaction-path="${coin.transactionPath}" \
          data-action="click->portfolio-coin-transactions-new#updateCoinIdValue"> \
          ${hyperlink_text} \
        </a> \
      </div> \
    </div> \
  `
}