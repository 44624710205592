import { Controller } from "stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static targets = ["coinSelect"];

  connect() {
    new Choices(this.coinSelectTarget, {
      removeItemButton: true
    });
  }
}
