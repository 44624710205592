import { Controller } from "stimulus";

////// Usages Example
// Must specify home and id
// <div id="innerTab" data-controller="tab-switch" data-tab-switch-home="#general" data-tab-switch-id="innerTab">

export default class extends Controller {
  connect() {
    this._homeTabHash = this.data.get('home'); // eg. #general
    this._id = this.data.get('id'); // eg. innerTab

    // swiching tab by reading url hash
    let url = location.href.replace(/\/$/, "");
    this._switchingTabByHash(url);

    // swiching tab when click back button
    window.addEventListener("popstate", e => {
      let url = location.href.replace(/\/$/, "");
      this._switchingTabByHash(url);
    });
  }

  addHashToUrlAndPushState(e) {
    //add hash to url and push state when switching tab
    let url = location.href.replace(/\/$/, "");
    let newUrl;
    const hash = e.target.hash;
    if (hash == this._homeTabHash) {
      newUrl = url.split("#")[0];
    } else {
      newUrl = url.split("#")[0] + hash;
    }

    history.pushState(null, null, newUrl);
  }

  _switchingTabByHash(url) {
    if (location.hash) {
      const hash = url.split("#");
      $('#' + this._id + ' a[href="#' + hash[1] + '"]').tab("show");

      // Workaround to have innerTab load on trigger
      // For some reason it wouldnt work with the shown.bs.tab which is what its supposed to
      // possibly due to jquery or event listener not loaded earlier
      var tabId = $('#' + this._id + ' a[href="#' + hash[1] + '"]').attr("id");
      if (tabId) {
        document.getElementById(tabId).click();
      }
      url = location.href.replace(/\/#/, "#");
      history.replaceState(null, null, url);
    } else {
      $('#' + this._id + ' a[href="' + this._homeTabHash + '"]').tab("show");
    }
  }
}
