import { Controller } from "stimulus";
import { EventCurrencyChanged } from "../events";
import { getActiveCurrency } from "../util/currency";

export default class extends Controller {
  static targets = ["currencyLink"];

  connect() {
    fx.base = "btc";
    fx.rates = JSON.parse(document.body.getAttribute("data-exchange-rate-json"));

    // Other controllers depend on events emitted by these methods.
    // Use set timeout to ensure they receive them after connect.
    setTimeout(() => {
      this.reloadCurrency();

      // Dispatch event to tell others about possible currency change.
      this.element.dispatchEvent(
        new CustomEvent(EventCurrencyChanged, {
          bubbles: true,
          detail: {
            currencyCode: getActiveCurrency()
          }
        })
      );

      // Put event listener here so we don't trigger callback twice on connect
      window.addEventListener(EventCurrencyChanged, () => {
        this.reloadCurrency();
      });
    }, 50);
  }

  // Retrieve current active currency and replace all currencies in this page with it.
  reloadCurrency() {
    const currencyCode = getActiveCurrency();

    // Update links with currency params in it
    this.currencyLinkTargets.forEach(el => {
      const url = new URL(el.href, window.location.href);
      const hrefParts = url.pathname.split("/");
      hrefParts[hrefParts.length - 1] = currencyCode;
      url.pathname = hrefParts.join("/");
      el.href = url.href;
    });
  }
}
