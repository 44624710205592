import DialogController from "./dialog_controller";
import { EventToast } from "../events";
import { getCSRFToken } from "../util";

export default class extends DialogController {
  static targets = [];

  connect() {}

  deletePortfolio(event) {
    const portfolio = event.currentTarget,
          portfolioTitle = portfolio.dataset.portfolioTitle,
          deletePortfolioConfirmDialog = $(`#${portfolio.dataset.confirmModal}`),
          deletePortfolioConfirmDialogHeader = deletePortfolioConfirmDialog.find("h4")[0], // header
          deletePortfolioConfirmButton = deletePortfolioConfirmDialog.find(`#${portfolio.dataset.confirmButton}`);

    deletePortfolioConfirmDialogHeader.innerText = portfolioTitle;
    this._confirmationDialog(
      () => { deletePortfolioConfirmDialog.modal("show") },
      deletePortfolioConfirmButton,
      () => {
        deletePortfolioConfirmDialog.modal("hide");
        this._deletePortfolioCallBack(portfolio);
      }
    );
  }

  _deletePortfolioCallBack(transaction) {
    const deletePath = transaction.dataset.actionPath;

    fetch(`${deletePath}`,
      {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      })
      .then(res => res.json())
      .then(data => {
        if (data.message == "success") {
          window.dispatchEvent(
            new CustomEvent(EventToast, {
              detail: {
                notice: "Successful"
              }
            })
          )
          window.location.href = "/en/portfolio";
        } else {
          throw new Error("Failed to delete");
        }
      })
      .catch(error => {
        window.dispatchEvent(
          new CustomEvent(EventToast, {
            detail: {
              notice: "Unsuccessful"
            }
          })
        )
      });
  }
}
