import { Controller } from "stimulus";
import { EventCurrencyChanged, EventPriceChanged } from "../events";
import { getActiveCurrency, formatCurrency } from "../util/currency";

export default class extends Controller {
  static targets = [
    "portfolio",
    "rowLoading",
    "loading",
    "transactionRow",
    "profitLoss",
    "profitLossChangePercentage",
    "holdings",
    "averageNetCost",
    "totalCost"
  ];

  connect() {
    this._showSpinner(true);
    this._fetchOverview(getActiveCurrency());

    window.addEventListener(EventCurrencyChanged, e => this._fetchOverview(e.detail.currencyCode));
  }

  _fetchOverview(currency) {
    this._showSpinner(true);

    const vs_currency = currency || getActiveCurrency(),
          overviewPath = this.portfolioTarget.dataset.portfolioCoinTransactionOverviewPath,
          overviewPathWithParams = `${overviewPath}?vs_currency=${vs_currency}`;

    fetch(overviewPathWithParams, { credentials: "same-origin" })
      .then(response => response.json())
      .then(json => {
        this._updateOverview(json);
        this._showSpinner(false);
      });
  }

  _updateOverview(data) {
    this.holdingsTarget.innerText = this._formattedPrice(data.total_market_value);
    this.averageNetCostTarget.innerText = this._formattedPrice(data.average_net_cost);
    this.totalCostTarget.innerText = this._formattedPrice(data.total_cost);
    this._updateProfitLoss(data);
    this._updateTransaction(data.transactions);

    this.element.dispatchEvent(
      new CustomEvent(EventPriceChanged, {
        bubbles: true
      })
    );
  }


  _updateProfitLoss(data) {
    const profitLossChangeUpClass = this.profitLossChangePercentageTarget.getAttribute("data-up-class"),
          profitLossChangeDownClass = this.profitLossChangePercentageTarget.getAttribute("data-down-class");

    this.profitLossTarget.innerText = this._formattedPrice(data.profit_loss);
    this.profitLossChangePercentageTarget.classList.remove(profitLossChangeDownClass);
    this.profitLossChangePercentageTarget.classList.remove(profitLossChangeUpClass);

    if (data.profit_loss_change > 0) {
      this.profitLossChangePercentageTarget.innerText = `(+${data.profit_loss_change}%)`;
      this.profitLossChangePercentageTarget.classList.add(profitLossChangeUpClass);
    } else if (data.profit_loss_change < 0) {
      this.profitLossChangePercentageTarget.innerText = `(${data.profit_loss_change}%)`;
      this.profitLossChangePercentageTarget.classList.add(profitLossChangeDownClass);
    } else {
      this.profitLossChangePercentageTarget.innerText = "(-)";
    }

  }

  _updateTransaction(transactions) {
    this.transactionRowTargets.forEach(transactionTarget => {
      const transactionId = transactionTarget.getAttribute("data-transaction-id"),
            transaction = transactions.filter(t => t.id.toString() == transactionId.toString())[0];

      // transaction fee
      transactionTarget.querySelector("td[class~='fees']").innerText = this._formattedPrice(transaction.fees);
      // transaction price
      transactionTarget.querySelector("td[class~='price']").innerText = this._formattedPrice(transaction.price);

      // transaction proceeds
      if (transaction.proceeds != 0) {
        transactionTarget.querySelector("td[class~='proceeds']").innerText = this._formattedPrice(transaction.proceeds);
      } else {
        transactionTarget.querySelector("td[class~='proceeds']").innerText = "-";
      }
      // transaction costs
      if (transaction.cost != 0) {
        transactionTarget.querySelector("td[class~='cost']").innerText = this._formattedPrice(transaction.cost);
      } else {
        transactionTarget.querySelector("td[class~='cost']").innerText = "-";
      }

      if (transaction.profit_loss > 0) {
        transactionTarget.querySelector("td[class~='pnl']").innerHTML = `
          <span class="text-green"> \
            +${this._formattedPrice(transaction.profit_loss)} \
          </span>`;
      } else if (transaction.profit_loss < 0) {
        transactionTarget.querySelector("td[class~='pnl']").innerHTML = `
          <span class"text-danger"> \
            ${this._formattedPrice(transaction.profit_loss)} \
          </span>`;
      } else {
        transactionTarget.querySelector("td[class~='pnl']").innerHTML = "-";
      }
    })
  }

  _formattedPrice(price) {
    return formatCurrency(parseFloat(price), getActiveCurrency());
  }

  _showSpinner(show) {
    if (show) {
      this.rowLoadingTargets.forEach((loadingTarget) =>{
        loadingTarget.innerText = "Loading...";
      });
      this.loadingTargets.forEach((loadingTarget) =>{
        loadingTarget.classList.remove("tw-hidden");
      });
      this.holdingsTarget.classList.add("tw-hidden");
      this.profitLossTarget.classList.add("tw-hidden");
      this.profitLossChangePercentageTarget.classList.add("tw-hidden");
      this.profitLossChangePercentageTarget.classList.add("tw-hidden");
      this.averageNetCostTarget.classList.add("tw-hidden");
      this.totalCostTarget.classList.add("tw-hidden");
    } else {
      this.loadingTargets.forEach((loadingTarget) =>{
        loadingTarget.classList.add("tw-hidden");
      });
      this.holdingsTarget.classList.remove("tw-hidden");
      this.profitLossTarget.classList.remove("tw-hidden");
      this.profitLossChangePercentageTarget.classList.remove("tw-hidden");
      this.profitLossChangePercentageTarget.classList.remove("tw-hidden");
      this.averageNetCostTarget.classList.remove("tw-hidden");
      this.totalCostTarget.classList.remove("tw-hidden");
    }
  }
}
