export const EventCurrencyChanged = "coingecko-currency-changed";
export const EventPriceLoaded = "coingecko-price-loaded"; // new price elements loaded/injected into DOM
export const EventPriceChanged = "coingecko-price-changed";

export const EventPriceChartReload = "coingecko-price-chart-reload";
export const EventPricePercentageChanged = "coingecko-price-percentage-changed";

export const EventPortfolioOverviewChart = "coingecko-portfolio-overview-chart"

export const EventToast = "coingecko-toast";
export const EventDarkMode = "coingecko-darkmode";

export const EventResetTooltip = "coingecko-reset-tooltip";

export const EventScroll = "coingecko-scroll";

export const EventCheckUserLogin = "coingecko-check-user-login";
export const EventTickersMoreLoaded = "coingecko-tickers-more-loaded";

export const EventFocusSearchGlobal = "coingecko-focus-search-global";

export const EventPrivacyModeToggled = "coingecko-privacy-mode-toggled";