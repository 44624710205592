import { Controller } from "stimulus";
import { EventPriceLoaded } from "../events";

export default class extends Controller {
  static targets = ["tablebody", "loading", "showMore", "form", "select"];

  connect() {
    this.page = 1;
    this.perPage = 300;

    this.rowCount = this.tablebodyTarget.children.length;

    // Hide button if first page not full
    const hasMoreToLoad = this.rowCount == this.perPage;
    this._showButton(hasMoreToLoad);
  }

  submitFilterForm() {
    this.formTarget.submit();
  }

  showMore(e) {
    this._showButton(false);
    this._showSpinner(true);

    this.page += 1;

    fetch(
      `${window.location.pathname}/show_more_coins?page=${this.page}&per_page=${
        this.perPage
      }&${window.location.search.substring(1)}`,
      { credentials: "same-origin" }
    )
      .then(response => response.text())
      .then(res => {
        if (res) {
          this.tablebodyTarget.insertAdjacentHTML("beforeend", res);

          this._showButton(true);
          this._showSpinner(false);

          const newRowCount = this.tablebodyTarget.children.length;
          if (this.perPage > newRowCount - this.rowCount) {
            // Nothing more to load.
            this._showButton(false);
            this._showSpinner(false);
          }
          this.rowCount = newRowCount;

          // Incoming rows are in USD, we need to convert to target currency. Run formatter.
          this.element.dispatchEvent(
            new CustomEvent(EventPriceLoaded, {
              bubbles: true
            })
          );
        } else {
          this._showButton(false);
          this._showSpinner(false);
        }
      });

    // Prevent hash from showing in URL
    e.preventDefault();
  }

  _showSpinner(show) {
    this.loadingTarget.style.display = show ? "" : "none";
  }

  _showButton(show) {
    this.showMoreTarget.style.display = show ? "" : "none";
  }
}
