import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["passwordSection", "passwordFormCollapseButton", "emailChangeVerificationPassword", "currentPassword", "newPassword", "newPasswordConfirmation"];

  togglePasswordHolder() {
    if (this.passwordSectionTarget.classList.contains('tw-hidden')) {
      this.passwordSectionTarget.classList.remove('tw-hidden')
    } else {
      this.passwordSectionTarget.classList.add('tw-hidden')
    }
  }

  collapsePasswordChangeForm(){
    this.passwordSectionTarget.classList.remove('tw-hidden');
    document.getElementById("change-password-fields-button").innerText = "Change"
  }


  expandEmailChangeForm(){
    var button = document.getElementById("change-email-fields-button");
    if(button.innerText == "Change"){
      button.innerText = "Cancel"
    } else {
      button.innerText = "Change"
    }
  }

  expandPasswordChangeForm(){
    var button = document.getElementById("change-password-fields-button");
    if(button.innerText == "Change"){
      button.innerText = "Cancel"
    } else {
      button.innerText = "Change"
    }
  }

  showEmailChangePassword(e) {
    if (this.emailChangeVerificationPasswordTarget.type === "password") {
      e.target.classList.remove("fa-eye");
      e.target.classList.add("fa-eye-slash");
      this.emailChangeVerificationPasswordTarget.type = "text";
    } else {
      e.target.classList.remove("fa-eye-slash");
      e.target.classList.add("fa-eye");
      this.emailChangeVerificationPasswordTarget.type = "password";
    }
  }

  showCurrentPassword(e) {
    if (this.currentPasswordTarget.type === "password") {
      e.target.classList.remove("fa-eye");
      e.target.classList.add("fa-eye-slash");
      this.currentPasswordTarget.type = "text";
    } else {
      e.target.classList.remove("fa-eye-slash");
      e.target.classList.add("fa-eye");
      this.currentPasswordTarget.type = "password";
    }
  }

  showNewPassword(e) {
    if (this.newPasswordTarget.type === "password") {
      e.target.classList.remove("fa-eye");
      e.target.classList.add("fa-eye-slash");
      this.newPasswordTarget.type = "text";
    } else {
      e.target.classList.remove("fa-eye-slash");
      e.target.classList.add("fa-eye");
      this.newPasswordTarget.type = "password";
    }
  }

  showNewPasswordConfirmation(e) {
    if (this.newPasswordConfirmationTarget.type === "password") {
      e.target.classList.remove("fa-eye");
      e.target.classList.add("fa-eye-slash");
      this.newPasswordConfirmationTarget.type = "text";
    } else {
      e.target.classList.remove("fa-eye-slash");
      e.target.classList.add("fa-eye");
      this.newPasswordConfirmationTarget.type = "password";
    }
  }
}
