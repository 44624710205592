import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["show"];

  show(e) {
    const text = this.showTarget.getAttribute("data-text");
    this.showTarget.textContent = text;
    e.preventDefault();
  }
}
