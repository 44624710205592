import { Controller } from "stimulus";
import { API_ENDPOINTS, ASSET_ENDPOINTS } from "../config";
import Fuse from "fuse.js";
import { debounce } from "../util";
import { getCookie } from "../util";
import { EventFocusSearchGlobal } from "../events";
import showAds from "../util/show_ads";

const INPUT_DEBOUNCE_DURATION_MS = 150;

// Score of 0 is a perfect match. Score of 1 is perfect mismatch.
const FUSE_SCORE_THRESHOLD = 0.55;

const isDownKey = keyboardEvent => {
  return keyboardEvent.key === "ArrowDown" || keyboardEvent.key === "Down";
};
const isUpKey = keyboardEvent => {
  return keyboardEvent.key === "ArrowUp" || keyboardEvent.key === "Up";
};
const isEscKey = keyboardEvent => {
  return keyboardEvent.key === "Escape" || keyboardEvent.key === "Esc";
};
const isEnterKey = keyboardEvent => {
  return keyboardEvent.key === "Enter";
};
const isSlashKey = keyboardEvent => {
  return keyboardEvent.key === "Divide" || keyboardEvent.key === "/";
};

const liTemplate = (imgSrc, itemText, rank, searchRedirect) => `
  <li class="text-sm mt-1">
    <a href="${searchRedirect}" >
    <div class="d-flex p-2" style="line-height: 1">
      <span style="width:10vw;">
        ${
          imgSrc
          ? `<img class="mr-2" src="${imgSrc}" width="15" height="15" />`
          : `<div class="mr-2" style="width: 15px; height: 15px;"></div>`
        }
      </span>
      <span style="width:70vw;">
        ${itemText}
      </span>
      <span class="text-right text-primary" style="font-size: 12px;">
        ${rank}
      </span>
    </div>
    </a>
  </li>`;

const getCoinsTemplate = (coins, query, Fuse, hasScore = false, url, templateType = "search") => {
  if (coins && coins.length) {
    let customTitle = "Cryptocurrencies"
    if(templateType == "trending"){
      customTitle = "Trending Search 🔥";
    }

    const coinsListTemplate = coins
      .map((coin, idx) => {
        if (hasScore && coin.score > FUSE_SCORE_THRESHOLD) {
          return "";
        }

        coin = hasScore ? coin.item : coin;
        let coinDisplayName = coin.name;
        if (coin.other_names && coin.other_names.length) {
          if(Fuse){
            coinDisplayName = findQueryInList(query, coin.other_names, Fuse) || coinDisplayName;
          }
        }

        let urlPath = new URL(url)
        urlPath.searchParams.set("id", coin.id)
        urlPath.searchParams.set("type", "coin")

        if(templateType == "trending"){
          urlPath.searchParams.set("source", "trending")
        }

        return liTemplate(
          coin.thumb,
          coin.symbol ? `${coinDisplayName} (${coin.symbol})` : coinDisplayName,
          coin.market_cap_rank ? "#" + coin.market_cap_rank : " ",
          urlPath
        );
      })
      .join("");

    if (!coinsListTemplate) {
      return "";
    }
    return `
      <hr class="d-block d-sm-none mt-0">
      <div class="mb-3 font-semibold text-2xs font-bold">${customTitle}</div>
      <hr class="d-block d-sm-none">
      <ul class="list-reset relevant-results">
        ${coinsListTemplate}
      </ul>`;
  }
  return ``;
};

const getExchangesTemplate = (exchanges, hasScore = false, url) => {
  if (exchanges && exchanges.length) {
    const exchangesListTemplate = exchanges
      .map((exchange, idx) => {
        if (hasScore && exchange.score > FUSE_SCORE_THRESHOLD) {
          return "";
        }
        exchange = hasScore ? exchange.item : exchange;

        let urlPath = new URL(url)
        urlPath.searchParams.set("id", exchange.id)
        urlPath.searchParams.set("type", "exchange")

        return liTemplate(
          exchange.thumb,
          exchange.name,
          " ",
          urlPath);
      })
      .join("");

    if (!exchangesListTemplate) {
      return "";
    }
    return `
      <hr class="d-block d-sm-none mt-0">
      <div class="my-2 font-semibold text-2xs font-bold">Exchanges</div>
      <hr class="d-block d-sm-none">
      <ul class="list-reset relevant-results">
        ${exchangesListTemplate}
      </ul>`;
  }
  return ``;
};

const getCategoriesTemplate = (categories, hasScore = false, url) => {
  if (categories && categories.length) {
    const categoriesListTemplate = categories
      .map((category, _idx) => {
        if (hasScore && category.score > FUSE_SCORE_THRESHOLD) {
          return "";
        }
        category = hasScore ? category.item : category;

        let urlPath = new URL(url)
        urlPath.searchParams.set("id", category.id)
        urlPath.searchParams.set("type", "category")

        return liTemplate(
          category.thumb,
          category.name,
          " ",
          urlPath);
      })
      .join("");

    if (!categoriesListTemplate) {
      return "";
    }
    return `
      <hr class="tw-block sm:tw-hidden tw-mt-0">
      <div class="tw-mb-3 tw-font-semibold tw-text-xs tw-font-bold">Categories</div>
      <hr class="tw-block sm:tw-hidden">
      <ul class="list-reset relevant-results">
        ${categoriesListTemplate}
      </ul>`;
  }
  return ``;
};

const getSponsoredTemplate = (text, url, imageUrl) => {
  if (!showAds()) {
    return '';
  }

  // For desktop
  let image_1 = imageUrl;
  // For mobile
  let image_2 = imageUrl;

  return `<ul class="list-reset">
          <li class="text-sm mt-1 mb-2">
            <a href="${url}" target="_blank" rel="noopener" class="tw-hidden d-lg-block" style="line-height: 1">
            <div class="d-flex p-2">
            <span style="width:10vw;">
              <img src="${image_1}" width="20" height="20">
            </span>
            <span style="width:90vw; font-size: 12px;">
              ${text}
              <div class="mt-1 text-muted" style="font-size: 10px;">
              Sponsored
              </span>
            </span>
            </div>
            </a>

            <a href="${url}" target="_blank" rel="noopener" class="d-flex p-2 d-md-block d-lg-none" style="line-height: 1">
            <div class="d-flex">
            <span style="width:8vw;">
              <img src="${image_2}" width="20" height="20">
            </span>
            <span>
              ${text} <br/>
              <span class="text-muted" style="font-size: 10px;">Sponsored</span>
              </span>
            </div>
            </a>
          </li>
          </ul>`;
}

const fuseOptions = {
  shouldSort: true,
  includeScore: true,
  threshold: 0.3,
  location: 0,
  tokenize: true,
  distance: 85,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  useExtendedSearch: true,
};

// shouldSort false so it takes market cap sorting from payload
const fuseOptionsCoins = {
  shouldSort: true,
  includeScore: true,
  tokenize: true,
  threshold: 0.15,
  location: 0,
  distance: 85,
  maxPatternLength: 20,
  minMatchCharLength: 1,
  // includeScore: true,
};

const findQueryInList = (query, results, Fuse) => {
  if (results && results.length) {
    // Fuse cannot search through array of strings, so create object first and use key
    const filtered = new Fuse(
      results.map(name => ({ name })),
      Object.assign(fuseOptions, { keys: ["name"] })
    ).search(query);

    if (filtered && filtered.length) {
      const res = filtered[0];
      return res.item ? res.item.name : res.name; // .item is includeScore=true
    }
  }
  return null;
};

export default class extends Controller {
  static targets = ["results", "input", "path", "sponsored", "slashIcon"];
  connect() {
    this._debouncedRenderResults = debounce(this._renderResults, INPUT_DEBOUNCE_DURATION_MS);

    // Load results now (Commented out to try only loading when necessary)
    // this._fetchResults();

    // Fix bug where user types in field before Stimulus loads.
    const query = this.inputTarget.value.trim();
    if (query) {
      this._fetchResults(query).then(() => this._renderResults(query));
    }

    window.addEventListener(EventFocusSearchGlobal, e => this.focusInputBox(e));

    // "/" shortcut to activate search
    window.addEventListener("keyup", (event)=> {
      event.preventDefault();
      if(document.activeElement.tagName === "INPUT") {  return; }
      if(isSlashKey(event)){
        this.focusInputBox();
      }
    }, true);
  }

  onInputChanged(e) {
    const query = e.target.value;

    // Debounce this
    this._fetchResults(query).then(() => this._debouncedRenderResults(query));

    // if input change and query is empty, show trending results
    if(query === ""){
      this._renderTrendingResults("");
    }
  }

  onKeydown(e) {
    if (isEscKey(e)) {
      this.resultsTarget.classList.add("tw-hidden"); // Hide results
      this.resultsTarget.innerHTML = ""; // Clear results
    } else if (isEnterKey(e)) {
      const selectedItem = this.resultsTarget.querySelector("li.search-selected a");
      if (selectedItem) {
        selectedItem.click()
        // this._submitNavigate(selectedItem);
      }
    } else if (isUpKey(e) || isDownKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      // Set the next/previous result as selected
      const selectedItem = this.resultsTarget.querySelector("li.search-selected");
      if (!selectedItem) {
        return;
      }

      const items = Array.from(this.resultsTarget.querySelectorAll("li"));
      const selectedItemIdx = items.findIndex(item => item === selectedItem);

      let newSelectedItem;
      let newSelectedItemIdx = 0;
      if (isDownKey(e)) {
        newSelectedItemIdx = selectedItemIdx + 1;
        if (newSelectedItemIdx < items.length) {
          selectedItem.classList.remove("search-selected");

          newSelectedItem = items[newSelectedItemIdx];
          newSelectedItem.classList.add("search-selected");
        }
      } else {
        newSelectedItemIdx = selectedItemIdx - 1;
        if (newSelectedItemIdx >= 0) {
          selectedItem.classList.remove("search-selected");

          newSelectedItem = items[newSelectedItemIdx];
          newSelectedItem.classList.add("search-selected");
        }
      }

      if (newSelectedItemIdx === 0) {
        this.resultsTarget.scrollTop = 0;
      }

      if (!newSelectedItem) {
        return;
      }

      //
      // Scroll item into visible view.
      //

      const scrollContainer = this.resultsTarget.querySelector(".scroll-container");
      const scrollContainerTop = scrollContainer.getBoundingClientRect().top;

      const containerTop = this.resultsTarget.getBoundingClientRect().top;
      const containerHeight = this.resultsTarget.getBoundingClientRect().height;

      const itemTop = newSelectedItem.getBoundingClientRect().top;
      const itemHeight = newSelectedItem.getBoundingClientRect().height;

      const itemTopToScrollContainerTopDistance = itemTop - scrollContainerTop;

      let itemTopTarget = itemTop;
      if (isDownKey(e)) {
        // Bottom of the box
        itemTopTarget = containerTop + containerHeight - itemHeight;
      } else {
        // Top of the box
        itemTopTarget = containerTop;
      }

      const scrollTop = itemTopToScrollContainerTopDistance - (itemTopTarget - containerTop);

      if (isDownKey(e)) {
        if (itemTop > itemTopTarget) {
          this.resultsTarget.scrollTop = scrollTop;
        }
      } else {
        if (itemTop < itemTopTarget) {
          this.resultsTarget.scrollTop = scrollTop;
        }
      }
    }
  }

  onMousedown(e) {
    if (e.target.nodeName === "LI" || e.target.nodeName === "IMG"  || e.target.nodeName === "SPAN") {
      this._isClickInProcess = true;
    }
  }

  onMouseup(e) {
    this._isClickInProcess = false;
  }

  onMouseover(e) {
    const selectedItem = this.resultsTarget.querySelector("li.search-selected");

    let liTarget;
    if (e.target.nodeName === "LI") {
      liTarget = e.target.parentElement.parentElement;
    } else if (e.target.nodeName === "IMG") {
      liTarget = e.target.parentElement.parentElement.parentElement.parentElement;
    } else if (e.target.nodeName === "SPAN") {
      liTarget = e.target.parentElement.parentElement.parentElement;
    }

    if (selectedItem && liTarget) {
      selectedItem.classList.remove("search-selected");
      liTarget.classList.add("search-selected");
    }
  }

  onFocus(e) {
    // select input text on click
    const input = e.currentTarget;
    input.setSelectionRange(0, input.value.length);

    // show current results if available
    if (e.target.value && this.resultsTarget.children.length) {
      this.resultsTarget.classList.remove("tw-hidden");
    }

    // Lazy-load results on focus
    this._fetchResults();

    // if onfocus and query is empty, show trending results
    const query = this.inputTarget.value.trim();
    if (query === "") {
      this._renderTrendingResults("");
    }

    this.slashIconTarget.classList.add("tw-hidden");
  }

  onBlur(e) {
    if (!this._isClickInProcess) {
      this.resultsTarget.classList.add("tw-hidden");
      this.slashIconTarget.classList.remove("tw-hidden");
    }
  }

  goBack(e) {
    e.preventDefault();
    window.history.back();
  }

  focusMobileInputBox() {
    this.inputTarget.focus();
    this.inputTarget.click();
    this._renderTrendingResults("");
  }

  focusInputBox() {
    this.inputTarget.focus();
  }

  // _submitNavigate(selectedItem) {
  //   window.location.href = selectedItem.getElementsByTagName("a")[0].getAttribute("href")
  // }

  // Fetches the results EXACTLY ONCE. If already fetched, simply returns the resolved promise
  _fetchResults(query = "") {
    if (this._fetchPromise) {
      return this._fetchPromise;
    }

    this.currentLocale = document.getElementsByTagName("body")[0].getAttribute("data-locale")

    return (this._fetchPromise = fetch(
      `${API_ENDPOINTS[process.env.NODE_ENV]}/search?locale=${this.currentLocale}`,
      {
        credentials: "same-origin"
      }
    )
      .then(response => response.json())
      .then(json => {
        // Only update if matches latest query.
        // Race condition when earlier query returns earlier that latest query.
        this._results = json;
        this._renderResults(query);
      })
      .catch(() => {
        this._results = {};
        this._renderResults(query, true);
      }));
  }

  _renderResults(query, error = false) {
    if (error) {
      this.resultsTarget.innerHTML = '<div class="p-2 text-sm">An error occured. Try again later.</div>';
      this.resultsTarget.classList.remove("tw-hidden");
      return;
    }
    if (!query) {
    //   this.resultsTarget.innerHTML = '<div class="p-2">Enter a search query.</div>';
    //   this.resultsTarget.style.display = "";
      return;
    }

    gtag('event', 'searching', {
      'event_category': 'Search',
      'event_label': query
    })

    const results = this._results;
    if (!results) {
      // Results not in yet, wait for the results.
      return;
    }

    this._fuseCoins =
      this._fuseCoins ||
      new Fuse(
        results.coins,
        Object.assign(fuseOptionsCoins, {
          keys: [{
            name: "symbol",
            weight: 0.6
          },{
            name: "name",
            weight: 0.3
          },{
            name: "other_names",
            weight: 0.1
          }]
        })
      );
    this._fuseExchanges =
      this._fuseExchanges ||
      new Fuse(
        results.exchanges,
        Object.assign(fuseOptions, {
          keys: ["name"]
        })
      );

    this._fuseCategories =
      this._fuseCategories ||
      new Fuse(
        results.categories,
        Object.assign(fuseOptions, {
          keys: ["name"]
        })
      );

    let coins = this._fuseCoins.search(query).slice(0,6);
    // Sort remaining after fuse score, by market cap
    coins.sort(function(coinA, coinB) {
      return (coinB.item.market_cap_rank != null) - (coinA.item.market_cap_rank != null) || coinA.item.market_cap_rank - (coinB.item.market_cap_rank)
     })

    const exchanges  = this._fuseExchanges.search(`${query} | '(${query})`).slice(0,4);
    const categories = this._fuseCategories.search(`'(${query}) | ${query}`).slice(0,4)

    categories.sort(function(catA, catB) {
      return (catB.item.market_cap_rank != null) - (catA.item.market_cap_rank != null) || catA.item.market_cap_rank - (catB.item.market_cap_rank)
     })

    let resultsTemplate = "";
    let sponsoredText = this.inputTarget.dataset.sponsoredText;
    let sponsoredUrl = this.inputTarget.dataset.sponsoredUrl;
    let sponsoredImageUrl = this.inputTarget.dataset.sponsoredImageUrl;
    resultsTemplate += getSponsoredTemplate(sponsoredText, sponsoredUrl, sponsoredImageUrl);

    // Try to show best match first.
    [
      { category: "coins", topScore: coins && coins.length && coins[0].score },
      { category: "categories", topScore: categories && categories.length && categories[0].score },
      { category: "exchanges",  topScore: exchanges  && exchanges.length  && exchanges[0].score }
    ]
      .forEach(item => {
        if (item.topScore == null) {
          return;
        }
        if (item.category === "coins") {
          resultsTemplate += getCoinsTemplate(coins, query, Fuse, true, this.pathTarget.getAttribute("data-url"));
        } else if (item.category === "categories") {
          resultsTemplate += getCategoriesTemplate(categories, true, this.pathTarget.getAttribute("data-url"));
        } else if (item.category === "exchanges") {
          resultsTemplate += getExchangesTemplate(exchanges, true, this.pathTarget.getAttribute("data-url"));
        }
      });

    if (!resultsTemplate) {
      resultsTemplate = '<div class="p-2 text-sm">No Results Found</div>';
    }

    this.resultsTarget.innerHTML = `
    <div class="scroll-container">
      ${resultsTemplate}
    </div>`;

    this.resultsTarget.scrollTop = 0;
    this.resultsTarget.classList.remove("tw-hidden");

    const firstResult = this.resultsTarget.querySelector(".relevant-results li");
    if (firstResult) {
      firstResult.classList.add("search-selected");
    }
  }

  // Fetches the results EXACTLY ONCE. If already fetched, simply returns the resolved promise
  _renderTrendingResults(query, error = false) {
    const trendingResults = JSON.parse(this.data.get("trending"));

    let coins = trendingResults.coins
    let exchanges = trendingResults.exchanges
    let resultsTemplate = "";

    let sponsoredText = this.inputTarget.dataset.sponsoredText;
    let sponsoredUrl = this.inputTarget.dataset.sponsoredUrl;
    let sponsoredImageUrl = this.inputTarget.dataset.sponsoredImageUrl;
    resultsTemplate += getSponsoredTemplate(sponsoredText, sponsoredUrl, sponsoredImageUrl);

    // Try to show best match first.
    [
      { category: "coins", results: coins },
      { category: "exchanges", results: exchanges }
    ]
      .forEach(item => {
        if (item.category === "coins") {
          resultsTemplate += getCoinsTemplate(coins, "", null, true, this.pathTarget.getAttribute("data-url"), "trending");
        } else {
          resultsTemplate += getExchangesTemplate(exchanges, true, this.pathTarget.getAttribute("data-url"));
        }
      });

    if (!resultsTemplate) {
      resultsTemplate = '';
    }

    this.resultsTarget.innerHTML = `
    <div class="scroll-container">
      ${resultsTemplate}
    </div>`;

    this.resultsTarget.scrollTop = 0;
    this.resultsTarget.classList.remove("tw-hidden");

    const firstResult = this.resultsTarget.querySelector(".relevant-results li");
    if (firstResult) {
      firstResult.classList.add("search-selected");
    }
  }
}
