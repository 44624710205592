import { getCSRFToken } from "../util";
import { resolveError, resolveSuccess } from "../helpers/routes";

export function createPortfolioRequest(params, method, success) {
  fetch("/en/portfolios",
    {
      credentials: "same-origin",
      method: method,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken()
      },
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then(data => {
      if (success) {
        success(data);
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Unsuccessful");
    });
}

export function resetPortfolioRequest(params, success) {
  const {portfolioId} = params;

  fetch(`/en/portfolios/${portfolioId}/reset.json`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken(),
      credentials: "same-origin",
    },
    method: "POST",
  })
    .then(response => {
      if (response.ok) {
        success();
      } else {
        throw response.json();
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Unsuccessful");
    });
}

export function updatePortfolioShareLinkRequest(params, success, error) {
  const {portfolioId} = params;

  fetch(`/en/portfolios/${portfolioId}/update_share_link.json`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken(),
      credentials: "same-origin",
    },
    method: "POST",
    body: JSON.stringify({slug: params.slug}),
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw response.json();
      }
    })
    .then(res => success && success(res))
    .catch(exception => {
      resolveError(exception, error, "Unsuccessful");
    })
}

export function sharePortfolioRequest(params, success) {
  fetch(`/en/portfolios/${params.portfolioId}/share.json`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken(),
    },
    method: "POST",
    credentials: "same-origin",
  })
    .then(response => response.json())
    .then(res => {
      if (success) {
        success(res)
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}

export function unSharePortfolioRequest(params, success) {
  fetch(`/en/portfolios/${params.portfolioId}/unshare.json`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken(),
    },
    method: "POST",
    credentials: "same-origin",
  })
    .then(response => response.json())
    .then(res => {
      if (success) {
        success(res)
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}

export function getPortfolioOverviewChart(params, success) {
  const searchParams = new URLSearchParams();
  // set query params
  searchParams.set("timeframe", params.timeframe);
  searchParams.set("vs_currency", params.vs_currency);

  fetch(`/en/portfolios/${params.portfolioId}/overview_chart.json?${searchParams}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken(),
    },
    credentials: "same-origin",
  })
    .then(response => response.json())
    .then(res => resolveSuccess(success, res))
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    })
}

export function getPortfoliosRequest(success) {
  fetch(`/en/portfolio.json`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken(),
    },
    method: "GET",
    credentials: "same-origin",
  })
    .then(response => response.json())
    .then(res => {
      if (success) {
        success(res);
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}

export function getPortfolioCoins(params, method, validate, success) {
  fetch("/en/portfolios/coins", {
    credentials: "same-origin",
    method: method,
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params),
  })
    .then(response => {
      if (!validate(response)) { return; };

      return response.json();
    })
    .then(res => {
      if (success) {
        success(res)
      }
    });
}

export function addPortfolioCoinRequest(params, method, success) {
  fetch(`/en/portfolios/${params.portfolioId}/coins/${params.coinId}.json`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken()
    },
    method: method,
    credentials: "same-origin",
    body: JSON.stringify({source: params.source})
  })
    .then(response => response.json())
    .then(res  => {
      if (success) {
        success(res);
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}

export function removePortfolioCoinRequest(params, success) {
  fetch(`/en/portfolios/${params.portfolioId}/coins/${params.coinId}.json`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken()
    },
    method: "DELETE",
    credentials: "same-origin",
    body: JSON.stringify({ source: params.source })
  })
    .then(response => response.json())
    .then(res => {
      if (success) {
        success(res);
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}

export function setPortfolioAsDefault(params, success) {
  fetch(`/en/portfolios/${params.portfolioId}`, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-CSRF-Token": getCSRFToken(),
    },
    method: "POST",
    credentials: "same-origin",
    body: JSON.stringify(params),
  })
    .then(response => response.json())
    .then(res => {
      if (success) {
        success(res);
      }
    })
    .catch(exception => {
      resolveError(exception, null, "Something went wrong!");
    });
}
