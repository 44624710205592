import { Controller } from "stimulus";
import 'jquery-countdown';

export default class extends Controller {
  static targets = ["countdownTimer", "currentBlock"];

  connect() {
    let countdownTimer = this.countdownTimerTarget
    if(countdownTimer.getAttribute("data-halving-mode") == "specific_datetime") {
      this.getCountdownDataBySpecificDatetime(countdownTimer)
    } else {
      this.getCountdownDataByApiCall(countdownTimer);
    }
  }

  getCountdownDataByApiCall(countdownTimer) {
    let currentBlock = countdownTimer.getAttribute('data-currentblock')
    let hardforkBlock = countdownTimer.getAttribute('data-hardforkblock')
    let blockTime = countdownTimer.getAttribute('data-blocktime')

    var blocksToGo = hardforkBlock - currentBlock
    var secondsFromNow = (hardforkBlock - currentBlock) * 1 * blockTime
    var now = new Date()
    var forkDate = now.setSeconds(now.getSeconds() + secondsFromNow)
    $('#' + countdownTimer.id).countdown(forkDate, function(event){
      $(this).text(event.strftime('%-D days %-H h %M min %S sec'))
    });
    $('#' + this.currentBlockTarget.id).text(String(parseInt(currentBlock)).replace(/\B(?=(\d{3})+(?!\d))/g, ","))
   }

  getCountdownDataBySpecificDatetime(countdownTimer) {
     var date = new Date();
     var hardforkDate = new Date(countdownTimer.getAttribute('data-hardfork-date').replace(/-/g, "/"));
     var secondsFromNow = (hardforkDate.getTime() - date.getTime()) / 1000;
     var forkDate = date.setSeconds(date.getSeconds() + secondsFromNow)
     $('#' + countdownTimer.id).countdown(forkDate, function(event){
       $(this).text(event.strftime(`%-D days %-H h %M min %S sec`))
     });
   }
}
