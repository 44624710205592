import { Controller } from "stimulus";
import { loadScript } from "../util/load_script";

export default class extends Controller {
  
  connect(){
    this.loadedAddThis = null
  }

  loadAddThis(e) {
    if(this.loadedAddThis != true){
      loadScript(true)
        .url("//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c7619d67f49e589")
      this.loadedAddThis = true
    }
  }
}
