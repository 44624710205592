import { Controller } from "stimulus";
import { EventCurrencyChanged, EventPriceChanged, EventPriceLoaded } from "../events";
import { getActiveCurrency, formatCurrency } from "../util/currency";
import { reloadBootstrapTooltip } from "../util/tooltip";

// This controller is responsible for executing price changes from websocket or user actions.
// For performance reasons, there is only ONE price controller in the entire page.
export default class extends Controller {
  // DOM should have data-target="price.price" in order to refer to elements that need price update.
  static targets = ["price"];

  connect() {
    window.addEventListener(EventPriceChanged, e => this._updatePrice(e, true));
    window.addEventListener(EventCurrencyChanged, e => this._updatePrice(e));
    window.addEventListener(EventPriceLoaded, e => this._updatePrice(e));
  }

  _updatePrice(e, isLiveUpdate = false) {
    reloadBootstrapTooltip()

    // Retrieve all targets with data-target="price.price" with this.priceTargets
    this.priceTargets.forEach(priceTarget => {
      // If coin id is provided in event detail, we only want to update if coin id matches
      const targetCoinId = e.detail && e.detail.coinId;
      const symbol    = priceTarget.getAttribute("data-coin-symbol");
      const isAnomaly = priceTarget.getAttribute("data-is-anomaly")

      if (targetCoinId == null || targetCoinId === +priceTarget.getAttribute("data-coin-id")) {
        // Coin match. Proceed with update.

        // Two sources of updates: data-price-btc or data-price-json
        const dataPriceBTC = priceTarget.getAttribute("data-price-btc");
        const dataPriceJSON = priceTarget.getAttribute("data-price-json");

        const currencyCode = (e.detail && e.detail.currencyCode) || getActiveCurrency();

        let price;
        if (currencyCode === symbol) {
          // The price of ABC coin in ABC currency is 1.
          price = 1;
        } else if (dataPriceBTC) {
          const priceBTC = (e.detail && e.detail.priceBTC) || +dataPriceBTC;
          price = fx(priceBTC)
            .from("btc")
            .to(currencyCode);

          // Write the new price_btc to attribute
          if (e.detail && e.detail.priceBTC) {
            priceTarget.setAttribute("data-price-btc", priceBTC);
          }
        } else {
          const priceJSON = (e.detail && e.detail.priceJSON) || JSON.parse(dataPriceJSON); // Parse only when absolutely necessary due to performance.
          price = priceJSON && priceJSON[currencyCode];
        }

        const raw = !!priceTarget.getAttribute("data-raw");
        const noDecimal = !!priceTarget.getAttribute("data-no-decimal");
        const prevDisplayText = priceTarget.textContent.trim();
        const nextDisplayText = this._formatAnomaly(isAnomaly, formatCurrency(price, currencyCode, raw, noDecimal));

        priceTarget.textContent = nextDisplayText;

        // Blink only if the displayed price actually changed.
        if (isLiveUpdate && prevDisplayText !== nextDisplayText) {
          const upClass = "text-green";
          const downClass = "text-danger";
          const prevPrice = +priceTarget.getAttribute("data-price-previous");

          if (price >= prevPrice) {
            priceTarget.classList.add(upClass);
            priceTarget.classList.remove(downClass);
          } else {
            priceTarget.classList.add(downClass);
            priceTarget.classList.remove(upClass);
          }

          // Remove color after delay
          setTimeout(() => {
            priceTarget.classList.remove(upClass);
            priceTarget.classList.remove(downClass);
          }, 1000);
        }

        // Store current price as previous price attribute for live update use.
        priceTarget.setAttribute("data-price-previous", price);
      }
    });
  }

  _formatAnomaly(isAnomaly, price) {
    if (isAnomaly === "true") {
      return `*${price}`
    }
    return price
  }
}
